/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { queryCreatives } from '../../Utilities/api';
// import Modal from '../../Components/Modal';
// import Button from '../../Components/Button';
// import { useDropzone } from 'react-dropzone';
import DataError from '../../Components/DataError';
import Loading from '../../Components/Loading';
import { DashboardContext } from '../../context';

const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  padding-top: 50px;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
`;
const Image = styled.div`
  position: absolute;
  width: 95%;
  background: url("${({ src }) => src}") center center no-repeat;
  background-size: cover;
  left: 2.5%;
  right: 2.5%;
  top: 3%;
  bottom: 10%;
  border-radius: 5px;
  > p {
    padding-top: 100px;
    color: #169aff;
    font-weight: 500;
  }
`;
const CreativeName = styled.p`
  font-size: 21px;
  font-weight: 600;
  padding-top: 15px;
  text-align: left;
`;
const TabletMockup = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 62%;
  background: url("/assets/welcome-screen-mockup.png") center center no-repeat;
  background-size: contain;
  border-radius: 10px;
`;
const Header = styled.h2`
  font-size: 30px;
  font-weight: 600;
  color: #222222;
  text-align: left;
`;
const Tag = styled.div`
  width: 100px;
  height: 23px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bg};
  text-align: center;
  line-height: 23px;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`;
const PageContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;
// const Svg = styled.svg`
//     width: 83.5px;
//     height: 83.5px;
//     margin: 30px auto 0;
//     fill: #85c7ff;
// `;
// const DragText = styled.p`
//     font-size: 18px;
//     padding-top: 20px;
//     min-width: 400px;
// `;
// const OrText = styled.p`
//     padding: 20px 0;
//     text-transform: uppercase;
//     color: #bdbdbd;
//     font-size: 18px;
//     font-weight: 600;
//     `;
// const ButtonWrapper = styled.div`
//     width: 216px;
//     margin: 0 auto;
// `;
// const Spacing = styled.div`
//     height: 15px;
// `;
// const FileInput = styled.input`
//     display: none;
// `;
// const TabletPreview = styled.div`
//     padding-top: 30px;
//     width: 600px;
//     //height: 374px;
// `;
// const NameInput = styled.input`
//     height: 45px;
//     box-sizing: border-box;
//     margin-top: 15px;
//     border-radius: 10px;
//     border: solid 1px #d6d6d6;
//     background-color: #ffffff;
//     padding: 10px 25px;
//     width: 100%;
// `;
// const UploadSvg = styled.svg`
//     margin-right: 10px;
// `;
// const FlexCenter = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `;

// const UploadImageSvg = () => (
//     <Svg xmlns="http://www.w3.org/2000/svg" height={24} viewBox="0 0 24 24" width={24}><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z" /></Svg>
// );

// const UploadFileSvg = ({ color }) => (
//     <UploadSvg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
//         <g id="Group_874" data-name="Group 874" transform="translate(-757 -678)">
//             <g id="insert_drive_file-24px" transform="translate(757 678)">
//                 <path id="Path_494" data-name="Path 494" d="M0,0H24V24H0Z" fill="none" />
//                 <path id="Path_495" data-name="Path 495" d="M14,2H6A2,2,0,0,0,4.01,4L4,20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8ZM6,20V4h7V9h5V20Z" fill={color || "#fff"} />
//             </g>
//             <path id="Path_497" data-name="Path 497" d="M10.433,5.191l1.482,1.476.807-.807L9.861,3,7,5.861l.807.807L9.288,5.191v5.532h1.144Z" transform="translate(759.139 685.889)" fill={color || "#fff"} />
//         </g>
//     </UploadSvg>
// );

const CampaignImages = () => {
  const [data, setData] = useState();
  const { campaign } = React.useContext(DashboardContext);
  // const [addModal, setAddModal] = useState(true);

  // const [creative, setCreative] = useState({
  //     url: undefined,
  //     blob: undefined
  // });

  // const handleFile = (file) => {
  //     try {
  //         const blob = new Blob([file]);

  //         const url = URL.createObjectURL(blob);

  //         setCreative({
  //             url,
  //             blob
  //         });
  //     } catch (error) {
  //         console.log('Error parsing file: ', error);
  //     }
  // }

  // const handleUpload = (event) => {
  //     if (event.target.files.length > 0) {
  //         const file = event.target.files[0];
  //         handleFile(file);
  //     };
  // };

  // const onDrop = useCallback(acceptedFiles => {
  //     handleFile(acceptedFiles[0]);
  // }, []);
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    let isMounted = true;
    const findCreatives = async () => {
      const { value } = campaign;
      if (!value || !value.creatives) return setData();

      const creatives = value.creatives.map(({ creativeId }) => creativeId);
      const response = await queryCreatives(creatives);
      if (response.error) {
        console.log('Error: ', response.error);
        return setData({ error: response.error });
      }

      return isMounted && setData(response.data.welcomeitems);
    };

    findCreatives();
    return () => {
      isMounted = false;
    };
  }, [campaign]);

  // const handleAdd = () => {
  //     setAddModal(true);
  // };

  const videoTypes = ['mp4', 'mpeg', 'mpg', 'quicktime', 'webm'];

  if (data && data.error) {
    return <DataError />;
  }

  const CampaignImagesContent = () => (data.length === 0 ? (
    <p>No creatives found.</p>
  ) : (
    data.map((creative) => (
      <div key={creative.AssetUrl}>
        <TabletMockup>
          {videoTypes.includes(creative.CreativeType) ? (
            <ReactPlayer
              url={creative.AssetUrl}
              playing
              controls={false}
              height="87%"
              width="95%"
              volume={0}
              muted
              onReady={() => { }}
              onError={(err) => {
                console.log('error playing video: ', err);
              }}
              style={{
                position: 'absolute',
                left: '2.5%',
                right: '2.5%',
                top: '3%',
                bottom: '10%',
                borderRadius: '5px',
                overflow: 'hidden',
              }}
              loop
            />
          ) : (
            <Image src={creative.AssetUrl}>
              {creative.ItemType === 'component' && (
                <p>Dynamic Component (no render available)</p>
              )}
            </Image>
          )}
        </TabletMockup>
        <Flex>
          <CreativeName>{creative.Name}</CreativeName>
          <Tag
            color={creative.Active ? '#13ba71' : '#f9a825'}
            bg={creative.Active ? '#ebfbf7' : '#fcefdb'}
          >
            {creative.Active ? 'Active' : 'Innactive'}
          </Tag>
        </Flex>
      </div>
    ))
  ));

  return (
    <PageContainer>
      {/* <Modal isOpen={addModal} onClose={() => setAddModal(false)} title='Upload file' center maxWidth={creative.blob && '800px'}>
            {creative.blob ?
                <TabletPreview>
                    <TabletMockup>
                        <Image src={creative.url} />
                    </TabletMockup>
                    <NameInput placeholder="Add title (i.e. 'Creative A')" />
                    <Spacing />
                    <ButtonWrapper>
                        <FileInput type='file' id='upload' onChange={handleUpload} />
                        <Button color='#169aff' borderColor='#169aff' fontSize='21px' onClick={() => setAddModal(false)}>Save</Button>
                        <Spacing />
                        <Button as='label' action='return' htmlFor='upload' borderColor='#169aff' fontSize='21px'><FlexCenter><UploadFileSvg color='#169aff' />Upload file</FlexCenter></Button>
                        <Spacing />
                        <Button action='return' color='#f1f1f1' borderColor='#f1f1f1' fontSize='21px' fontColor='#222222' onClick={() => setAddModal(false)}>Cancel</Button>
                    </ButtonWrapper>
                </TabletPreview>
                : <>
                    <div {...getRootProps()}>
                        <UploadImageSvg />
                        <DragText>{isDragActive ? 'Drop here' : 'Drag and drop your file here'}</DragText>
                        <input {...getInputProps()} />
                    </div>
                    <OrText>Or</OrText>
                    <ButtonWrapper>
                        <FileInput type='file' id='upload' onChange={handleUpload} />
                        <Button as='label' htmlFor='upload' color='#169aff' borderColor='#169aff' fontSize='21px'><FlexCenter><UploadFileSvg />Upload file</FlexCenter></Button>
                        <Spacing />
                        <Button action='return' color='#f1f1f1' borderColor='#f1f1f1' fontSize='21px' fontColor='#222222' onClick={() => setAddModal(false)}>Cancel</Button>
                    </ButtonWrapper>
                </>
            }
        </Modal>
        <button onClick={handleAdd}>Add</button> */}
      <Header>Campaign Images</Header>
      <ImagesContainer>
        {!data ? <Loading /> : <CampaignImagesContent />}
      </ImagesContainer>
    </PageContainer>
  );
};

export default CampaignImages;

import React from 'react';
import styled from 'styled-components';

// Components
import BackgroundGradient from '../../Components/BackgroundGradient';
import PortlInsightsLogo from '../../Components/PortlInsightsLogo';
import Footer from '../../Components/Footer';

const InsightsLogoStyled = styled.div`
    padding: 26px 0 38px 80px;
`;

const ContentWrapper = styled.div`
    display: flex;
    margin-bottom: 90px;
`;

const Wrapper = styled.div`
    flex: 0 0 672px;
`;

const Image = styled.div`
    height: 629px;
`;

const AuthTemplate = ({ children }) => (
  <>
    <BackgroundGradient>
      <InsightsLogoStyled>
        <PortlInsightsLogo />
      </InsightsLogoStyled>
      <ContentWrapper>
        <Wrapper>
          {children}
        </Wrapper>
        <Image as="img" src="/assets/insight.png" />
      </ContentWrapper>
    </BackgroundGradient>
    <Footer />
  </>
);

export default AuthTemplate;

import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
    font-size: 50px;
    line-height: 1.22;
    text-align: left;
`;

const Subtitle = styled.h3`
    font-size: 36px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 20px;
    text-align: left;
    color: #1fb6ff;
`;

const Heading = ({ title, subtitle }) => (
  <>
    <Title>
      {title}
    </Title>
    <Subtitle>
      {subtitle}
    </Subtitle>
  </>
);

export default Heading;

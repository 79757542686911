import React, { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  Formik, Form, ErrorMessage, Field,
} from 'formik';

import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

import ModalButtonGroup from '../ModalButtonGroup';
import { ModalButton, ModalButtonCancel } from '../../Components/ModalButton';

const ModalTitleAdd = styled.div`
  font-size: 21px;
  font-weight: 500;
  text-align: left;
  color: #222222;
  padding-bottom: 18px;
`;

const InputWrapper = styled(Field)`
  font-size: 16px;
  color: #757575;
  border-radius: 5px;
  border: solid 1px #757575;
  padding: 10px 15px;
  width: 447px;
  margin-top: 15px;
`;

const ButtonWrapper = styled.div`
  text-align: end;
  margin-top: 30px;
`;

const ModalButtonStyled = styled(ModalButton)`
  margin: 0 0 0 10px;
`;

const ErrorMessageStyled = styled.p`
  padding-top: 4px;
  font-size: 11px;
  color: #d80f4d;
  margin: 0;
`;

export const ChangeIconModal = ({ modalContent, closeModal }) => {
  const validationSchema = Yup.object({
    logo: Yup.string().required('Icon url is required'),
  });

  const {
    _id, action, title, name,
  } = modalContent;

  return (
    <Formik
      initialValues={{
        logo: '',
        _id,
      }}
      validationSchema={validationSchema}
      onSubmit={action}
    >
      {() => (
        <Form>
          <ModalTitleAdd>
            {title}
            {name}
            ?
          </ModalTitleAdd>
          Please insert the logo url:
          <InputWrapper
            id="logo"
            name="logo"
            placeholder="New company icon"
          />
          <ErrorMessage
            id="logo"
            name="logo"
            component={ErrorMessageStyled}
          />
          <ButtonWrapper>
            <ModalButtonCancel type="button" onClick={closeModal}>
              Cancel
            </ModalButtonCancel>
            <ModalButtonStyled type="submit">Change</ModalButtonStyled>
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
};

const DateRangePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 15px 0;

  .SingleDatePicker {
    width: 100%;
  }

  .SingleDatePickerInput__withBorder {
    width: 100%;
    border: 1px solid #757575;
    border-radius: 5px;
  }

  .SingleDatePickerInput {
    padding: 10px;
  }

  .DateInput {
    width: 115px;
  }

  .DateInput_input {
    font-size: 13px;
    font-weight: normal;
    color: #222222;
    padding: 0;
  }

  .SingleDatePicker_picker {
  }

  .SingleDatePickerInput_arrow {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
  }

  .CalendarMonth_caption {
    font-size: 15px;
  }

  .DayPickerNavigation_button__horizontalDefault {
    padding: 3px 3px;
  }
`;

const FieldStyled = styled(Field)`
  box-sizing: border-box;
  border: 1px solid #757575;
  border-radius: 5px;
  display: block;

  padding: 11px;
  height: 40px;
  width: 477px; ;
`;

const DatePickerStyled = styled.div`
  flex-grow: 1;
  max-width: 48%;
`;

const DatePickerWithFormmik = ({
  form: { setFieldValue, setFieldTouched, values },
  field,
  placeholder,
}) => {
  const [isfocused, setFocused] = useState(false);

  return (
    <DatePickerStyled name={field.name}>
      <SingleDatePicker
        placeholder={placeholder}
        date={
          values[field.name]
            ? moment(values[field.name]) : values[field.name]
        }
        onDateChange={(date) => setFieldValue(
          field.name,
          date && date.isValid() ? moment(date.toDate()) : '',
        )}
        focused={isfocused}
        onFocusChange={({ focused }) => {
          if (!focused) {
            // Formik x React-Dates Bug, not fixed as of 2020-08-12 : https://github.com/formium/formik/issues/2482
            setTimeout(() => {
              setFieldTouched(field.name, true);
            }, 25);
          }
          setFocused(focused);
        }}
        numberOfMonths={1}
      />
    </DatePickerStyled>
  );
};

const GenerateForm = ({
  handleSubmit,
  isSubmitting,
  closeModal,
  editValues,
}) => {
  const START = 'startDate';
  const END = 'endDate';

  return (
    <Form onSubmit={handleSubmit}>
      <FieldStyled name="campaignName" placeholder="Campaign Name" />
      <ErrorMessage name="campaignName" component={ErrorMessageStyled} />
      <DateRangePickerWrapper>
        <Field
          name={START}
          placeholder="Start Date"
          component={DatePickerWithFormmik}
        />
        <Field
          name={END}
          placeholder="End Date"
          component={DatePickerWithFormmik}
        />
      </DateRangePickerWrapper>
      <ErrorMessage name={START} component={ErrorMessageStyled} />
      <ErrorMessage name={END} component={ErrorMessageStyled} />
      <FieldStyled name="impressionGoal" placeholder="Impression Goal" />
      <ErrorMessage name="impressionGoal" component={ErrorMessageStyled} />
      <div style={{ height: '20px' }} />
      <ModalButtonGroup
        alignment="right"
        closeModal={closeModal}
        isForm
        text={editValues ? 'Edit' : 'Create'}
        action={handleSubmit}
        disabled={isSubmitting}
      />
    </Form>
  );
};

export const AddCampaign = ({ handleSubmit, closeModal, editValues = null }) => {
  const START = 'startDate';
  const END = 'endDate';

  const createValues = {
    campaignName: '',
    [START]: null,
    [END]: null,
    impressionGoal: '',
  };

  const initialValues = editValues || createValues;

  const ValidationSchema = Yup.object().shape({
    campaignName: Yup.string()
      .trim()
      .min(1)
      .required('Please enter a valid campaign name.'),
    impressionGoal: Yup.string()
      .trim()
      .min(1)
      .required('Please enter a valid goal.'),
    startDate: Yup.string()
      .typeError('Please select a start date.')
      .required('Please select a start date.'),
    endDate: Yup.string()
      .typeError('Please select an end date.')
      .required('Please select an end date.'),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
    >
      {(formikProps) => (
        <GenerateForm
          handleSubmit={formikProps.handleSubmit}
          isSubmitting={formikProps.isSubmitting}
          closeModal={closeModal}
          editValues={editValues}
        />
      )}
    </Formik>
  );
};

export const AddCampaignModal = ({ modalContent, closeModal }) => {
  const {
    action, title, name,
  } = modalContent;

  return (
    <>
      <ModalTitleAdd>
        {title}
        {name}
      </ModalTitleAdd>
      <AddCampaign handleSubmit={action} closeModal={closeModal} />
    </>
  );
};

export const AddCreativeModal = ({ modalContent, closeModal }) => {
  const validationSchema = Yup.object({
    creativeName: Yup.string().required('Creative name is required'),
    creativeId: Yup.string().required('Creative id is required'),
  });

  const {
    action,
    title,
    creatives,
    name,
    brandId,
    dateAdded,
    endDate,
    goal,
    startDate,
    _id,
  } = modalContent;

  return (
    <Formik
      initialValues={{
        creativeName: '',
        creativeId: '',
        creatives,
        name,
        brandId,
        dateAdded,
        endDate,
        goal,
        startDate,
        _id,
      }}
      validationSchema={validationSchema}
      onSubmit={action}
    >
      {() => (
        <Form>
          <ModalTitleAdd>
            {title}
          </ModalTitleAdd>
          Creative Id is the same number as the Strapi id
          <InputWrapper
            id="creativeName"
            name="creativeName"
            placeholder="Creative Name"
          />
          <ErrorMessage
            id="creativeName"
            name="creativeName"
            component={ErrorMessageStyled}
          />
          <InputWrapper
            id="creativeId"
            name="creativeId"
            placeholder="Creative Id"
          />
          <ErrorMessage
            id="creativeId"
            name="creativeId"
            component={ErrorMessageStyled}
          />
          <ButtonWrapper>
            <ModalButtonCancel type="button" onClick={closeModal}>
              Cancel
            </ModalButtonCancel>
            <ModalButtonStyled type="submit">Add</ModalButtonStyled>
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
};

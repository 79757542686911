import * as Yup from 'yup';

export const phoneRegExp = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/;
export const nameRegExp = /^[a-zA-Z]+(?:[\s.]+[a-zA-Z]+)*$/;
// eslint-disable-next-line
// const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{6,}/
// At least one upper and lower case, one number, and can have symbols

export const name = Yup.string()
  .trim()
  .matches(nameRegExp, 'Please enter a valid name.')
  .required('Please enter a valid name.');

export const phone = Yup.string()
  .trim()
  .matches(phoneRegExp, 'Please enter a valid phone number.')
  .required('Please enter a valid phone number.');

export const email = Yup.string()
  .trim()
  .email('Please enter a valid email.')
  .required('Please enter a valid email.');
// .lowercase('Please enter your email address in lowercase only.').strict()

export const password = Yup.string()
  .matches(/[a-z]/, 'Password must have at least one lowercase letter.')
  .matches(/[A-Z]/, 'Password must have at least one uppercase letter.')
  .matches(/.*[0-9].*/, 'Password must have at least one number.')
  .min(6, 'Password must have at least 6 characters.')
  .required('Please enter a valid password.');

import styled from 'styled-components';
import Button from '../../Components/Button';

export const downloadStyles = {
  background: 'white',
  width: '40px',
  height: '40px',
  border: '1px solid rgba(0,0,0,0.4)',
  cursor: 'pointer',
  borderRadius: '5px',
  marginLeft: '15px',
  outline: 'none',
};

export const Dot = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    background-color: #169aff;
    display: inline-block;
    margin: 2px;
`;

export const DotsContainer = styled.div`
    cursor: pointer;
`;

export const UnmatchedTablets = styled.div`
    margin: 0 auto;
    border: 1px solid red;
    background: #ffcccc;
    padding: 10px;
    text-align: left;
    color: red;
    margin-top: 20px;
    font-weight: 500;
`;

export const Container = styled.div`
    text-align: left;
    padding: 25px;
`;

export const Header = styled.div`
    padding: 0 20px 20px;
    font-size: 30px;
    font-weight: 600;
    color: #222222;
`;

export const PopupContainer = styled.div`
    background-color: white;
    border-radius: 5px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.16);
    overflow: hidden;
`;

export const PopupItem = styled.button`
    box-sizing: border-box;
    outline: none;
    color: #757575;
    font-size: 18px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border: none;
    width: 100%;
    padding: 0 10px;
    background: none;
    cursor: pointer;
    &:hover {
      color: #169aff;
      font-weight: 500;
      background: #e8f0fe;
    }
    //     &:not(:last-child) {
    //     border-bottom: 1px solid rgba(0,0,0,0.5);
    // }
`;

export const SearchInput = styled.input`
    height: 30px;
    line-height: 40px;
    font-size: 16px;
    width: 300px;
    border-radius: 5px;
    padding: 5px 10px;
    border: solid 1px rgba(0, 0, 0, 0.3);
    margin-left: 20px;
    &:hover,
    &:active {
      border: solid 1px #169aff;
    }
`;

export const ReloadAllButton = styled(Button)`
    display: inline-block;
    width: 200px;
    height: 36px;
    line-height: 36px;
    border-radius: 5px;
`;

export const FilterButton = styled(Button)`
    display: inline-block;
    width: 80px;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
`;

export const BadgeIcon = styled.img`
  width: 35px;
  height: 35px;
`;

export const AdPlatformImg = styled.img`
  width: 40px;
  height: 40px;
  margin: 5px;
`;

export const LogImg = styled.img`
  width: 200px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.16);
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
`;

/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Components
import { Formik, Form, FieldArray } from 'formik';
import Modal from '../../Components/Modal';
import AddClient from '../../Components/AddButton';

// Compositions
import Table from '../../Compositions/Table';
import DashboardTableTopBar from '../../Compositions/DashboardTableTopBar';
import FormSingleInput from '../../Compositions/FormSingleInput';
import ModalButtonGroup from '../../Compositions/ModalButtonGroup';

// Modals
import { ChangeIconModal, AddCampaignModal } from '../../Compositions/Modals';

// API
import {
  getInsightCompanies,
  getInsightCompanyByQuery,
  constructCompany,
  getInsightPlanners,
  linkPlannerToCompany,
  deleteInsightCompanies,
  updateInsightCompanies,
  addCampaignToCompany,
} from '../../Utilities/api';

// UTILS
import { DIVIDER, DELETE } from '../../Utilities/macros';

// ICON
import {
  AddIconStyled,
  ChangeIconStyled,
  PlannerIconStyled,
  DeleteIconStyled,
} from '../../Components/Icon/CollapsibleMenuIconGroup';

const LinkPlannerContainer = styled.div`
  border: solid 1px #757575;
  padding: 15px;
  margin: 30px auto;
`;

const LinkPlannersTitle = styled.span`
  display: block;
  line-height: 1.56;
  text-align: left;
  padding-bottom: 15px;
  color: #757575;
`;

const ModalTitle = styled.p`
  color: #222222;
  font-size: 21px;
  font-weight: 500;
  text-align: ${({ alignment }) => alignment || 'center'};
`;

const ModalSubtitle = styled.p`
  line-height: 1.56;
  padding: 30px;
  text-align: center;
  color: #757575;
`;

const shortCutMenuOptions = [
  {
    name: 'Link Planner',
    icon: PlannerIconStyled,
  },
  {
    name: 'Add Campaign',
    icon: AddIconStyled,
  },
  {
    name: 'Change Icon',
    icon: ChangeIconStyled,
  },
  {
    name: DIVIDER,
  },
  {
    name: 'Delete',
    icon: DeleteIconStyled,
  },
];

const plannerShortcutMenuOptions = [
  {
    name: 'Remove Planner',
    icon: DeleteIconStyled,
  },
];

const brandHeaders = {
  mainHeader: 'Brand Name',
  subHeaders: ['date added'],
};

const BrandsTopBarContent = ({ agencyName, agencyID, setTableContent }) => {
  const [modalContent, setModalContent] = useState(undefined);
  const closeModal = () => {
    setModalContent(undefined);
  };

  const handleSubmit = async (data) => {
    const results = await constructCompany(data, agencyID);
    if (results.error) return console.warn('Error: ', results.error);
    setTableContent((prevBrands) => [...prevBrands, results]);
    return closeModal();
  };

  return (
    <>
      <Modal isOpen={Boolean(modalContent)} onClose={closeModal}>
        <FormSingleInput
          createType={modalContent}
          toggle={closeModal}
          formTitle={`Add brand for ${agencyName}`}
          handleSubmit={handleSubmit}
        />
      </Modal>
      <AddClient onClick={() => setModalContent('Brand')}>+</AddClient>
    </>
  );
};

const LinkPlannerForm = (
  {
    _id: id, plannersChecked, title, agencyName, planners, buttonAction,
  },
  handleFormSubmit,
  closeModal,
) => (
  <Formik
    initialValues={{
      companyId: id,
      planners: [...plannersChecked],
    }}
    onSubmit={handleFormSubmit}
  >
    {({ values, touched }) => (
      <Form>
        <ModalTitle alignment="left">{title}</ModalTitle>
        <LinkPlannerContainer>
          <LinkPlannersTitle>
            {agencyName}
            {' '}
            Planners
          </LinkPlannersTitle>
          {planners.length > 0 ? (
            <FieldArray
              name="planners"
              render={(arrayHelpers) => planners.map((planner) => (
                <div key={`plannerKey-${planner.id}`}>
                  <input
                    id={planner.id}
                    name={planner.name}
                    className={planner.name}
                    value={values[planner.id]}
                    type="checkbox"
                    checked={values.planners.includes(planner.id)}
                    touched={touched[planner.id]}
                    onChange={(e) => {
                      if (e.target.checked) arrayHelpers.push(planner.id);
                      else {
                        const index = values.planners.indexOf(planner.id);
                        arrayHelpers.remove(index);
                      }
                    }}
                  />
                  <label htmlFor={planner.id}>{planner.name}</label>
                </div>
              ))}
            />
          ) : (
            <div> No Planneres Available </div>
          )}
        </LinkPlannerContainer>
        <ModalButtonGroup
          closeModal={closeModal}
          disabled={planners.length === 0}
          isForm
          text={buttonAction}
        />
      </Form>
    )}
  </Formik>
);

const DeleteModal = ({
  title, name, subtitle, action,
}, closeModal) => (
  <>
    <ModalTitle>
      {title}
      {name}
      ?
    </ModalTitle>
    <ModalSubtitle>{subtitle}</ModalSubtitle>
    <ModalButtonGroup closeModal={closeModal} action={action} text="Delete" />
  </>
);

const MediaAgencyBrandList = ({ match }) => {
  const [tableContent, setTableContent] = useState([]);
  const [modalContent, setModalContent] = useState(undefined);
  const [brand, setBrand] = useState();
  const [brandIds, setBrandIds] = useState();
  const [headers, setHeaders] = useState(brandHeaders);
  const agencyID = match.params && match.params.id;

  const closeModal = () => setModalContent(undefined);

  useEffect(() => {
    let isMounted = true;

    const fetchBrands = async (companyId) => {
      const companyResponse = await getInsightCompanyByQuery({ _id: companyId });

      if (companyResponse.error) {
        return console.log('Error fetching brands with ids: ', companyResponse.error);
      }

      console.log('Media agency brands:', companyResponse);

      if (isMounted) {
        console.log('company response: ', companyResponse);
        setBrand(companyResponse);
        const arrayBrandIds = companyResponse.brands.map((brandID) => brandID);
        setBrandIds(arrayBrandIds);
      }
      return null;
    };

    fetchBrands(agencyID);

    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyID]);

  useEffect(() => {
    let isMounted = true;
    let parsedData;

    const fetchClientList = async (ids) => {
      const data = await getInsightCompanies(ids);
      if (data.error && isMounted) {
        // TODO: ERROR HANDLING
        return console.log('ERROR ON DATA', data.error);
      }

      parsedData = data.map((item) => {
        const newItem = { ...item };
        if (item.type === 'Brand') {
          // <--- Media Agency's brands
          newItem.route = `/brand/${item._id}/campaigns`;
          newItem.reppedBy = brand && brand.name;
          newItem.reppedById = agencyID;
        } else {
          console.warn('This should not happen', item);
        }
        return newItem;
      });
      return setTableContent(parsedData);
    };

    if (brandIds) {
      console.log('Object has array of object IDs, HTTP request...');
      if (isMounted) {
        setHeaders(brandHeaders);
        fetchClientList(brandIds);
      }
    }

    return () => { isMounted = false; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandIds]);

  const mediaAgencyLocation = [
    {
      sectionName: 'Client List',
      route: '/clients',
    },
    {
      sectionName: `${brand && brand.name ? `Manage Brands - ${brand.name}` : ''}`,
    },
  ];

  const handleLinkPlannerFormSubmit = async (data) => {
    const payload = {
      plannersToAdd: [],
      plannersToDelete: [],
      companyId: data.companyId,
    };

    // Add if this planner does not exist in modalContent.plannersChecked
    data.planners.forEach((planner) => {
      if (!modalContent.plannersChecked.includes(planner)) {
        payload.plannersToAdd.push(planner);
      }
    });

    // Delete if planner is no longer checked in the form.
    modalContent.plannersChecked.forEach((checkedPlanner) => {
      if (!data.planners.includes(checkedPlanner)) {
        payload.plannersToDelete.push(checkedPlanner);
      }
    });

    if (
      payload.plannersToAdd.length === 0
      && payload.plannersToDelete.length === 0
    ) {
      closeModal();
      return console.log('Nothing needs to be updated, aborted');
    }

    const results = await linkPlannerToCompany(payload);

    if (results.error) {
      return console.warn('Error: ', results.error);
    }
    // closeModal(); //TODO: mutate props.location.state?
    return window.location.reload();
  };

  const setModaltoForm = async (targetDocument) => {
    // Find planners of this agency, and of this brand
    const plannerListWithAgency = await getInsightPlanners([
      agencyID,
      targetDocument._id,
    ]);

    // Something went wrong
    if (
      plannerListWithAgency.error /* || plannerListWithAgency.masterList.length === 0 */
    ) {
      return console.warn(
        'Error on getting planners',
        plannerListWithAgency.error,
      );
    }

    const { masterList, assignedList } = plannerListWithAgency;

    // Map planner ID & name for form, marked planner that already has membership.
    const planners = masterList.planner
      ? masterList.planner.map((planner) => ({
        id: planner._id,
        name: planner.name,
      }))
      : [];
    const plannersChecked = assignedList.planner
      && assignedList.planner.map((planner) => planner._id);

    // Form set up:
    return setModalContent({
      ...targetDocument,
      optionType: targetDocument.optionType,
      agencyName: brand.name,
      name: targetDocument.name,
      _id: targetDocument._id,
      title: `Link planner to ${targetDocument.name} - ${brand.name} `,
      planners,
      plannersChecked: plannersChecked || [],
      buttonAction: 'create',
    });
  };

  const deleteBrand = async (id) => {
    const results = await deleteInsightCompanies(id, agencyID);
    if (results.error) {
      return console.log('ERROR: ', results.error);
    }
    // TODO: When deleting a brand, also remove its planners!
    return window.location.reload();
  };

  const updateBrandPlanners = async (plannerId, companyId) => {
    const payload = {
      plannersToAdd: [],
      plannersToDelete: [plannerId],
      companyId,
    };
    const results = await linkPlannerToCompany(payload);
    if (results.error) return console.log('Error : ', results.error);
    return window.location.reload();
  };

  const handleAddCampaign = async ({
    startDate,
    endDate,
    campaignName,
    impressionGoal,
    _id,
    setSubmitting,
    setFieldError,
  }) => {
    const datePattern = 'YYYY-MM-DDTHH:mm:ss';
    const formattedStartDate = (startDate).format(datePattern);
    const formattedEndDate = (endDate).format(datePattern);

    if (formattedStartDate > formattedEndDate) return setFieldError('startDate', 'Start date cannot be after end date');

    const campaign = {
      name: campaignName,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      goal: parseInt(impressionGoal, 10),
      dateAdded: new Date(),
    };

    setSubmitting(true);
    const results = await addCampaignToCompany(_id, campaign);
    setSubmitting(false);

    if (results.error) return setFieldError('campaignName', results.error);

    return closeModal();
  };

  const changeBrandIcon = async (info) => {
    const response = await updateInsightCompanies(info);

    if (response.error) {
      return console.log('Error changing brand logo', response); // TODO: Handle error for fail
    }
    return window.location.reload();
  };

  const setModalToDelete = (targetDocument) => {
    setModalContent({
      ...targetDocument,
      optionType: 'Delete',
      title: 'Do you want to permanently delete',
      icon: DeleteIconStyled,
      subtitle:
        'Please note that this will delete all data associated with this client',
      action: () => deleteBrand(targetDocument._id),
    });
  };

  const setModalToDeletePlanner = (targetDocument) => {
    setModalContent({
      ...targetDocument,
      optionType: 'Delete',
      title: 'Do you want to remove',
      icon: DeleteIconStyled,
      subtitle:
        'Please note that this will remove the link with this planner to this brand',
      action: () => updateBrandPlanners(targetDocument.plannerId, targetDocument.companyId),
    });
  };

  const setModalToChangeIcon = ({ _id }) => {
    setModalContent({
      optionType: 'Change',
      title: 'Would you like to change the icon for the following company: ',
      action: ({ logo }) => changeBrandIcon({ logo, _id }),
    });
  };

  const setModalToAddCampaign = ({ name, _id }) => {
    setModalContent({
      optionType: 'Add Campaign',
      title: 'Add campaign for ',
      name,
      action: (values, { setSubmitting, setFieldError }) => handleAddCampaign({
        setSubmitting, setFieldError, ...values, _id,
      }),
    });
  };

  const handleModalContent = async (targetDocument) => {
    switch (targetDocument.optionType) {
      case 'Link Planner':
        return setModaltoForm(targetDocument);
      case DELETE:
        return setModalToDelete(targetDocument);
      case 'Remove Planner':
        return setModalToDeletePlanner(targetDocument);
      case 'Change Icon':
        return setModalToChangeIcon(targetDocument);
      case 'Add Campaign':
        return setModalToAddCampaign(targetDocument);
      default:
        return console.log('Option not implemented yet...');
    }
  };

  const switchModals = () => {
    if (!modalContent) return null;
    switch (modalContent.optionType) {
      case 'Link Planner':
        return LinkPlannerForm(
          modalContent,
          handleLinkPlannerFormSubmit,
          closeModal,
        );
      case 'Change':
        return <ChangeIconModal modalContent={modalContent} closeModal={closeModal} />;
      case 'Add Campaign':
        return <AddCampaignModal modalContent={modalContent} closeModal={closeModal} />;
      default:
        return DeleteModal(modalContent, closeModal);
    }
  };

  return (
    <>
      {modalContent && (
        <Modal
          isOpen={Boolean(modalContent)}
          onClose={closeModal}
          icon={modalContent.icon}
        >
          {switchModals()}
        </Modal>
      )}
      <DashboardTableTopBar location={mediaAgencyLocation}>
        <BrandsTopBarContent
          agencyName={brand && brand.name}
          agencyID={agencyID}
          setTableContent={setTableContent}
        />
      </DashboardTableTopBar>
      <Table
        list={tableContent}
        headers={headers}
        menuContent={shortCutMenuOptions}
        setModalContent={handleModalContent}
        plannerShortcutMenuOptions={plannerShortcutMenuOptions}
      />
    </>
  );
};

export default MediaAgencyBrandList;

/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

// Components
import ArrowIcon from '../Icon/Arrow';
import ShortcutMenuIcon from '../Icon/ShortcutMenu';
import Tooltip from '../Tooltip';
import MenuOptions from '../MenuOption';

// Utils
import { getInsightPlanners } from '../../Utilities/api';
import { DIVIDER } from '../../Utilities/macros';

export const EDGE_SECTION = '35px';

export const MAIN_SECTION_STYLE = css`
  display: flex;
  align-items: center;
  flex: 1 0 320px;
`;

const MainSection = styled.span`
  ${MAIN_SECTION_STYLE}
`;

export const ROW_TEMPLATE = css`
  padding: 17px 20px;
  line-height: 1.56;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-align: left;
`;

const ParentBox = styled.div`
  border-radius: 10px;
  border: solid 1px #d6d6d6;
  background-color: #ffffff;
  ${ROW_TEMPLATE}
`;

const ChildBox = styled.div`
  ${ROW_TEMPLATE}
`;

export const DATA_STYLE = css`
  width: 150px;
  padding: 0 25px;
  flex-grow: 0;
  flex-shrink: 1;
  cursor: pointer;
  font-size: 13px;
`;

const Data = styled.span`
  ${DATA_STYLE}
  color: ${({ isActiveStatus }) => isActiveStatus && '#30de92'};
`;

const Arrow = styled.span`
  cursor: pointer;
  width: ${EDGE_SECTION};
  //padding-right: 25px;
`;

const Logo = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background: url(${(props) => props.src}) center center no-repeat;
  background-size: cover;
`;

const PlannerDisplay = styled(Logo)`
  border-radius: 50%;
  background: #d7d7d7;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const Title = styled.span`
  padding: 0 10px;
  flex-grow: 1;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
`;

const PlannerEmail = styled.div`
  font-weight: 400;
`;

const PlannerList = styled.div`
  padding: ${({ collapse }) => (collapse ? '15px 0px' : '1px 0px')};
  opacity: ${({ collapse }) => (collapse ? '1' : '0')};
  max-height: ${({ collapse }) => (collapse ? '100%' : '0')};
  transition: padding 0.8s, opacity 0.5s ease-in;

  transition-delay: 0.1s;
  transition-property: padding;
`;

const ShortcutMenuWrapper = styled.span`
  width: ${EDGE_SECTION};
  height: ${EDGE_SECTION};
  border-radius: 50%;
  cursor: pointer;
  background: ${({ selected }) => selected && '#e8f0fe'}};
`;

const MenuOptionLightFont = styled(MenuOptions)`
  font-weight: 400;
`;

const ShortCutMenuContainer = styled.div`
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
`;

const Divider = styled.hr`
  height: 4px;
  border: none;
  border-bottom: 1px solid #d7d7d7;
  padding: 0px 15px;
`;

const ShortCutMenuPopup = ({ setModalContent, menuContent, info }) => {
  if (!menuContent) console.log('no menu content found');
  const toggleClick = (optionType) => {
    setModalContent({ optionType, ...info });
  };

  return (
    <ShortCutMenuContainer>
      {menuContent
        && menuContent.map((option) => {
          if (option.name === DIVIDER) {
            return <Divider key={`divider-${option.name}`} />;
          }
          return (
            <MenuOptionLightFont
              key={`divider-${option.name}`}
              name={option.name}
              icon={option.icon}
              onClick={() => toggleClick(option.name)}
            />
          );
        })}
    </ShortCutMenuContainer>
  );
};

const ShortcutMenu = ({
  data,
  menuContent,
  setModalContent,
}) => {
  const [menuSelected, setMenuSelected] = React.useState(false);

  const toggleMenuSelected = () => setMenuSelected((prevState) => !prevState);

  return (
    <ShortcutMenuWrapper selected={menuSelected}>
      <Tooltip
        onVisibilityChange={toggleMenuSelected}
        placement="bottom-end"
        trigger="click"
        tooltip={(
          <ShortCutMenuPopup
            setModalContent={setModalContent}
            menuContent={menuContent}
            info={{
              ...data,
            }}
          />
        )}
      >
        <ShortcutMenuIcon />
      </Tooltip>
    </ShortcutMenuWrapper>
  );
};

const Collapsible = ({
  headers,
  data,
  menuContent,
  setModalContent,
  plannerShortcutMenuOptions,
}) => {
  const ACTIVE = 'Active';
  const history = useHistory();
  const [collapse, setCollapse] = React.useState(false);
  const [collapsedContent, setCollapsedContent] = React.useState(null);

  let isMounted = true;

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isMounted = true;

    return () => {
      isMounted = false;
    };
  }, []);

  React.useEffect(() => {
    if (collapse) {
      setCollapse(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const toggleCollapsible = async (id) => {
    setCollapse((prevCollapseState) => !prevCollapseState);

    // if (collapsedContent) {
    //     return;
    // }

    const plannersData = await getInsightPlanners([id]);
    if (plannersData.error && isMounted) {
      console.warn('Error', plannersData.error);
      return setCollapsedContent(null);
    }

    if (plannersData.masterList && isMounted) {
      setCollapsedContent(plannersData.masterList.planner);
    }
    return null;
  };

  const processDBObjectText = (text) => {
    switch (text) {
      case 'status':
        return data.disabled ? 'Inactive' : ACTIVE;
      case 'date added':
        return new Date(data.dateAdded).toLocaleDateString();
      case 'impression':
        return data.goal ? data.goal : '';
      default:
        return data[text];
    }
  };

  const expandOnDetails = () => {
    setCollapse(false);

    // history.push data.route, pass state if there's any
    history.push({
      pathname: data.route,
      state: data,
    });
  };

  return (
    <div>
      <ParentBox>
        <MainSection>
          <Arrow
            onClick={() => plannerShortcutMenuOptions && toggleCollapsible(data._id)}
          >
            {plannerShortcutMenuOptions && <ArrowIcon selected={collapse} />}
          </Arrow>
          <Logo src={data.logo} />
          <Title onClick={expandOnDetails}>{data.name}</Title>
        </MainSection>
        {headers.map((header) => {
          const content = processDBObjectText(header);
          const isActiveStatus = content === ACTIVE;
          return (
            <Data isActiveStatus={isActiveStatus} key={`data-${header}`}>
              {content}
            </Data>
          );
        })}
        <ShortcutMenu
          data={data}
          menuContent={menuContent}
          setModalContent={setModalContent}
        />
      </ParentBox>
      <PlannerList collapse={collapse}>
        {collapsedContent
          && collapse
          && collapsedContent.map((planner, index) => (
            <div key={`collapsible-${planner.email}`}>
              <ChildBox>
                <MainSection>
                  <Arrow />
                  <PlannerDisplay src={planner.photo} />
                  <Title>
                    {planner.name}
                    <PlannerEmail>{planner.email}</PlannerEmail>
                  </Title>
                </MainSection>
                <Data />
                <Data>{planner.disabled ? 'Inactive' : 'Active'}</Data>
                <Data>{planner.dateAdded}</Data>
                <ShortcutMenu
                  data={{
                    plannerName: planner.name,
                    plannerId: planner._id,
                    companyId: data._id,
                  }}
                  menuContent={plannerShortcutMenuOptions}
                  setModalContent={setModalContent}
                  setCollapse={setCollapse}
                />
              </ChildBox>
              {index + 1 < collapsedContent.length && <Divider />}
            </div>
          ))}
        {!collapsedContent && collapse && (
          <ChildBox>
            {' '}
            <span style={{ fontWeight: 600 }}> Planner(s) not found</span>
          </ChildBox>
        )}
      </PlannerList>
    </div>
  );
};

export default Collapsible;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

// Components
import Modal from '../../Components/Modal';
import Button from '../../Components/Button';

const Image = styled.div`
  position: absolute;
  width: 95%;
  background: url("${({ src }) => src}") center center no-repeat;
  background-size: cover;
  left: 2.5%;
  right: 2.5%;
  top: 3%;
  bottom: 10%;
  border-radius: 5px;
`;

const TabletMockup = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 62%;
  background: url("/assets/welcome-screen-mockup.png") center center no-repeat;
  background-size: contain;
  border-radius: 10px;
`;

const Svg = styled.svg`
  width: 83.5px;
  height: 83.5px;
  margin: 30px auto 0;
  fill: #85c7ff;
`;

const DragText = styled.p`
  font-size: 18px;
  padding-top: 20px;
  min-width: 400px;
`;

const OrText = styled.p`
  padding: 20px 0;
  text-transform: uppercase;
  color: #bdbdbd;
  font-size: 18px;
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  width: 216px;
  margin: 0 auto;
`;

const Spacing = styled.div`
  height: 15px;
`;

const FileInput = styled.input`
  display: none;
`;

const TabletPreview = styled.div`
  padding-top: 30px;
  width: 600px;
  //height: 374px;
`;

const NameInput = styled.input`
  height: 45px;
  box-sizing: border-box;
  margin-top: 15px;
  border-radius: 10px;
  border: solid 1px #d6d6d6;
  background-color: #ffffff;
  padding: 10px 25px;
  width: 100%;
`;

const UploadSvg = styled.svg`
  margin-right: 10px;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadImageSvg = () => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    viewBox="0 0 24 24"
    width={24}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z" />
  </Svg>
);

const UploadFileSvg = ({ color }) => (
  <UploadSvg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
  >
    <g id="Group_874" data-name="Group 874" transform="translate(-757 -678)">
      <g id="insert_drive_file-24px" transform="translate(757 678)">
        <path
          id="Path_494"
          data-name="Path 494"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_495"
          data-name="Path 495"
          d="M14,2H6A2,2,0,0,0,4.01,4L4,20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8ZM6,20V4h7V9h5V20Z"
          fill={color || '#fff'}
        />
      </g>
      <path
        id="Path_497"
        data-name="Path 497"
        d="M10.433,5.191l1.482,1.476.807-.807L9.861,3,7,5.861l.807.807L9.288,5.191v5.532h1.144Z"
        transform="translate(759.139 685.889)"
        fill={color || '#fff'}
      />
    </g>
  </UploadSvg>
);

const UploadImageModel = ({
  onCloseModel,
  openModal,
  creative,
  setCreative,
  onSubmit,
  children,
}) => {
  // const [creative, setCreative] = React.useState({
  //     url: undefined,
  //     blob: undefined
  // });

  const handleFile = (file) => {
    try {
      const blob = new Blob([file], { type: file.type });

      const url = URL.createObjectURL(blob);

      setCreative({
        url,
        blob,
      });
    } catch (error) {
      console.log('Error parsing file: ', error);
    }
  };

  const handleUpload = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      handleFile(file);
    }
  };

  const onDrop = React.useCallback((acceptedFiles) => {
    handleFile(acceptedFiles[0]);
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Modal
      isOpen={openModal}
      onClose={onCloseModel}
      title="Upload file"
      center
      maxWidth={creative.blob && '800px'}
    >
      {creative.blob ? (
        <TabletPreview>
          {children || (
            <>
              <TabletMockup>
                <Image src={creative.url} />
              </TabletMockup>
              <NameInput placeholder="Add title (i.e. 'Creative A')" />
            </>
          )}
          <Spacing />
          <ButtonWrapper>
            <FileInput type="file" id="upload" onChange={handleUpload} />
            <Button
              color="#169aff"
              borderColor="#169aff"
              fontSize="21px"
              onClick={onSubmit}
            >
              Save
            </Button>
            <Spacing />
            <Button
              as="label"
              action="return"
              htmlFor="upload"
              borderColor="#169aff"
              fontSize="21px"
            >
              <FlexCenter>
                <UploadFileSvg color="#169aff" />
                Upload file
              </FlexCenter>
            </Button>
            <Spacing />
            <Button
              action="return"
              color="#f1f1f1"
              borderColor="#f1f1f1"
              fontSize="21px"
              fontColor="#222222"
              onClick={onCloseModel}
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </TabletPreview>
      ) : (
        <>
          <div {...getRootProps()}>
            <UploadImageSvg />
            <DragText>
              {isDragActive ? 'Drop here' : 'Drag and drop your file here'}
            </DragText>
            <input {...getInputProps()} />
          </div>
          <OrText>Or</OrText>
          <ButtonWrapper>
            <FileInput type="file" id="upload" onChange={handleUpload} />
            <Button
              as="label"
              htmlFor="upload"
              color="#169aff"
              borderColor="#169aff"
              fontSize="21px"
            >
              <FlexCenter>
                <UploadFileSvg />
                Upload file
              </FlexCenter>
            </Button>
            <Spacing />
            <Button
              action="return"
              color="#f1f1f1"
              borderColor="#f1f1f1"
              fontSize="21px"
              fontColor="#222222"
              onClick={onCloseModel}
            >
              Cancel
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Modal>
  );
};

export default UploadImageModel;

import React, { useState } from 'react';
import styled from 'styled-components';
import Tooltip from '../Tooltip';

const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background-color: #169aff;
  display: inline-block;
  margin: 2px;
`;
const DotsContainer = styled.div`
  cursor: pointer;
`;

const PopupContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.16);
  overflow: hidden;
`;
const PopupItem = styled.button`
  box-sizing: border-box;
  outline: none;
  color: #757575;
  font-size: 18px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border: none;
  width: 100%;
  padding: 0 10px;
  background: none;
  cursor: pointer;
  &:hover {
    color: #169aff;
    font-weight: 500;
    background: #e8f0fe;
  }
  //     &:not(:last-child) {
  //     border-bottom: 1px solid rgba(0,0,0,0.5);
  // }
`;

const PopupMenu = ({ items }) => {
  const Item = ({ text, action, onSuccess }) => {
    const [loading, setLoading] = useState(false);
    const handleAction = async () => {
      try {
        setLoading(true);
        const res = await action();
        setLoading(false);
        if (onSuccess) onSuccess(res);
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <PopupItem onClick={handleAction}>
        {loading ? 'loading...' : text}
      </PopupItem>
    );
  };

  return (
    <PopupContainer>
      {items.map(({ text, action, onSuccess }) => (
        <Item
          key={text}
          text={text}
          action={action}
          onSuccess={onSuccess}
        />
      ))}
    </PopupContainer>
  );
};

const DropdownMenu = ({ items }) => (
  <Tooltip
    // onVisibilityChange={toggleMenuSelected}
    placement="bottom-end"
    trigger="click"
    tooltip={<PopupMenu items={items} />}
  >
    <DotsContainer>
      <Dot />
      <Dot />
      <Dot />
    </DotsContainer>
  </Tooltip>
);

export default DropdownMenu;

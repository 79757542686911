/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie';

const options = {
  width: 227,
  height: 227,
  innerRadius: 0.65,
  colors: ['#ffffff'],
  defs: [
    {
      id: 'gradientB',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: 'hsla(200, 100%, 56%, 1)' },
        { offset: 100, color: 'hsla(200, 100%, 56%, 0.3)' },
      ],
    },
  ],
  fill: [
    {
      match: {
        id: 'Impression',
      },
      id: 'gradientB',
    },
  ],
  enableSlicesLabels: false,
  isInteractive: false,
  enableRadialLabels: false,
  enableArcLabels: false,
};

const ChartWrapper = styled.div`
  height: 69px;
  width: 227px;
  margin: 10px auto 0;
`;

const Number = styled.div`
  color: #003863;
  font-size: 49px;
  font-weight: 600;
  text-align: center;
`;

const Description = styled.div`
  text-align: center;
`;

const ImpressionChart = ({ percent }) => (
  <>
    <ChartWrapper>
      <ResponsivePie
        data={[
          {
            id: 'Impression',
            value: percent,
          },
          {
            id: 'Total',
            color: 'hsl(0, 100%, 100%)',
            value: 100 - percent,
          },
        ]}
        {...options}
      />
    </ChartWrapper>
    <Number>
      {percent}
      %
    </Number>
    <Description>
      of impression
      <br />
      goal
    </Description>
  </>
);

export default ImpressionChart;

import styled from 'styled-components';
import React from 'react';
import { ReactComponent as HubspotIcon } from './Hubspot.svg';

const HubspotIconStyled = styled(HubspotIcon)`
    height: 35px;
    width: 35px;
    stroke: #043650;
    stroke-width: 27px;
    fill:none;
    padding: 0 10px;

`;

const HubspotLink = styled.a`
    text-decoration: none;
    :hover {
        cursor: pointer;
        transform: scale(1.05);
    }
`;
const Hubspot = ({ url }) => (
  <HubspotLink href={url} target="_blank">
    <HubspotIconStyled />
  </HubspotLink>
);
export default Hubspot;

const serverUrl = process.env.REACT_APP_SERVER_URL;
const profileUrl = process.env.REACT_APP_DRIVER_URL;
const strapiUrl = process.env.REACT_APP_STRAPI_URL;

export const BLOCK_USERS_FROM_STAGING = 'Please login or sign up via https://drivers.portlmedia.com';

const MOBILE_THRESHOLD = 1024;

console.log('env var:', process.env.REACT_APP_ENV);

export {
  MOBILE_THRESHOLD, serverUrl, profileUrl, strapiUrl,
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Prompt } from 'react-router-dom';

// Components
import Loading from '../../Components/Loading';
import Button from '../../Components/Button';
import CollapsibleBlock, {
  CollapsibleTitleText,
} from '../../Components/CollapsibleBlock';

// Compositions
import ModalTemplate from '../ModalTemplate';

// Utils
import { initConnection } from '../../Utilities/socket';
import { saveLogsToText } from '../../Utilities/helper';

// TODO: on all device page, have error handling if page doesnt load.

const LogsContainer = styled.div`
  margin: 0 auto;
  width: 97%;
  overflow: scroll;
  border: 1px solid #d7d7d7;
  border-radius: 15px;
  padding-bottom: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

const StyledSeparator = styled.div`
  border-bottom: 1.5px dotted #d7d7d7;
`;

const StreamMenu = styled.div`
  border-bottom: 1px solid #d7d7d7;
  width: 96%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  margin: 0 auto;
`;

const StreamMenuItem = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-weight: 500;
  flex: 0 1 200px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-right: 25px;
`;

const Label = styled.div`
  background-color: #e8f0fe;
  padding: 12px;
  border-radius: 10px 0 0 10px;
`;

const Count = styled.div`
  margin: 0 auto;
  padding: 0 15px;
`;

const StreamTitle = styled.div`
  font-size: 16px;
  align-self: center;
  padding: 5px;
  font-weight: 600;
  border-bottom: 1px solid #d7d7d7;
  width: 97%;
  padding-top: 15px;
  margin: 0 auto;
  text-align: left;
`;

const ModalContent = styled.div`
  padding: 30px 30px 0 30px;
`;

const TurnOffButton = styled.div`
  padding-top: 20px;
`;
const ButtonWrapper = styled.div`
  margin-right: 25px;
`;

const LogSection = styled.div`
  padding: 1em;
  overflow: scroll;
`;

const LogSubtitle = styled.span`
  font-style: italic;
  font-size: 12px;
  font-weight: 300;
  color: #222222;
`;

const LogsStreamer = ({ clientID }) => {
  const [modalOpen, setmodalOpen] = useState(false);
  const [log, setLogs] = useState([]);
  const [isStream, setStreaming] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const logBottomRef = React.useRef(null);
  const socket = initConnection();

  const enableStream = (start) => {
    if (start) {
      if (socket && !socket.connected) {
        socket.open();
      }
      setStreaming(true);
      setStartTime(new Date().toLocaleTimeString());
      socket.emit('adminRequest', clientID);
    } else {
      setStreaming(false);
      setEndTime(new Date().toLocaleTimeString());
      socket.close();
    }
  };

  const scrollToBottom = () => {
    logBottomRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  const blockNavigation = () => {
    if (isStream) {
      setmodalOpen(true);
      return false;
    }
    return null;
  };

  const unblockNavigation = () => {
    setmodalOpen(false);
    enableStream(false);
  };

  const parseLogs = (logGroup) => {
    // case 1, length: is 1 //console.log("state.pop")
    // case 2: length is >= 2  //console.log("URL", "http://asdkfalsdfjasdf", "200")
    // case 3: length is >= and has object //console.log("Ad Algorh", obj)
    // case 4: length is >=2 and has array
    // case 5: length is >=2 and has array, within array has nested
    // Can one case handle both nested array and nester objects

    const prettierJSON = (log2) => {
      try {
        return JSON.stringify(JSON.parse(log2), null, 2);
      } catch (error) {
        return `could not parse log ${error}`;
      }
    };

    // First line always string, check second line
    // LogGroup[1] may have null object
    if (
      logGroup.length > 1
      && logGroup[1]
      && (logGroup[1].includes('{') || logGroup[1].includes('['))
    ) {
      let logSubtitle;
      if (logGroup[1].includes('[')) {
        try {
          logSubtitle = ` Array: (${JSON.parse(logGroup[1]).length}) items`;
        } catch (error) {
          console.log('Error on parsing log: ', logGroup);
          logSubtitle = 'Error [Check console]';
        }
      } else {
        logSubtitle = ` Object: ${logGroup[1]}`;
      }

      const logContent = logGroup.slice(1);
      return (
        <CollapsibleBlock title={logGroup[0]} subtitle={logSubtitle} index={0}>
          {logContent.map((thatLog) => (
            <pre key={`pre${thatLog[0] + thatLog[1]}`}>{prettierJSON(thatLog)}</pre>
          ))}
        </CollapsibleBlock>
      );
    }

    if (logGroup.length > 1) {
      return (
        <CollapsibleTitleText>
          {logGroup[0]}
          <LogSubtitle>{logGroup.slice(1).join('  ')}</LogSubtitle>
        </CollapsibleTitleText>
      );
    }

    return (
      <CollapsibleTitleText>
        {logGroup ? logGroup[0] : 'Recieved `undefined` log group'}
      </CollapsibleTitleText>
    );
  };

  const handleLogs = ({ logs }) => {
    setLogs((prevLogs) => [...prevLogs, logs]);
  };

  useEffect(() => {
    socket.on('tabletLogs', handleLogs);
    if (logBottomRef.current) {
      scrollToBottom();
    }

    if (log.length >= 500) {
      enableStream(false);
      console.log('Log Size at 500, terminating stream');
      // console.log('Log Size At 500');
    }

    return () => {
      enableStream(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LogsContainer>
      <Prompt when={isStream} message={blockNavigation} />
      <StreamTitle>Stream Logs</StreamTitle>
      <StreamMenu>
        <StreamMenuItem>
          <Label>Count</Label>
          <Count>
            {' '}
            {log.length}
            {' '}
          </Count>
        </StreamMenuItem>
        <StreamMenuItem>
          <Label>Started:</Label>
          <Count>{startTime}</Count>
        </StreamMenuItem>
        <StreamMenuItem>
          <Label>Ended:</Label>
          <Count>{endTime}</Count>
        </StreamMenuItem>
        <ButtonWrapper>
          <Button type="small" onClick={() => enableStream(!isStream)}>
            {isStream ? 'Streaming...' : 'Stream'}
          </Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            type="small"
            disabled={isStream || log.length <= 0}
            onClick={() => saveLogsToText(log, 'streamed.txt')}
          >
            Download
          </Button>
        </ButtonWrapper>
      </StreamMenu>
      <LogSection>
        {isStream && log.length === 0 ? (
          <Loading paddingTop="30" />
        ) : (
          log.map((logGroup) => (
            <div key={logGroup}>
              {parseLogs(logGroup)}
              <StyledSeparator />
            </div>
          ))
        )}
        <div ref={logBottomRef} />
      </LogSection>
      <ModalTemplate modalOpen={modalOpen} setModalOpen={setmodalOpen}>
        <ModalContent>
          You&apos;re streaming logs. Click
          &quot;
          Turn Off
          &quot;
          to stop streaming.
          <TurnOffButton>
            <Button type="small" onClick={unblockNavigation}>
              Turn Off
            </Button>
          </TurnOffButton>
        </ModalContent>
      </ModalTemplate>
    </LogsContainer>
  );
};

export default LogsStreamer;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ResponsiveLine } from '@nivo/line';

import GreaterSign from '../Icon/GreaterSign';

const options = {
  theme: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    textColor: '#222222',
    grid: {
      line: {
        stroke: 'rgba(0,0,0,0.16)',
        strokeWidth: 0.3,
        strokeDasharray: '25 20',
      },
    },
  },
  defs: [
    {
      id: 'gradientA',
      type: 'linearGradient',
      colors: [
        { offset: 0, color: 'hsla(200, 100%, 56%, 1)' },
        { offset: 100, color: 'hsla(200, 100%, 56%, 0.25)' },
      ],
    },
  ],
  fill: [
    {
      match: {
        id: 'impressionGraph',
      },
      id: 'gradientA',
    },
  ],
  margin: {
    top: 40,
    right: 25,
    bottom: 32,
    left: 70,
  },
  xyScale: {
    type: 'linear',
    min: 0,
    max: 'auto',
  },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: 'bottom',
    tickSize: 3,
    tickPadding: 12,
  },
  axisLeft: {
    orient: 'left',
    tickSize: 0,
    tickPadding: 20,
  },
  enableGridX: false,
  colors: ['#ffffff'],
  lineWidth: 0.5,
  enablePoints: false,
  enableArea: true,
  areaOpacity: 1,
};

const Wrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin: 20px auto;
`;

const GraphWrapper = styled.div`
  height: 265px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #222222;
  padding: 14px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 14px auto 15px;
`;

const DatePickerStyled = styled.div`
  display: inline-block;
  font-size: 13px;
  width: 130px;
  margin: 14px auto 15px;
`;

const ArrowWrapper = styled.button`
  display: inline-block;
  margin: 0 30px;
  border: none;
  background: transparent;
`;

const showDefault = [
  { x: '12am', y: '0' },
  { x: '1am', y: '0' },
  { x: '2am', y: '0' },
  { x: '3am', y: '0' },
  { x: '4am', y: '0' },
  { x: '5am', y: '0' },
  { x: '6am', y: '0' },
  { x: '7am', y: '0' },
  { x: '8am', y: '0' },
  { x: '9am', y: '0' },
  { x: '10am', y: '0' },
  { x: '11am', y: '0' },
  { x: '12pm', y: '0' },
  { x: '1pm', y: '0' },
  { x: '2pm', y: '0' },
  { x: '3pm', y: '0' },
  { x: '4pm', y: '0' },
  { x: '5pm', y: '0' },
  { x: '6pm', y: '0' },
  { x: '7pm', y: '0' },
  { x: '8pm', y: '0' },
  { x: '9pm', y: '0' },
  { x: '10pm', y: '0' },
  { x: '11pm', y: '0' },
];

const mapToCoords = (arr = []) => arr.map(({ time, count }) => ({
  x: moment(time).format('ha'),
  y: count,
}));

const ImpressionGraph = ({ data = [], startDate, endDate }) => {
  const [showData, setShowData] = React.useState(showDefault);
  const [filteredDate, setFilteredDate] = React.useState(moment());
  const [arrowToggle, setArrowToggle] = React.useState({ left: true, right: true });

  const datesInfo = new Map();

  const handleDateChange = (change) => {
    const changedDate = filteredDate.clone();

    if (change) {
      changedDate.add(1, 'd');
    } else {
      changedDate.subtract(1, 'd');
    }

    datesInfo.set(filteredDate.format('L'), showData);

    const left = changedDate.isSame(startDate, 'day');
    const right = changedDate.isSame(endDate, 'day') || changedDate.isSame(moment(), 'day');
    setArrowToggle({ left, right });
    setFilteredDate(changedDate);
  };

  const handleFilterDates = () => {
    const filteredData = data.filter(({ time }) => moment(time).isSame(filteredDate, 'day'));
    setShowData(mapToCoords(filteredData));
  };

  React.useEffect(() => {
    if (data.length > 0) {
      handleFilterDates();
    }
    // eslint-disable-next-line
  }, [data, filteredDate]);

  // React.useEffect(() => {
  //   const dateString = filteredDate.format('L');

  //   if (!data) return;
  //   if (datesInfo.has(dateString)) {
  //     setShowData(datesInfo.get(dateString));
  //   } else {
  //     handleFilterDates();
  //   }
  //   // eslint-disable-next-line
  // }, [filteredDate]);

  React.useEffect(() => {
    const now = moment();
    setArrowToggle({ right: true, left: false });
    if (endDate.diff(now) < 0) {
      setFilteredDate(endDate);
    } else {
      setFilteredDate(now);
    }
    // eslint-disable-next-line
  }, [endDate]);
  return (
    <Wrapper>
      <Title>Impressions</Title>
      <GraphWrapper>
        {showData && (
          <ResponsiveLine
            data={[
              {
                id: 'impressionGraph',
                data: showData,
              },
            ]}
            {...options}
          />
        )}
      </GraphWrapper>
      <DateWrapper>
        <ArrowWrapper
          onClick={() => handleDateChange(false)}
          disabled={arrowToggle.left}
        >
          <GreaterSign left="true" disabled={arrowToggle.left} />
        </ArrowWrapper>
        <DatePickerStyled>{filteredDate.format('LL')}</DatePickerStyled>
        <ArrowWrapper
          onClick={() => handleDateChange(true)}
          disabled={arrowToggle.right}
        >
          <GreaterSign disabled={arrowToggle.right} />
        </ArrowWrapper>
      </DateWrapper>
    </Wrapper>
  );
};

export default ImpressionGraph;

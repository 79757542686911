import React from 'react';
import styled from 'styled-components';

import { withRouter } from 'react-router-dom';
import Modal from '../../Components/Modal';
import { ModalButton, ModalButtonCancel } from '../../Components/ModalButton';
import Spacing from '../../Components/Spacing';
import LogoutIcon from '../../Components/Icon/Logout';
import { logUserOut } from '../../Utilities/api';

// const LogoutIcon = styled.img`
//     width: 53px;
//     height: 53px;
// `
// const LogoutTitle = styled.div`
//     padding-top: 15px;
//     color: #757575;
//     line-height: 29px;
// `

const Note = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #000000;
  margin: 0 24px 30px;
`;

const ModalButtonStyled = styled(ModalButton)`
  width: 180px;
  font-size: 14px;
`;

const ModalButtonCancelStyled = styled(ModalButtonCancel)`
  width: 180px;
  background-color: #f1f1f1;
  font-size: 14px;
  color: #000000;
`;

// const LogoutIconHeader = () => (
//     <React.Fragment>
//         <LogoutIcon src={'./logout-icon.png'} />
//         <LogoutTitle>Log Out</LogoutTitle>
//     </React.Fragment>
// );

const handleLogout = async () => {
  await logUserOut();
  window.location.reload();
};

const Logout = ({ modalOpen, setModalOpen }) => (
  <Modal
    isOpen={modalOpen}
    onRequestClose={setModalOpen}
    maxWidth="355px"
    center
  >
    <Note>
      {' '}
      Are you sure you want to
      <br />
      {' '}
      log out?
      {' '}
    </Note>
    <ModalButtonStyled onClick={handleLogout}>
      <LogoutIcon fill="#ffffff" />
      {' '}
      Log out
    </ModalButtonStyled>
    <Spacing type="small" />
    <ModalButtonCancelStyled onClick={() => setModalOpen(false)}>
      Cancel
    </ModalButtonCancelStyled>
  </Modal>
);

export default withRouter(Logout);

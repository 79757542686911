import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

// Composition
import MenuContent from '../Menu';
import MenuTop from '../MenuTop';
import MenuSelection from '../MenuSelection';

import { DashboardContext, UserContext } from '../../context';

import BASE_CSS_CONFIG from '../../Utilities/cssConfig';

import PortlInsightsLogo from '../../Components/PortlInsightsLogo';

// App Container Visual:
/*
+------------------------------------------------------+
|                     App Container                    |
|  +-----------+  +---------------------------------+  |
|  |   Left    |  |            Right                |  |
|  |           |  |                                 |  |
|  | +-------+ |  | +-----------------------------+ |  |
|  | |       | |  | |                             | |  |
|  | | Logo  | |  | |          Menu Top           | |  |
|  | +-------+ |  | +-----------------------------+ |  |
|  | +-------+ |  | +-----------------------------+ |  |
|  | |       | |  | |                             | |  |
|  | |  Menu | |  | |                             | |  |
|  | |content| |  | |      Main Content           | |  |
|  | |       | |  | |                             | |  |
|  | |       | |  | |                             | |  |
|  | |       | |  | |                             | |  |
|  | |       | |  | |                             | |  |
|  | +-------+ |  | +-----------------------------+ |  |
|  +-----------+  +---------------------------------+  |
+------------------------------------------------------+
 */

const AppContainerStyled = styled.div`
    height: inherit;
    width: inherit;

    overflow: hidden;
    margin: 0;
    display: flex;
`;

const LeftSectionStyled = styled.div`
    max-width: ${BASE_CSS_CONFIG.MAX_MENU_WIDTH};
    width: ${BASE_CSS_CONFIG.MENU_WIDTH};
    height: 100%;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;
`;

const MenuContentContainer = styled.div`
    border-right:  1px solid #D7D7D7;
    text-align: left;
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 50px;
`;

const LogoContainer = styled.div`
    height: ${BASE_CSS_CONFIG.HEADER_HEIGHT};
    padding: 15px;
    padding-left: ${BASE_CSS_CONFIG.PADDING_LEFT}px;
    border-bottom: 1px solid #D7D7D7;

    flex-shrink: 0;
`;

const RightSectionStyled = styled.div`
    //min-width: 1089px;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: ${(props) => props.bgColor || '#fcfcfc'};
    overflow-x: auto;
`;

const MenuTopContainer = styled.div`
    border-bottom: solid 1px #d6d6d6;
    z-index: 1; //Tooltips and popups need to be lower than 1
    height: ${BASE_CSS_CONFIG.HEADER_HEIGHT};
    background: white;
    padding: 0 25px;
`;

const MainContentContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    padding: 25px;
`;

const MaxWidth = styled.div`
    max-width: 1025px;
    min-width: 871px;
    height: 73px;
    margin: auto;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    ${(props) => props.showDropdown
        || `justify-content: flex-end;
         width: 90%;`}
`;

const LeftSection = ({ children }) => (
  <LeftSectionStyled>
    <LogoContainer><PortlInsightsLogo /></LogoContainer>
    <MenuContentContainer>
      {children}
    </MenuContentContainer>
  </LeftSectionStyled>
);

const RightSection = ({ children }) => {
  const { user } = React.useContext(UserContext);
  const { pathname } = useLocation();
  const showDropdown = pathname.includes('dashboard') || pathname.includes('images');
  const color = showDropdown && '#f6f6ff';
  const hideCreativeField = pathname.includes('images');

  return (
    <RightSectionStyled bgColor={color}>
      <MenuTopContainer>
        <MaxWidth showDropdown={showDropdown}>
          <div>
            {showDropdown && <MenuSelection hideCreative={hideCreativeField} />}
          </div>
          <MenuTop photo={user.photo} />
        </MaxWidth>
      </MenuTopContainer>
      {children}
    </RightSectionStyled>
  );
};

const AppContainer = ({ children }) => {
  const [listBrands, setListBrands] = React.useState([]);
  const [campaign, setCampaign] = React.useState({});
  const [brand, setBrand] = React.useState({});
  const [creative, setCreative] = React.useState({});

  const dashboardContext = {
    listBrands,
    setListBrands,
    campaign,
    setCampaign,
    brand,
    setBrand,
    creative,
    setCreative,
  };

  return (
    <DashboardContext.Provider value={dashboardContext}>
      <AppContainerStyled>
        <LeftSection>
          <MenuContent />
        </LeftSection>
        <RightSection>
          <MainContentContainer>
            {children}
          </MainContentContainer>
        </RightSection>
      </AppContainerStyled>
    </DashboardContext.Provider>
  );
};

export default AppContainer;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../../Components/Button';
import Dropdown from '../../Components/Dropdown';
import {
  uploadHivestackTablets,
  uploadVistarTablets,
} from '../../Utilities/api';
import Loading from '../../Components/Loading';

const Svg = styled.svg`
  width: 83.5px;
  height: 83.5px;
  margin: 30px auto 0;
  fill: #85c7ff;
`;

const DragText = styled.p`
  font-size: 18px;
  padding-top: 20px;
  min-width: 400px;
`;

const OrText = styled.p`
  padding: 20px 0;
  text-transform: uppercase;
  color: #bdbdbd;
  font-size: 18px;
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  width: 216px;
  margin: 10px auto;
`;

const FileInput = styled.input`
  display: none;
`;
const UploadSvg = styled.svg`
  margin-right: 10px;
`;
const Header = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #222222;
`;
const OutcomeText = styled.p`
  color: #222222;
  font-weight: 500;
  padding: 10px 0;
`;

const validationSchema = Yup.object().shape({
  platform: Yup.object().required('Platform is required.'),
});

const UploadImageSvg = () => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    viewBox="0 0 24 24"
    width={24}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4.86 8.86l-3 3.87L9 13.14 6 17h12l-3.86-5.14z" />
  </Svg>
);

const UploadFileSvg = ({ color }) => (
  <UploadSvg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
  >
    <g id="Group_874" data-name="Group 874" transform="translate(-757 -678)">
      <g id="insert_drive_file-24px" transform="translate(757 678)">
        <path
          id="Path_494"
          data-name="Path 494"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_495"
          data-name="Path 495"
          d="M14,2H6A2,2,0,0,0,4.01,4L4,20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8ZM6,20V4h7V9h5V20Z"
          fill={color || '#fff'}
        />
      </g>
      <path
        id="Path_497"
        data-name="Path 497"
        d="M10.433,5.191l1.482,1.476.807-.807L9.861,3,7,5.861l.807.807L9.288,5.191v5.532h1.144Z"
        transform="translate(759.139 685.889)"
        fill={color || '#fff'}
      />
    </g>
  </UploadSvg>
);
const Spacing = styled.div`
  height: 15px;
`;

const UploadTablets = () => {
  const [uploadedFile, setUploadedFile] = useState({
    url: undefined,
    blob: undefined,
  });
  const [outcome, setOutcome] = useState({});

  const handleFile = (file) => {
    try {
      const blob = new Blob([file], { type: file.type });

      const url = URL.createObjectURL(blob);

      setUploadedFile({
        url,
        blob,
      });
    } catch (error) {
      console.log('Error parsing file: ', error);
    }
  };

  const locationOptions = [
    { value: 'Hivestack', label: 'Hivestack' },
    { value: 'Vistar', label: 'Vistar' },
  ];

  const handleUpload = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      handleFile(file);
    }
  };

  const onDrop = React.useCallback((acceptedFiles) => {
    handleFile(acceptedFiles[0]);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async ({ platform }, { setSubmitting }) => {
    if (!uploadedFile.blob) return;
    setSubmitting(true);
    const file = uploadedFile.blob;

    const uploadFn = {
      Hivestack: uploadHivestackTablets,
      Vistar: uploadVistarTablets,
    };
    const uploadRes = await uploadFn[platform.value](file);
    setSubmitting(false);
    if (uploadRes.error) {
      console.log(uploadRes.error);
    }
    console.log(uploadRes, uploadRes.added);

    setUploadedFile({
      blob: undefined,
      url: undefined,
    });
    setOutcome(uploadRes);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const UploadTabletForm = () => (!uploadedFile.blob ? (
    <>
      <div {...getRootProps()}>
        <UploadImageSvg />
        <DragText>
          {isDragActive ? 'Drop here' : 'Drag and drop your .csv file here'}
        </DragText>
        <input {...getInputProps()} />
      </div>
      <OrText>Or</OrText>
      <ButtonWrapper>
        <FileInput type="file" id="upload" onChange={handleUpload} />
        <Button
          as="label"
          htmlFor="upload"
          color="#169aff"
          borderColor="#169aff"
          fontSize="21px"
        >
          <UploadFileSvg />
          Upload file
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <Button
          as="a"
          href="/assets/tablet-upload-template.csv"
          download="upload_template.csv"
          color="#169aff"
          borderColor="#169aff"
        >
          Download template
        </Button>
      </ButtonWrapper>
    </>
  ) : (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ platform: '' }}
      validationSchema={validationSchema}
    >
      {(props) => (props.isSubmitting ? (
        <Loading text={`Uploading to ${props.values.platform.label}...`} />
      ) : (
        <form onSubmit={props.handleSubmit}>
          <ButtonWrapper>
            <Dropdown
              items={locationOptions}
              thick
              placeholder="Select platform"
              id="platform"
              commonProps={props}
            />
            <Spacing />
            <DragText>Note: Each screen takes about 4s to upload.</DragText>
            <Button color="#169aff" borderColor="#169aff" fontSize="21px">
              <UploadFileSvg />
              Upload
            </Button>
          </ButtonWrapper>
        </form>
      ))}
    </Formik>
  ));

  return (
    <>
      <Header>Upload Tablets</Header>
      <Spacing />
      {Object.keys(outcome).length > 0 ? (
        <>
          {outcome.error && (
            <div>
              <p>
                Error:
                {JSON.stringify(outcome.error)}
              </p>
              <h2>Does it look like this?</h2>
              <img src="/assets/hivestack-upload-template.png" alt="" />
            </div>
          )}
          {outcome.added && outcome.added.length > 0 && (
            <>
              <h2>Added</h2>
              {outcome.added.map(({ imei, name }) => (
                <OutcomeText key={`added-${imei}`}>
                  {name}
                  {' '}
                  (
                  {imei}
                  )
                </OutcomeText>
              ))}
            </>
          )}
          {outcome.failed && outcome.failed.length > 0 && (
            <>
              <h2>Failed</h2>
              {outcome.failed.map(({ imei, error }) => (
                <OutcomeText key={`failed-${imei}`}>
                  {imei}
                  {' '}
                  :
                  {JSON.stringify(error)}
                </OutcomeText>
              ))}
            </>
          )}
          {outcome.invalid && outcome.invalid.length > 0 && (
            <>
              <h2>Invalid</h2>
              {outcome.invalid.map(({
                imei, lat, lng, line,
              }) => (
                <OutcomeText key={`invalid-${imei}`}>
                  <strong>
                    Line
                    {line}
                    :
                  </strong>
                  {' '}
                  {imei}
                  {' '}
                  | Lat:
                  {lat}
                  {' '}
                  | Lng:
                  {lng}
                </OutcomeText>
              ))}
            </>
          )}
          <ButtonWrapper>
            <Button onClick={() => setOutcome({})}>Go Back</Button>
          </ButtonWrapper>
        </>
      ) : (
        <UploadTabletForm />
      )}
    </>
  );
};

export default UploadTablets;

import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from './arrow.svg';

const ArrowIconStyled = styled(ArrowIcon)`
    height: 20px;
    width: 15px;
    //stroke-width: 3;
    fill: #757575;
    transform: ${({ selected }) => (selected && 'rotate(90deg)')};
    transition: all 0.25s;
`;

export default ArrowIconStyled;

import React from 'react';
import styled from 'styled-components';
import Button from '../Button';

const UnhappyFace = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="sentiment_dissatisfied-24px" width={65} height={65} viewBox="0 0 65 65">
    <path id="Path_502" fill="none" d="M0 0h65v65H0z" data-name="Path 502" />
    <circle id="Ellipse_144" cx="3.936" cy="3.936" r="3.936" className="cls-2" data-name="Ellipse 144" transform="translate(38.485 21.275)" fill="#169aff" />
    <ellipse id="Ellipse_145" cx="4.33" cy="3.936" className="cls-2" data-name="Ellipse 145" rx="4.33" ry="3.936" transform="translate(18.787 21.275)" fill="#169aff" />
    <path id="Path_503" d="M29.06 2a27.087 27.087 0 1 0 27.115 27.087A27.074 27.074 0 0 0 29.06 2zm.027 48.757a21.67 21.67 0 1 1 21.67-21.67 21.664 21.664 0 0 1-21.67 21.67zm0-9.481a10.413 10.413 0 0 1 5.336 1.436c.325-.379 2.33-2.655 2.736-3.088a14.6 14.6 0 0 0-8.072-2.411 14.961 14.961 0 0 0-8.1 2.384c2.627 2.953.027.054 2.736 3.088a10.843 10.843 0 0 1 5.364-1.408z" className="cls-2" data-name="Path 503" transform="translate(3.413 3.413)" />
  </svg>
);

const RefreshIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" id="baseline-refresh-24px" width={24} height={24} viewBox="0 0 24 24" style={{ marginRight: '17px' }}>
    <path id="Path_29" fill="#fff" d="M18.735 6.537a8.637 8.637 0 1 0 2.245 8.257h-2.245a6.474 6.474 0 1 1-6.1-8.635 6.386 6.386 0 0 1 4.556 1.921l-3.476 3.476h7.557V4z" data-name="Path 29" transform="translate(-.637 -.635)" />
    <path id="Path_30" fill="none" d="M0 0h24v24H0z" data-name="Path 30" />
  </svg>
);

const Text = styled.h1`
    font-size: 36px;
    font-weight: 600;
    color: #169aff;
`;
const TextContainer = styled.div`
    padding-left: 12px;
    width: 404px;
    display: inline-block;
    text-align: left;
`;
const ButtonWrapper = styled.div`
    width: 150px;
    padding-top: 30px;
`;
const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 20vh;
`;

const DataError = () => (
  <FlexContainer>
    <UnhappyFace />
    <TextContainer>
      <Text>Sorry... we just need a moment to retrieve your data.</Text>
      <ButtonWrapper>
        <Button onClick={() => window.location.reload()}>
          <RefreshIcon />
          Refresh
        </Button>
      </ButtonWrapper>
    </TextContainer>
  </FlexContainer>
);

export default DataError;

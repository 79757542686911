import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { calcFontSize } from '../../Utilities/fontsize';
import { ICON_CSS_CONFIG } from '../../Utilities/cssConfig';

const MenuSubsectionContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 1.1vw 0;
    font-size: 21px;
    text-align: left;
    color: ${ICON_CSS_CONFIG.normal};
    font-weight: inherit;
    :hover {
        background:  ${(props) => !props.selected && '#F6F6FF;'}
        border-radius: 10px;
        cursor: pointer;
    }
`;

const IconPadder = styled.span`
    padding: 0px 25px;
    width: 58px;
`;

const MenuItemName = styled.div`
    align-text: left;
    flex: 1 0 70%;
    font-size: ${calcFontSize(15, 18)}; 
    font-weight: inherit;
    text-transform: capitalize !important;
`;

const MenuOption = ({
  icon: Icon, name, selected, path, onClick, hasChild, child: Child,
}) => (
  <MenuSubsectionContainer onClick={onClick} selected={selected} as={(path) && Link} to={path}>
    <IconPadder>
      <Icon selected={selected} />
    </IconPadder>
    <MenuItemName>{name}</MenuItemName>
    {hasChild && <Child />}
  </MenuSubsectionContainer>
);

export default MenuOption;

import styled from 'styled-components';
import { ReactComponent as ClientListIcon } from './clientlist.svg';
import { ICON_CSS_CONFIG } from '../../../Utilities/cssConfig';

const ClientListIconStyled = styled(ClientListIcon)`
    height: 24px;
    //stroke-width: 3;
    fill: ${({ selected }) => (selected ? ICON_CSS_CONFIG.selected : ICON_CSS_CONFIG.normal)};
`;

export default ClientListIconStyled;

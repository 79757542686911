import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import styled from 'styled-components';

// import { UserContext } from '../../context';

// Components
import Button from '../../Components/Button';
import InputSection from '../../Components/InputSection';
import Spacing from '../../Components/Spacing';
import StyledLink from '../../Components/StyledLink';

// Compositions
import AuthHeader from '../../Compositions/AuthHeader';
import Modal from '../../Compositions/ModalTemplate';

// Utils
import { BLOCK_USERS_FROM_STAGING } from '../../config';
import { constructAdmin } from '../../Utilities/api';
// import { renderRedirect } from '../../Utilities/helper';
import { email, name } from '../../Utilities/validation';
import BackgroundGradient from '../../Components/BackgroundGradient';

const ValidationSchema = Yup.object().shape({
  email,
  name,
});

export const signupFieldErrors = {
  400: 'Create a driver account first.',
  422: 'Email format is incorrect. Please double check the spelling and try again.',
  409: 'This email is taken. Please try another.',
  403: 'You are not authorized to perform this action.',
  429: 'You have made too many requests, please wait an hour and try again.',
};

const ModalContent = styled.div`
    padding: 30px 30px 0 30px;
`;

const Note = styled.div`
    padding: 20px 0px 35px 0px;
    color: #757575;

    a {
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: #1FB6FF;
    }
`;
const StyledForm = styled.form`
    max-width:60%;
    margin: 0 auto;
    
`;

const Input = ({ placeholder, id, commonProps }) => (
  <>
    <InputSection placeholder={placeholder} id={id} commonProps={commonProps} />
    <Spacing height="15px" />
  </>
);

const generateForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
}) => {
  const commonProps = {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
  };

  return (
    <StyledForm onSubmit={handleSubmit} autoComplete="off">
      <Input placeholder="Portl Email Address" id="email" commonProps={commonProps} />
      <Input placeholder="Full Name" id="name" commonProps={commonProps} />
      <Button type="submit" disabled={isSubmitting}>Sign Up</Button>
      <Note>
        Already an Admin?&nbsp;
        <StyledLink to="/admin-login">
          Log In.
        </StyledLink>
      </Note>
    </StyledForm>
  );
};

const SignUp = () => {
  const admin = {
    email: '', name: '', phone: '', password: '',
  };
  const [modalOpen, setmodalOpen] = React.useState(false);

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    const newAdmin = {
      name: values.name.trim(),
      email: values.email.toLowerCase().trim(),
    };

    if (!values.email.includes('portlmedia.com')) {
      // block non-portlmedia users to sign up to prod
      return setFieldError('email', BLOCK_USERS_FROM_STAGING);
    }

    const response = await constructAdmin(newAdmin);
    setSubmitting(false);

    if (response.error) {
      return setFieldError('email', signupFieldErrors[response.error.response.status]);
    }

    return setmodalOpen(true);
  };

  return (
    <>
      <BackgroundGradient>
        <AuthHeader />
        <Formik
          initialValues={admin}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {generateForm}
        </Formik>
      </BackgroundGradient>
      <Modal modalOpen={modalOpen} setModalOpen={setmodalOpen}>
        <ModalContent>
          Please contact the admin for approval settings.
        </ModalContent>
      </Modal>
    </>
  );
};

export default SignUp;

import React from 'react';

// Could have light/dark theme context here
// Also language context which changes based on user preference/ location

export const UserContext = React.createContext();

export const DashboardContext = React.createContext();

// For list of Clients, Marketing Agencies, Brands, etc.
export const ClientContext = React.createContext();

import React from 'react';
import styled from 'styled-components';
import Button from '../../Components/Button';

import { reloadTablets } from '../../Utilities/api';
import Loading from '../../Components/Loading';

const CardContainer = styled.div`
  flex: 1 1 25%;
  margin: 15px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

const ButtonWrapper = styled.div`
  padding: 15px 15px 0 15px;

  & > button {
    border-radius: 0px;
  }
`;
const ActionTitle = styled.p`
  padding: 15px;
  color: #222222;
  font-weight: 600;
  border-bottom: 1px solid #d7d7d7;
  width: 90%;
  margin: 0 auto;
`;

const StatusStyled = styled.div`
  padding-top: 5px;
  min-height: 50%;
  margin: 15px 15px 0 15px;
  border: 1px solid #d7d7d7;
  width: 90%;
`;

const ResultsStyled = styled.div`
  padding-top: 15px;
  font-weight: 400;
`;

const Status = ({ status, children }) => (
  <StatusStyled>
    {children}
    {status}
  </StatusStyled>
);

const ActionButton = ({ children, disabled, target }) => (
  <ButtonWrapper>
    <Button onClick={target} disabled={disabled}>
      {children}
    </Button>
  </ButtonWrapper>
);

const ActionCard = ({ clientID }) => {
  const [reloading, setReloading] = React.useState(false);
  const [results, setResults] = React.useState(false);

  // TODO: add reload time fail case & success case
  // reload to array not just a string

  React.useEffect(() => {
    let isMounted = true;

    const handleTabletReload = async () => {
      const data = await reloadTablets(clientID);

      setReloading(false);
      if (data.error && isMounted) {
        setResults(data.error);
      } else if (data.undefinedSockets.length > 0) {
        setResults('Socket disconnected');
      } else {
        setResults(`Reload Success at ${new Date().toLocaleTimeString()}`);
      }
    };

    if (reloading) handleTabletReload();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloading]);

  return (
    <CardContainer>
      <ActionTitle>Actions</ActionTitle>
      <ActionButton target={() => setReloading(true)}>
        {' '}
        Reload Tablet
        {' '}
      </ActionButton>
      <ActionButton disabled>Log in</ActionButton>
      <Status>
        <ActionTitle>Action Status</ActionTitle>
        {reloading && <Loading paddingTop={25} />}
        {results && <ResultsStyled>{results}</ResultsStyled>}
      </Status>
    </CardContainer>
  );
};

export default ActionCard;

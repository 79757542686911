/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { UserContext, ClientContext } from '../context';
import { isAdmin } from '../Utilities/helper';
import AppContainer from '../Compositions/AppContainer';
import { getInsightCompanies } from '../Utilities/api';

const PrivateRoute = ({ component: Component, render, ...rest }) => {
  const [clients, setClients] = useState();
  const { user } = React.useContext(UserContext);
  const { pathname } = useLocation();

  // Fetch client ids from api
  useEffect(() => {
    let isMounted = true;
    const getClientList = async () => {
      const clientList = await getInsightCompanies();

      if (clientList.error) {
        // TO DO: Handle Error
        return console.error('Error fetching client list: ', clientList.error);
      }
      if (isMounted) setClients(clientList);
      return null;
    };
    getClientList();
    return () => {
      isMounted = false;
    };
  }, []);

  if (!user || (user && user.error)) {
    return <Redirect push to="/login" />;
  }
  const adminRoutes = [
    'clients',
    'devices',
    'compliance',
    'brands',
    'campaigns',
    'creatives',
    'device',
    'drivers',
    'tablets',
    'diagnostics',
  ];
  // admin middleware
  if (!isAdmin(user) && adminRoutes.some((route) => pathname.includes(route))) {
    return <Redirect push to="/dashboard" />;
  }

  return (
    <ClientContext.Provider value={{ clients, setClients }}>
      <AppContainer>
        {Component ? (
          <Route {...rest} render={(props) => <Component {...props} />} />
        ) : (
          <Route {...rest} render={render} />
        )}
      </AppContainer>
    </ClientContext.Provider>
  );
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: (props, _propName, componentName) => {
    if (props.component || props.render) return null;
    return new Error(
      `One of props 'component' or 'render' was not specified in '${componentName}'.`,
    );
  },
  render: (props, _propName, componentName) => {
    if (props.component || props.render) return null;
    return new Error(
      `One of props 'component' or 'render' was not specified in '${componentName}'.`,
    );
  },
};

/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import Toggle from 'react-toggle';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import Modal from '../../Components/Modal';
import InternalInput from '../../Components/InternalInput';
import { phone, name as nameReg, email } from '../../Utilities/validation';
import {
  createZendeskTicket,
  updateDriver,
  generateShippingLabel,
  deleteDriver,
  generateReturnLabel,
  handleDriverLogout,
  handleReplaceDriver,
  addDistanceDriver,
  generateExchangeLabel,
} from '../../Utilities/api';
import Button from '../../Components/Button';

import { initConnection } from '../../Utilities/socket';
import Dropdown from '../../Components/Dropdown';

const ModalText = styled.p`
  padding: 10px 0;
`;
const Bold = styled.span`
  font-weight: 500;
`;
const EditButtonWrapper = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  width: 200px;
`;
const TableContainer = styled.div`
  max-height: 600px;
  overflow-y: scroll;
`;

export const GenerateShippingModal = ({
  driver, setDrivers, onClose, isOpen,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    imeiShipped: 35851007,
  };

  const validationSchema = Yup.object().shape({
    imeiShipped: Yup.number().test(
      'len',
      'Must be exactly 15 characters',
      (val) => val.toString().length === 15,
    ),
  });

  const handleSubmit = async ({ imeiShipped }, { setSubmitting }) => {
    setSubmitting(true);
    const { shipments } = driver;
    if (shipments && shipments[0] && shipments[0].imei) {
      // CHANGE THIS
      createZendeskTicket(
        'driversupport@portlmedia.com',
        'Shipment IMEI is already defined',
        `The following driver has already an IMEI assigned to the most currently
        shipment information. Driver: ${driver.email}. Shipment: 
        IMEI-${shipments && shipments[0].imei}, Tracking-${shipments && shipments[0].trackingPin},
        ShipId-${shipments && shipments[0].shipmentId}, 
        IMEI that will override ${shipments && shipments[0].imei}`,
      );
    }

    const submitValues = {
      name: `${driver.driverName.first} ${driver.driverName.last}`,
      addressOne: driver.addressOne,
      addressTwo: driver.addressTwo,
      city: driver.city,
      province: driver.province,
      postalCode: driver.postalCode,
      country: driver.country,
      email: driver.email,
      IMEI: imeiShipped.toString(),
      phone: driver.phone,
    };

    const res = await generateShippingLabel(submitValues);

    setSubmitting(false);

    if (res.error) {
      console.log(res); // log error and close modal
      enqueueSnackbar('Shipping Label not generated', {
        variant: 'error',
      }); // Alert for error
      return onClose();
    }
    // assume successful and set local driver state to show label has been created
    return setDrivers((prevState) => (
      prevState.filter((someDriver) => someDriver.email !== driver.email)
    ));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Generate Label" center>
      {driver.driverName && (
        <ModalText>
          Are you sure you want to generate a shipping label for
          <Bold>
            {' '}
            {driver.driverName.first}
            {' '}
            {driver.driverName.last}
          </Bold>
          ?
        </ModalText>
      )}
      <ModalText>
        <Bold>This will charge your card.</Bold>
      </ModalText>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit} style={{ width: '300px', margin: '0 auto' }}>
            <InternalInput
              id="imeiShipped"
              commonProps={formProps}
              label="IMEI #"
              type="number"
            />
            <EditButtonWrapper>
              <Button type="submit" isLoading={formProps.isSubmitting}>
                Yes, Confirm
              </Button>
            </EditButtonWrapper>
            <EditButtonWrapper>
              <Button type="button" action="return" onClick={onClose}>
                Return
              </Button>
            </EditButtonWrapper>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export const GenerateReturnLabelModal = ({
  driver,
  setDrivers,
  onClose,
  isOpen,
}) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleGenerateLabel = async () => {
    setLoading(true);
    const fields = {
      name: `${driver.driverName.first} ${driver.driverName.last}`,
      addressOne: driver.addressOne,
      addressTwo: driver.addressTwo,
      city: driver.city,
      province: driver.province,
      postalCode: driver.postalCode,
      email: driver.email,
    };
    const res = await generateReturnLabel(fields);

    setLoading(false);
    if (res.error) {
      console.log('Error generating return label: ', res.error); // log error and close modal
      enqueueSnackbar('Return Label not generated', {
        variant: 'error',
      }); // Alert for error
      return onClose();
    }
    // assume successful and set local driver state to show label has been created
    setDrivers((prevState) => prevState.map((someDriver) => {
      if (someDriver.email === driver.email) return { ...driver, returnTrackingPin: res };
      return someDriver;
    }));

    // Assume label generated successfully, confirm if email sent successfully with alerts
    // status 206 means that the label was created but got an error sending the email to the driver
    if (res.status === 206) {
      return enqueueSnackbar(`Error sending shipping email to ${driver.email}`, {
        variant: 'error',
      });
    }
    return enqueueSnackbar(
      `Shipping label successfully emailed to ${driver.email}`,
      {
        variant: 'success',
      },
    ); // Alert for success
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Generate Return Label" center>
      {driver.driverName && (
        <ModalText>
          Are you sure you want to generate a return label for
          <Bold>
            {' '}
            {driver.driverName.first}
            {' '}
            {driver.driverName.last}
          </Bold>
          ?
        </ModalText>
      )}
      <ModalText>
        <Bold>This will charge your card when they ship the tablet.</Bold>
      </ModalText>
      <EditButtonWrapper>
        <Button onClick={handleGenerateLabel} isLoading={loading}>
          Yes, confirm
        </Button>
      </EditButtonWrapper>
      <EditButtonWrapper>
        <Button onClick={onClose} action="return">
          Cancel
        </Button>
      </EditButtonWrapper>
    </Modal>
  );
};

export const DeleteModal = ({
  driver, setDrivers, onClose, isOpen,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    const res = await deleteDriver(driver.email);
    setLoading(false);

    if (res.error) {
      console.error(res); // log error and close modal
      return onClose();
    }

    // assume successful and set local driver state to show label has been created
    return setDrivers((prevState) => [
      ...prevState.filter((someDriver) => someDriver.email !== driver.email),
    ]);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Are you sure?" center>
      <ModalText>
        Are you sure you want to delete
        <Bold>{driver.email}</Bold>
        ?
      </ModalText>
      <ModalText>
        <Bold>This action cannot be undone.</Bold>
      </ModalText>
      <EditButtonWrapper>
        <Button
          onClick={handleDelete}
          isLoading={loading}
          color="rgb(202, 3, 7)"
          borderColor="rgb(202, 3, 7)"
        >
          Yes, delete.
        </Button>
      </EditButtonWrapper>
      <EditButtonWrapper>
        <Button onClick={onClose} action="return">
          Cancel
        </Button>
      </EditButtonWrapper>
    </Modal>
  );
};

export const EditModal = ({
  isOpen, driver, onClose, setDrivers,
}) => {
  const validationSchema = Yup.object().shape({
    phone,
    // 'email': email, todo in future
    first: nameReg,
    last: nameReg,
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const parsedValues = {
      ...values,
      driverName: {
        first: values.first,
        last: values.last,
      },
    };
    delete parsedValues.first; // delete otherwise will fail validation
    delete parsedValues.last;
    const updateRes = await updateDriver(driver.email, parsedValues);
    setSubmitting(false);
    if (updateRes.error) {
      return console.log('Error updating driver: ', updateRes.error);
    }

    const newDriver = {
      ...driver,
      ...values,
      driverName: {
        first: values.first,
        last: values.last,
      },
    };
    return setDrivers((prevState) => prevState.map((someDriver) => (someDriver.email === driver.email ? newDriver : someDriver)));
    // onClose();
  };

  const initialValues = {
    phone: driver.phone,
    first: driver.driverName ? driver.driverName.first : '',
    last: driver.driverName ? driver.driverName.last : '',
    addressOne: driver.addressOne,
    addressTwo: driver.addressTwo,
    city: driver.city,
    province: driver.province,
    postalCode: driver.postalCode,
  };

  return (
    <Modal isOpen={isOpen} title="Edit Driver" onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit} style={{ width: '300px' }}>
            {/* Disable until email can be handled properly (accross databases) <InternalInput
                        id='email'
                        commonProps={formProps}
                        type='email'
                        label='Email'
                    /> */}
            <InternalInput id="first" commonProps={formProps} label="First name" />
            <InternalInput id="last" commonProps={formProps} label="Last name" />
            <InternalInput
              id="phone"
              commonProps={formProps}
              label="Phone"
              type="phone"
            />
            <InternalInput id="addressOne" commonProps={formProps} label="Address" />
            <InternalInput
              id="addressTwo"
              commonProps={formProps}
              label="Address 2"
            />
            <InternalInput id="city" commonProps={formProps} label="City" />
            <InternalInput id="province" commonProps={formProps} label="Province" />
            <InternalInput
              id="postalCode"
              commonProps={formProps}
              label="Postal Code"
            />
            <EditButtonWrapper>
              <Button type="submit" isLoading={formProps.isSubmitting}>
                Update
              </Button>
            </EditButtonWrapper>
            <EditButtonWrapper>
              <Button type="button" onClick={onClose} action="return">
                Return
              </Button>
            </EditButtonWrapper>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export const OverrideUrlModal = ({ isOpen, imei, onClose }) => {
  const handleSubmit = async ({ url }) => {
    const socket = initConnection();
    console.log(`Changing url on tablet(${imei}) to: ${url}`);
    socket.emit('changeURL', imei, url);
    onClose();
  };

  const validationSchema = Yup.object().shape({
    url: Yup.string().url('A valid url must be provided.'),
  });

  return (
    <Modal isOpen={isOpen} title="Override URL" onClose={onClose}>
      <Formik
        initialValues={{ url: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit} style={{ width: '300px' }}>
            <InternalInput id="url" commonProps={formProps} />
            <ModalText>
              <Bold>
                This will override the tablet app on the device. Use for testing
                purposes only.
              </Bold>
            </ModalText>
            <EditButtonWrapper>
              <Button type="submit">Override</Button>
            </EditButtonWrapper>
            <EditButtonWrapper>
              <Button type="button" onClick={onClose}>
                Close
              </Button>
            </EditButtonWrapper>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export const ReplaceDriverModal = ({ isOpen, imei, onClose }) => {
  const handleSubmit = async ({ newEmail }) => {
    const result = await handleReplaceDriver(imei, newEmail);
    console.log(result);
    onClose();
  };

  const handleLogout = async () => {
    const res = await handleDriverLogout(imei);
    console.log(res);
    onClose();
  };

  const validationSchema = Yup.object().shape({
    email,
  });

  return (
    <Modal isOpen={isOpen} title="Replace logged in driver" onClose={onClose}>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit} style={{ width: '300px' }}>
            <ModalText>
              <Bold>
                Replace a logged in driver on the tablet with another one. Or simply
                log them out.
              </Bold>
            </ModalText>
            <InternalInput id="email" commonProps={formProps} />
            <EditButtonWrapper>
              <Button type="submit">Replace</Button>
            </EditButtonWrapper>
            <EditButtonWrapper>
              <Button
                type="button"
                onClick={handleLogout}
                color="rgb(202, 3, 7)"
                borderColor="rgb(202, 3, 7)"
              >
                Logout
              </Button>
            </EditButtonWrapper>
            <EditButtonWrapper>
              <Button type="button" onClick={onClose} action="return">
                Return
              </Button>
            </EditButtonWrapper>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

const failReasons = [
  'Too few trips in previous week',
  'Has not been doing rideshare long enough',
  'Uploaded logs are not recent',
  'Screenshots were not valid driver logs from rideshare app',
  'Screenshots do not show trips per week in previous week',
  'Other',
];

export const FailDriverModal = ({
  isOpen, driver, onClose, setDrivers,
}) => {
  const handleSubmit = async ({ failReason, otherReason }, { setSubmitting }) => {
    let reason = failReason.value;
    if (failReason.value === 'Other') {
      reason = otherReason;
    }
    setSubmitting(true);

    const updateRes = await updateDriver(driver.email, {
      failReason: reason,
      leadStatus: 'Unqualified',
    });
    setSubmitting(false);
    if (updateRes.error) {
      return console.log('Error moving driver to unqualified: ', updateRes.error);
    }

    return setDrivers((prevState) => [
      ...prevState.filter((someDriver) => someDriver.email !== driver.email),
    ]);
  };

  const initialValues = {
    failReason: '',
    otherReason: '',
  };
  const validationSchema = Yup.object().shape({
    failReason: Yup.string().required('A reason is required.'),
  });

  return (
    <Modal isOpen={isOpen} title="Why is this driver unqualified?" onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit} style={{ width: '300px' }}>
            <Dropdown
              id="failReason"
              commonProps={formProps}
              items={failReasons}
              thick
            />
            {formProps.values.failReason.value === 'Other' && (
              <InternalInput
                id="otherReason"
                commonProps={formProps}
                type="string"
              />
            )}
            <EditButtonWrapper>
              <Button type="submit" isLoading={formProps.isSubmitting}>
                Confirm
              </Button>
            </EditButtonWrapper>
            <EditButtonWrapper>
              <Button type="button" action="return" onClick={onClose}>
                Return
              </Button>
            </EditButtonWrapper>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

const ApplyButton = styled(Button)`
  margin: 25px 0 0 auto;
`;

export const ColumnFilterModal = ({
  isOpen,
  onClose,
  columns,
  setColumns,
  resetColumns,
}) => {
  const [data, setData] = useState([]);

  React.useEffect(() => {
    setData([{ name: 'Show/Hide All', omit: true }, ...columns]);
  }, [isOpen, columns]);

  const handleColumnChange = ({ target }) => {
    setData((prev) => prev.map((item) => {
      if (target.id === 'Show/Hide All') return { ...item, omit: !target.checked };

      if (target.id === item.name) return { ...item, omit: !target.checked };

      return item;
    }));
  };

  const handleSubmit = () => {
    setColumns(data);
    onClose();
  };

  const columnsConfig = [
    {
      name: 'Show/Hide',
      selector: (row) => {
        const { omit, name } = row;
        return (
          <Toggle
            checked={!omit}
            aria-label="No label tag"
            id={name}
            onChange={handleColumnChange}
          />
        );
      },
      sortable: true,
      maxWidth: '230px',
    },
    {
      name: 'Column',
      selector: (row) => row.name,
      sortable: true,
      minWidth: '270px',
    },
  ];

  return (
    <Modal isOpen={isOpen} title="Columns" onClose={onClose}>
      <button onClick={resetColumns} type="button">
        reset
      </button>
      <TableContainer>
        <DataTable noHeader columns={columnsConfig} data={data} />
      </TableContainer>
      <ApplyButton onClick={handleSubmit} type="small">
        Apply
      </ApplyButton>
    </Modal>
  );
};

export const AddDistanceModal = ({
  isOpen,
  driver,
  onClose,
}) => {
  const validationSchema = Yup.object().shape({
    distance: Yup.string().required('A distance is required.'),
    date: Yup.string().required('The date is required. Format DD-MM-YYYY.'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const updateRes = await addDistanceDriver(values);
    setSubmitting(false);
    if (updateRes.error) {
      console.log('Error updating driver: ', updateRes.error);
    }

    onClose();
  };

  const initialValues = {
    email: driver.email,
    distance: '',
    date: '',
  };

  return (
    <Modal isOpen={isOpen} title={`Add Distance to ${driver.email}`} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit} style={{ width: '300px', margin: '0 auto' }}>
            <InternalInput id="distance" commonProps={formProps} label="Distance" />
            <InternalInput id="date" commonProps={formProps} label="Date" placeholder="DD-MM-YYYY" />
            <EditButtonWrapper>
              <Button type="submit" isLoading={formProps.isSubmitting}>
                Add
              </Button>
            </EditButtonWrapper>
            <EditButtonWrapper>
              <Button type="button" onClick={onClose} action="return">
                Return
              </Button>
            </EditButtonWrapper>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export const GenerateExchangeShippingModal = ({
  driver, onClose, isOpen, returnLabel,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    imeiShipped: 35851007,
  };

  const handleSubmit = async ({ imeiShipped }, { setSubmitting }) => {
    setSubmitting(true);
    const { shipments } = driver;
    if (shipments && shipments[0] && shipments[0].imei) {
      console.error('Error, could not find shipment array');
    }

    const submitValues = {
      name: `${driver.driverName.first} ${driver.driverName.last}`,
      addressOne: driver.addressOne,
      addressTwo: driver.addressTwo,
      city: driver.city,
      province: driver.province,
      postalCode: driver.postalCode,
      country: driver.country,
      email: driver.email,
      IMEI: imeiShipped.toString(),
      phone: driver.phone,
      returnLabel,
      shipments: driver.shipments,
    };

    const res = await generateExchangeLabel(submitValues);

    setSubmitting(false);

    if (res.error) {
      console.log(res); // log error and close modal
      enqueueSnackbar('Shipping Label not generated', {
        variant: 'error',
      }); // Alert for error
    }
    return onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Generate Label" center>
      {driver.driverName && (
        <ModalText>
          Are you sure you want to generate a exchange shipping label for
          <Bold>
            {' '}
            {driver.driverName.first}
            {' '}
            {driver.driverName.last}
          </Bold>
          {returnLabel ? ' with a return label' : ''}
          ?
        </ModalText>
      )}
      <ModalText>
        <Bold>This will charge your card.</Bold>
      </ModalText>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit} style={{ width: '300px', margin: '0 auto' }}>
            <InternalInput
              id="imeiShipped"
              commonProps={formProps}
              label="IMEI #"
              type="number"
            />
            <EditButtonWrapper>
              <Button type="submit" isLoading={formProps.isSubmitting}>
                Yes, Confirm
              </Button>
            </EditButtonWrapper>
            <EditButtonWrapper>
              <Button type="button" action="return" onClick={onClose}>
                Return
              </Button>
            </EditButtonWrapper>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

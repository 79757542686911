import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import styled from 'styled-components';
import Button from '../../Components/Button';
import Modal from '../../Components/Modal';
import Dropdown from '../../Components/Dropdown';
import DownloadIconStyled from '../../Components/Icon/Download';
import { getInsightBrands, downloadImpressionData, getImpressions } from '../../Utilities/api';
import { DashboardContext } from '../../context';

const DropdownWrapper = styled.div`
  margin-top: 10px;
  width: 380px;
`;
const Spacing = styled.div`
  height: 20px;
`;
const ButtonWrapper = styled.div`
  width: 180px;
  margin: 10px auto 0;
`;

const DownloadReportModal = ({ isOpen, onClose }) => {
  const { listBrands, setListBrands } = useContext(DashboardContext);
  const [loading, setLoading] = useState(false);

  const [listCampaigns, setListCampaigns] = useState([]);
  const [listCreatives, setListCreatives] = useState([]);
  const [campaign, setCampaign] = useState();
  const [brand, setBrand] = useState();
  const [doesHaveImpressions, setDoesHaveImpressions] = useState(null);
  const [creative, setCreative] = useState();
  const prevState = useRef({ brand, campaign });

  const handleBrandChange = (selected) => {
    setBrand(selected);
  };

  const handleCampaignChange = (selected) => {
    setCampaign(selected);
  };

  const handleCreativeChange = (seleted) => {
    setCreative(seleted);
  };

  useEffect(() => {
    let isMounted = true;

    const getInfo = async () => {
      if (listBrands && listBrands.length > 0) return;
      const response = await getInsightBrands();

      if (response.error) return; // TODO: send a zendesk ticket about it

      const brands = [];
      response.forEach((brandElem) => {
        if (brandElem.type === 'Brand' && brandElem.campaigns) {
          brands.push(brandElem);
        }
      });

      if (isMounted) {
        setListBrands(brands);
      }
    };

    getInfo();

    return () => { isMounted = false; };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const campaigns = [];
    listBrands.forEach((brandItem) => {
      if (brand && brand.value && brandItem.name !== brand.value.name) return;

      brandItem.campaigns.forEach((item) => {
        if (!item.creatives) return;
        campaigns.push(item);
      });
    });

    setListCampaigns(campaigns);

    // eslint-disable-next-line
  }, [brand]);

  useEffect(() => {
    const creatives = [];

    listCampaigns.forEach((campaignItem) => {
      if (campaign?.value && campaignItem.name !== campaign.value.name) return;

      campaignItem.creatives.forEach((item) => {
        creatives.push(item);
      });
    });

    setListCreatives(creatives);

    // eslint-disable-next-line
  }, [campaign]);

  useEffect(() => {
    const handleCampaign = () => {
      if (campaign?.label !== prevState.current.campaign?.label) {
        setCreative(undefined);
        setDoesHaveImpressions(false);
      }
      prevState.current.campaign = campaign;
    };
    return () => handleCampaign();
  }, [campaign]);

  useEffect(() => {
    const handleBrand = () => {
      if (brand?.label !== prevState.current.brand?.label) {
        setCampaign(undefined);
        setCreative(undefined);
        setDoesHaveImpressions(false);
      }
      prevState.current.brand = brand;
    };
    return () => handleBrand();
  }, [brand]);

  useEffect(() => {
    if (creative) {
      getImpressions(creative.value.creativeId).then((imps) => {
        if (imps.total) {
          setDoesHaveImpressions(true);
        } else {
          setDoesHaveImpressions(false);
        }
      });
    }
  }, [creative]);

  const handleDownload = async () => {
    if (!creative) return;
    const { value } = creative;
    if (!value || !value.creativeId) return;
    setLoading(true);
    const response = await downloadImpressionData(value.creativeId);
    if (response.error) {
      console.log('Error downloading file: ', response.error);
      return; // TODO: add error notification
    }
    setLoading(false);
  };

  return (
    <Modal
      title="Download report"
      isOpen={isOpen}
      onRequestClose={onClose}
      center
    >
      <Spacing />
      <DropdownWrapper>
        <Dropdown
          placeholder="Brand"
          thick
          items={listBrands}
          onSelect={handleBrandChange}
          value={brand}
        />
      </DropdownWrapper>
      <DropdownWrapper>
        <Dropdown
          placeholder="Campaign"
          thick
          items={listCampaigns}
          onSelect={handleCampaignChange}
          value={campaign}
        />
      </DropdownWrapper>
      <DropdownWrapper>
        <Dropdown
          placeholder="Creative"
          thick
          items={listCreatives}
          onSelect={handleCreativeChange}
          value={creative}
        />
      </DropdownWrapper>
      <Spacing />
      {!doesHaveImpressions && doesHaveImpressions !== null ? <p style={{ padding: '10px 20px' }}>No impressions to download</p> : null}
      <ButtonWrapper>
        <Button
          disabled={!doesHaveImpressions}
          color="#169aff"
          borderColor="#169aff"
          onClick={handleDownload}
          isLoading={loading}
        >
          <DownloadIconStyled />
          Download
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <Button
          action="return"
          onClick={onClose}
          color="#f1f1f1"
          fontColor="#222222"
          borderColor="#f1f1f1"
        >
          Cancel
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default DownloadReportModal;

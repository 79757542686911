/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import GoogleMapReact from 'google-map-react';

import Loading from '../../Components/Loading';
import Marker from './Marker';

import {
  fetchAllTrips,
} from '../../Utilities/api';

const Container = styled.div`
  text-align: left;
  padding: 25px;
`;
const Header = styled.div`
  padding: 0 20px 20px;
  font-size: 30px;
  font-weight: 600;
  color: #222222;
`;

const columns = [{
  name: 'Driver',
  selector: ({ driver }) => driver,
}, {
  name: 'IMEI',
  selector: ({ IMEI }) => IMEI,
}, {
  name: 'Date',
  selector: ({ startTimestamp }) => moment(startTimestamp).format('MMMM D'),
}, {
  name: 'Time',
  selector: ({ startTimestamp }) => moment(startTimestamp).format('LTS'),
}, {
  name: 'Duration (s)',
  selector: ({ duration }) => duration,
}, {
  name: 'FSA',
  selector: ({ startFSA, endFSA }) => {
    if (startFSA) {
      return startFSA.CFSAUID;
    }

    if (endFSA) {
      return endFSA.CFSAUID;
    }

    return '-';
  },
}];

const GOOGLE_MAPS_API = 'AIzaSyD8gKUAaItflRl3Z42uEZBfuE-dOW-4oR0';

const mapOptions = {
  bootstrapURLKeys: {
    key: GOOGLE_MAPS_API,
    libraries: ['visualization'],
    language: 'en',
  },
  defaultZoom: 13,
  style: { width: '100%', height: '370px', position: 'relative' },
  heatmapLibrary: true,
};

const ExpandedComponent = ({ data }) => (
  <GoogleMapReact {...mapOptions} defaultCenter={data.start}>
    <Marker
      lat={data.start.lat}
      lng={data.start.lng}
      text="Start Location"
      color="#26C281"
    />
    <Marker
      lat={data.end.lat}
      lng={data.end.lng}
      text="Drop off Location"
      color="#FF6E6E"
    />
  </GoogleMapReact>
);

const Activity = () => {
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [trips, setTrips] = useState([]);

  useEffect(() => {
    fetchAllTrips().then((response) => {
      setTrips(response.data);
      setTotalRows(response.count);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, []);

  const handlePageChange = (page) => {
    fetchAllTrips(page, perPage).then((response) => {
      setTrips(response.data);
    }).catch(() => {
      setTrips([]);
    });
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchAllTrips(page, newPerPage).then((response) => {
      setTrips(response.data);
    }).catch(() => {
      setTrips([]);
    });
    setPerPage(newPerPage);
  };

  return loading ? (<Loading />) : (
    <Container>
      <Header>
        Trips
      </Header>
      <DataTable
        noHeader
        columns={columns}
        data={trips}
        striped
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        subHeader
        expandableRows
        expandableRowsComponent={<ExpandedComponent />}
        subHeaderAlign="left"
      />
    </Container>
  );
};

export default Activity;

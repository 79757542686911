import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import Panel from '../../Components/Panel';
import Button from '../../Components/Button';

const ButtonWrapper = styled.div`
    padding: 25px 0;
`;

const modalStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '100',
  },
  content: {
    position: 'static',
    display: 'inline-block',
    padding: 'none',
    border: 'none',
    background: 'none',
  },
};
const ModalTemplate = ({ modalOpen, setModalOpen, children }) => {
  Modal.setAppElement('body');

  return (

    <Modal
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      style={modalStyles}
    >
      <Panel>
        {children}
        <ButtonWrapper>
          <Button action="return" type="small" onClick={() => setModalOpen(false)}>Return</Button>
        </ButtonWrapper>
      </Panel>
    </Modal>
  );
};

export default ModalTemplate;

/* eslint-disable react/jsx-props-no-spreading */
import 'react-dates/initialize';
import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const GOOGLE_MAPS_API = 'AIzaSyDaDNjqB9Tc1w_jUUVkhCV4XqjArgZkuqg';

const mapOptions = {
  bootstrapURLKeys: {
    key: GOOGLE_MAPS_API,
    libraries: ['visualization'],
    language: 'en',
  },
  defaultCenter: {
    lat: 43.6426,
    lng: -79.3871,
  },
  defaultZoom: 13,
  style: { width: '100%', height: '370px', position: 'relative' },
  heatmapLibrary: true,
};

const Wrapper = styled.div`
  width: 100%;
  height: 417px;
  border-radius: 10px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  overflow: hidden;
`;

const Menu = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #222222;
  height: 46.8px;
  padding: 0 15px;
  height: 46.8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateRangePickerWrapper = styled.div`
  .DateRangePickerInput__withBorder {
    border-radius: 10px;
    border: solid 1px #d6d6d6;
  }

  .DateRangePickerInput {
    padding: 0;
  }

  .DateInput {
    width: 115px;
    padding: 0 13px;
    border-radius: 10px;
  }

  .DateInput_input {
    font-size: 13px;
    font-weight: normal;
    color: #222222;
    padding: 0;
  }

  .DateRangePicker_picker {
    left: -230px !important;
  }

  .DateRangePickerInput_arrow {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
  }

  .CalendarMonth_caption {
    font-size: 15px;
  }

  .DayPickerNavigation_button__horizontalDefault {
    padding: 3px 3px;
  }
`;

const DashboardMap = ({ data, start, end }) => {
  const [range, setRange] = React.useState({ from: moment(), to: moment() });
  const [focusedInput, setFocusInput] = React.useState(null);
  const [heatMapData, setHeatMapData] = React.useState();

  const onFocusChange = (newValue) => {
    setFocusInput(newValue);
  };

  const populateHeatData = () => {
    const { from, to } = range;
    if (data) {
      const positions = [];
      data.forEach(({ time, locations }) => {
        if (moment(time).diff(from) > 0 && moment(time).diff(to) < 0) {
          positions.push(...locations);
        }
      });

      const heatmap = {
        positions,
        options: {
          radius: 20,
          opacity: 1,
        },
      };

      setHeatMapData(heatmap);
    }
  };

  React.useEffect(() => {
    const parseLogic = start.diff(moment()) > 0 ? start.add(1, 'd') : moment();
    const to = end.diff(moment()) < 0 ? end : parseLogic;
    // const from = to.clone().startOf('day').subtract(1, 'days');
    const from = start.startOf('day');

    setRange({ from, to });
    // eslint-disable-next-line
  }, [start, end]);

  React.useEffect(() => {
    populateHeatData();
    // eslint-disable-next-line
  }, [data, range]);

  return (
    <Wrapper>
      <Menu>
        Delivery Map
        <DateRangePickerWrapper>
          <DateRangePicker
            startDate={range.from || moment()} // momentPropTypes.momentObj or null,
            startDateId="yesterday" // PropTypes.string.isRequired,
            endDate={range.to || moment()} // momentPropTypes.momentObj or null,
            endDateId="now" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => setRange({
              from: startDate,
              to: endDate,
            })} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={onFocusChange}
            isOutsideRange={(day) => day.isAfter(moment())
              || !day.isBetween(moment(start), moment(end).add(1, 'd'))}
            displayFormat="MMM DD, YYYY"
            customArrowIcon="-"
            daySize={27}
          />
        </DateRangePickerWrapper>
      </Menu>
      {data && <GoogleMapReact {...mapOptions} heatmap={heatMapData} />}
    </Wrapper>
  );
};

export default React.memo(DashboardMap);

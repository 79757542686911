import styled from 'styled-components';

const AddClient = styled.div`
    border-radius: 50%;
    height: 41px;
    width: 41px;
    background: #169aff;
    font-size: 35px;
    color: white;
    line-height: 41px;
    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
`;

export default AddClient;

import React, { useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { debounce } from 'debounce';
import CsvDownload from 'react-json-to-csv';
import {
  fetchAllInstalledDrivers,
  getOnlineTablets,
  reloadTablets,
} from '../../Utilities/api';
import DownloadIconStyled from '../../Components/Icon/Download';

import {
  ColumnFilterModal,
} from './modals';
import {
  downloadStyles, UnmatchedTablets, Container, Header,
  SearchInput, ReloadAllButton, FilterButton, LogImg, Filters,
} from './style';
import {
  typesOfDrivers, DEFAULT_COLUMNS, typesOfSearchBy, allColumns,
} from './utils';

import Dropdown from '../../Components/Dropdown';
import Loading from '../../Components/Loading';
import Dots from './Dots';

const mapDriverToCSV = (driverArray = []) => driverArray.map((driverObject) => {
  const newDriver = {
    ...driverObject,
    first: driverObject.driverName ? driverObject.driverName.first : '',
    last: driverObject.driverName ? driverObject.driverName.last : '',
    imei:
        driverObject.tablets
        && driverObject.tablets.length > 0
        && driverObject.tablets[driverObject.tablets.length - 1].IMEI,
    created: driverObject.created
      ? moment(driverObject.created).format('YYYY-MM-DD')
      : '',
    agreed: driverObject.agreed
      ? moment(driverObject.agreed).format('YYYY-MM-DD')
      : '',
  };

  delete newDriver.driverName;
  delete newDriver.tablets;
  // newDriver = appendStatsForLastSevenDays(newDriver)
  delete newDriver.stats;
  delete newDriver.cities;
  delete newDriver.rideshareServices;
  delete newDriver.returnInfo;
  delete newDriver.engagements;
  delete newDriver.compliant;
  return newDriver;
});

const ExpandedComponent = ({ data }) => (
  <>
    {data.logs.map((url) => (
      <LogImg key={url} src={url} />
    ))}
  </>
);

const Drivers = () => {
  const [drivers, setDrivers] = useState([]);
  const [unmatchedIMEIs, setUnmatchedIMEIs] = useState();
  const [selected, setSelected] = useState(typesOfDrivers[0]);
  const [search, setSearch] = useState('');
  const [searchBy, setSearchBy] = useState({ value: 'email', label: 'Email' });
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [configurables, setConfigurables] = useState({
    // in future will have ability to toggle filters
    hidePortl: false,
  });
  const [visibleColumns, setVisibleColumns] = useState([]);

  const setInitialColumns = () => {
    const array = Object.entries(allColumns);
    const initialColumns = selected.showColumns || DEFAULT_COLUMNS;
    const newVisibleColumns = array.map(([key, item]) => ({
      ...item,
      omit: !initialColumns.includes(key),
    }));
    setVisibleColumns(newVisibleColumns);

    return newVisibleColumns;
  };

  const [filterView, setFilterView] = useState(false);

  const filterDrivers = useRef(
    debounce((allDrivers, searchValue, searchByValue) => {
      const filtered = allDrivers.filter((driver) => {
        if (!driver) return false;

        const {
          email, tablets, referralCode, driverName, phone, shipments,
        } = driver;

        if (configurables.hidePortl && email.includes('portlmedia')) {
          return false;
        }

        // check for search terms
        if (!searchValue || searchValue.length === 0) {
          return true;
        }
        switch (searchByValue) {
          case 'email':
            return email.includes(searchValue);
          case 'fullName': {
            const { first, last } = driverName;
            const driverFullName = `${first.toLowerCase()} ${last.toLowerCase()}`;

            return driverFullName.includes(searchValue.toLowerCase());
          }
          case 'IMEI': {
            if ((!tablets || tablets.length === 0) && searchValue) return false;
            return tablets[tablets.length - 1].IMEI.includes(searchValue);
          }
          case 'trackingPin': {
            if (shipments && shipments.length > 0) {
              return shipments.some((shipment) => {
                const { trackingPin } = shipment;
                return trackingPin.toString().includes(searchValue);
              });
            }
            return false;
          }
          case 'referralCode':
            return referralCode.includes(search);
          case 'phone':
            if (!phone && search) return false;
            return phone.includes(search);
          default:
            return true;
        }
      });

      // unpack latest stats object
      const todayFormatted = moment().format('DD-MM-YYYY');

      const withLeaderboardData = filtered.map((driver) => {
        const { stats } = driver;
        if (stats && stats[todayFormatted]) {
          const { currentRank, tier, points } = driver.stats[todayFormatted];
          return {
            ...driver,
            rank: currentRank,
            tier,
            points,
          };
        }
        return driver;
      });

      return setFilteredDrivers(withLeaderboardData);
    }, 500),
  );

  useEffect(() => {
    if (filteredDrivers.length > 0) {
      setLoading(false);
    }
  }, [filteredDrivers]);

  useEffect(() => {
    let isMounted = true;

    const getDrivers = async () => {
      setDrivers([]); // clear current table
      let driverRes;
      if (selected.value === 'Online') {
        driverRes = await getOnlineTablets(setLoading);
      } else {
        driverRes = await fetchAllInstalledDrivers(selected.value, setLoading);
      }
      if (driverRes.error) return console.log(driverRes);

      const parsedDrivers = driverRes.response ? driverRes.response : driverRes;

      if (
        driverRes.missingTablets
        && driverRes.missingTablets.length > 0
        && isMounted
      ) {
        setUnmatchedIMEIs(driverRes.missingTablets);
      }

      return isMounted && setDrivers(parsedDrivers);
      // filterDrivers.current(drivers);
    };
    getDrivers();
    setInitialColumns();
    // setRefresh(false);

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    // run filtering whenever search, drivers change

    filterDrivers.current(drivers, search, searchBy.value);
    const timeout = setTimeout(() => setLoading(false), 3 * 1000); // 3 seconds

    return () => {
      clearTimeout(timeout);
    };
  }, [search, drivers, searchBy]);

  const toggleFilter = () => setFilterView((prev) => !prev);

  const actionColumn = {
    name: 'Action',
    button: true,
    cell: (row) => (
      <Dots
        driver={row}
        setDrivers={setDrivers}
        allowedActions={selected.allowedActions}
      />
    ),
  };

  const noRecords = () => {
    if (loading && filteredDrivers.length === 0) {
      return <Loading paddingTop={30} paddingBottom={30} />;
    }
    return <span>There are no records to display</span>;
  };

  return (
    <Container>
      <Header>
        Drivers
        {`${filteredDrivers.length > 0 ? `(${filteredDrivers.length})` : ''}`}
      </Header>
      <Filters>
        <Dropdown
          items={typesOfDrivers}
          value={selected}
          onSelect={setSelected}
          thick
          width="175px"
        />
        <SearchInput
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
        />
        <Dropdown
          items={typesOfSearchBy}
          value={searchBy}
          onSelect={setSearchBy}
          thick
          width="140px"
          marginLeft="20px"
          placeholder="Search By"
        />

        {filteredDrivers.length > 0 && (
        <CsvDownload
          style={downloadStyles}
                // csvDownload takes two parameters (data, filename)
          data={mapDriverToCSV(filteredDrivers)}
          filename={`${selected.label}_${moment().format('YYYY-MM-DD')}.csv`}
        >
          <DownloadIconStyled color="rgba(0,0,0,0.4)" />
        </CsvDownload>
        )}
        {selected.value === 'Online' && (
        <ReloadAllButton onClick={() => reloadTablets(null, true)}>
          Reload All Tablets
        </ReloadAllButton>
        )}
        <FilterButton onClick={toggleFilter}>Filter</FilterButton>
      </Filters>

      <DataTable
        noHeader
        columns={[...visibleColumns, actionColumn]}
        data={filteredDrivers}
        striped
        pagination
        subHeader
        expandableRows
        noDataComponent={noRecords()}
        expandableRowDisabled={(driver) => !driver.logs}
        expandableRowsComponent={<ExpandedComponent />}
      />
      <ColumnFilterModal
        isOpen={filterView}
        onClose={toggleFilter}
        columns={visibleColumns}
        setColumns={setVisibleColumns}
        resetColumns={() => setVisibleColumns(setInitialColumns())}
      />
      {unmatchedIMEIs && (
        <UnmatchedTablets>
          Error: One or more IMEI(s) was not matched with a driver from MongoDB:
          {unmatchedIMEIs.map(({ email, IMEI }) => (
            <div key={`unmatched-${IMEI}`}>
              <p>{`${email} [ ID: ${IMEI}]`}</p>
            </div>
          ))}
        </UnmatchedTablets>
      )}
    </Container>
  );
};

export default Drivers;

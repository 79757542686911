import React from 'react';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: ${(props) => (props.type === 'small' ? '125px' : '-webkit-fill-available')};
    width: ${(props) => (props.type === 'small' ? '125px' : '-moz-available')}; /* Firefox */
    width: ${(props) => (props.type === 'small' ? '125px' : '100%')}; /* IE */
    height: 53px;
    cursor: ${(props) => !props.disabled && 'pointer'};
    border-radius: ${(props) => (props.radius ? `${props.radius}px` : '10px')};
    box-shadow: ${(props) => (props.action === 'return' ? ' ' : '0 3px 6px 0 rgba(0, 0, 0, 0.16)')};
    border: solid 1px ${(props) => {
    if (props.borderColor) {
      return props.borderColor;
    } if (props.error) {
      return '#d80f4d';
    } if (props.disabled) {
      return '#d6d6d6';
    }
    // return '#1FB6FF';
    return '#169aff';
  }};
    background-color: ${(props) => {
    if (props.disabled) {
      return '#d6d6d6';
    } if (props.color) {
      return props.color;
    } if (props.action === 'return') {
      return '#FFFFFF';
    } if (props.error) {
      return '#d80f4d';
    }
    // return '#1FB6FF'
    return '#169aff';
  }
};
    color: ${(props) => {
    if (props.fontColor) return props.fontColor;
    if (props.action === 'return') {
      if (props.error) return '#757575';
      return props.borderColor || '#1FB6FF';
    }
    return '#f9f9f9';
  }
};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '600')};
    disabled: ${(props) => props.disabled};

    &: active {
    background-color: ${(props) => {
    if (props.action === 'return') {
      return '#f3f3f3';
    } if (props.error) {
      return '#e66b91';
    }
    // return '#63ccff'
    return '#169aff';
  }};
}
    &: focus {
    outline: none;
}
`;

const StyledButton = (props) => {
  const { children, isLoading } = props;
  return (
    // eslint-disable-next-line
    <Button {...props}>
      {isLoading
        ? (
          <ClipLoader
            size={20} // is isLoading, disabled button press
            color="#ffffff"
            loading
          />
        )
        : children}
    </Button>
  );
};

export default StyledButton;

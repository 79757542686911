import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import App from './App';

import { ICON_CSS_CONFIG } from './Utilities/cssConfig';

require('dotenv').config();

// TODO: update all box sizing
const GlobalStyle = createGlobalStyle`

    html { 
        height: 100%;
        width: 100%;
        //overflow: scroll;
        //overflow-x: hidden;
    }
    body {
            font-family: 'Montserrat', sans-serif;
            
            font-size: 16px;
            font-weight: normal;
            margin: 0;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-align: center;
            width: 100%;
            height: 100%;
            color: #043650;
            //overflow: hidden;

            & > #root {
                height: 100%;
                width: inherit;
            }
    }

    div {
        box-sizing: border-box;
    }

    input, button {
        font-family: 'Montserrat', sans-serif;
    }

    a, :active :link, :visited {
        text-decoration: none;
        color: ${ICON_CSS_CONFIG.normal};
    }

    p, h1, h2, h3, h4 {
        margin: 0;
        padding: 0;
    }

    ::-webkit-scrollbar {
        width: 0px;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }

    ::-webkit-scrollbar-thumb {
        background: #e8f0fe;
    }

    .videoPlayer, .adVideoPlayer {
    display: flex;
    align-self: center;
    text-align: center;
    border: none;
}
`;

ReactDOM.render(
  <BrowserRouter>
    <GlobalStyle />
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);

import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { UserContext } from './context';

// DEMO usage [DO NOT REMOVE] Wyatt may kill you
// import Westjet from './Westjet';
import Campaign from './Pages/CampaignPlaceHolder';
// import AdvterisementDashboard from './Pages/AdvertisementDashboard';

// Pages
import Login from './Pages/Login';
import AdminLogin from './Pages/AdminLogin';
import Signup from './Pages/Signup';
// import Devices from './Pages/Devices';
import Logout from './Pages/Logout';
import Device from './Pages/Device';
import ClientOverview from './Pages/ClientOverview';
import Brands from './Pages/Brands';
import Profile from './Pages/Profile';
import Dashboard from './Pages/Dashboard';
import Settings from './Pages/Settings';
import CampaignImages from './Pages/CampaignImages';
import ContactUs from './Pages/ContactUs';
// import Compliance from './Pages/Compliance';
import UploadTablets from './Pages/UploadTablets';
import Shipping from './Pages/Shipping';
import Drivers from './Pages/Drivers';
import Activity from './Pages/Activity';
import Diagnostics from './Pages/Diagnostics';
import Tablets from './Pages/Tablets';
import Campaigns from './Pages/Campaigns';

// Component :
import Loading from './Components/Loading';

// Routes
import AuthRoute from './Routes/AuthRoute';
import PrivateRoute from './Routes/PrivateRoute';

// APIs
import { getUserInfo } from './Utilities/api';

const App = () => {
  const [user, setUser] = React.useState();
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);

  if (!user) {
    getUserInfo().then(setUser);
  }

  if (!user) return <Loading />;

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <SnackbarProvider maxSnack={2}>
        <Logout modalOpen={logoutModalOpen} setModalOpen={setLogoutModalOpen} />
        <Switch>
          {/* <Route exact path="/" render={() => <AdvterisementDashboard />} /> */}
          {/* <PrivateRoute path="/devices" exact render={() => <Devices />} /> */}
          <PrivateRoute path="/drivers" exact render={() => <Drivers />} />
          <PrivateRoute path="/tablets" exact render={() => <Tablets />} />
          <PrivateRoute path="/clients" exact render={() => <ClientOverview />} />
          {/* eslint-disable-next-line */}
          <PrivateRoute path="/client/:id/brands" exact render={(props) => <Brands type="media-agency" {...props} />} />
          {/* eslint-disable-next-line */}
          {/* <PrivateRoute path="/brand/:id/campaigns" exact render={(props) => <ClientOverview type="campaign" {...props} />} /> */}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <PrivateRoute path="/brand/:id/campaigns" exact render={(props) => <Campaigns {...props} />} />
          <PrivateRoute path="/profile" exact render={() => <Profile />} />
          <PrivateRoute path="/settings" exact render={() => <Settings setLogoutModalOpen={setLogoutModalOpen} />} />
          <PrivateRoute path="/device/:clientId" exact render={(props) => <Device clientId={props.match.params.clientId} />} />
          <PrivateRoute path="/images" exact render={() => <CampaignImages />} />
          <PrivateRoute path="/contact" exact render={() => <ContactUs />} />
          <PrivateRoute path="/dashboard" exact render={() => <Dashboard />} />
          <PrivateRoute path="/devices/upload" exact render={() => <UploadTablets />} />
          <PrivateRoute path="/activity" exact render={() => <Activity />} />
          <PrivateRoute path="/shipping" exact render={() => <Shipping />} />
          <PrivateRoute path="/diagnostics" exact render={() => <Diagnostics />} />
          <AuthRoute path="/admin" exact render={() => <AdminLogin />} />
          <AuthRoute path="/admin-signup" exact render={() => <Signup />} />
          <AuthRoute path="/login" exact render={() => <Login />} />
          {/* <Route path="/westjet" render={() => <Westjet />} /> */}
          <Route path="/campaign" exact render={() => <Campaign />} />
          <Redirect to="/dashboard" />
        </Switch>
      </SnackbarProvider>
    </UserContext.Provider>

  );
};

export default App;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CsvDownload from 'react-json-to-csv';
import { runDiagnostics } from '../../Utilities/api';
import Loading from '../../Components/Loading';
import Button from '../../Components/Button';

const Header = styled.h2`
    font-size: 24px;
    font-weight: 600;
    color: #222222;
    padding-bottom: 20px;
`;
const ReportTitles = styled.h3`
    padding: 10px 0;
`;
const ListItem = styled.p`
    padding: 5px 0;
`;

const Diagnostics = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const results = await runDiagnostics();
      if (results.error) return console.log(results);
      return setData(results);
    };
    fetchData();
  }, []);

  return (
    <>
      <Header>Tablet Diagnostics</Header>
      {data.noTablets || <Loading paddingTop={150} text="Fetching results.." />}
      {data.noTablets && (
      <div>
        <ReportTitles>No Tablets array:</ReportTitles>
        {data.noTablets.map((email) => (<ListItem key={email}>{email}</ListItem>))}
      </div>
      )}
      {data.missingInHivestack && (
      <div>
        <ReportTitles>Missing in hivestack:</ReportTitles>
        {data.missingInHivestack.map(({ email, imei, region }) => (
          <ListItem key={email}>
            Email:
            {' '}
            {email}
            , imei:
            {imei}
            , region:
            {region}
          </ListItem>
        ))}
        <Button as={CsvDownload} data={data.missingInHivestack} type="small" filename="hivestack_missing">Download CSV</Button>
      </div>
      )}
      {data.wrongRegion && (
      <div>
        <ReportTitles>In hivestack but listed as wrong region:</ReportTitles>
        {data.wrongRegion.map(({
          email, imei, hivestackRegion, driverRegion,
        }) => (
          <ListItem key={email}>
            Email:
            {' '}
            {email}
            , imei:
            {imei}
            , Hivestack Region:
            {hivestackRegion}
            , Driver Region:
            {driverRegion}
          </ListItem>
        ))}
      </div>
      )}
    </>
  );
};

export default Diagnostics;

import styled from 'styled-components';
import React from 'react';

import { useLocation, Link } from 'react-router-dom';
import Button from '../../Components/Button';
import { UserContext } from '../../context';
import { isAdmin } from '../../Utilities/helper';
import DownloadReportModal from '../DownloadReportModal';

// SVG ICONs
// import TabletIconStyled from '../../Components/Icon/Tablet';
import TabletsIconStyled from '../../Components/Icon/Tablets';
// import ProfileIconStyled from '../../Components/Icon/Profile';
import ClientListIconStyled from '../../Components/Icon/ClientList';
import SettingIconStyled from '../../Components/Icon/Settings';
import ContactIconStyled from '../../Components/Icon/ContactSupport';
import DownloadIconStyled from '../../Components/Icon/Download';
import DashboardIconStyled from '../../Components/Icon/Dashboard';
import RefreshIconStyled from '../../Components/Icon/Refresh';
import CarIconStyled from '../../Components/Icon/Car';
// import ShippingIconStyled from '../../Components/Icon/Shipping';

import BASE_CSS_CONFIG, { ICON_CSS_CONFIG } from '../../Utilities/cssConfig';

const { PADDING_LEFT } = BASE_CSS_CONFIG;

const MenuSubsectionContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 21px;
    height: 48px;
    padding-left: ${PADDING_LEFT - 5}px;
    color: ${ICON_CSS_CONFIG.normal};
    border-left: 5px solid white;
    :hover {
        background:  ${(props) => !props.selected && '#F6F6FF;'}
        cursor: pointer;
        border-left: 5px solid ${ICON_CSS_CONFIG.selected};
    }

    ${(props) => props.selected
      && `
        border-left: 5px solid ${ICON_CSS_CONFIG.selected};
        color: ${ICON_CSS_CONFIG.selected} !important;
        background: #e8f0fe;
    `}
`;

const MenuItemName = styled.p`
  padding-left: 24px;
  padding-right: 20px;
  flex: 1 0 70%;
  font-size: 14px;
  ${({ selected }) => selected && 'font-weight: 600;'}
`;

const MenuTitle = styled.h4`
  color: #bdbdbd;
  font-weight: 600;
  font-size: 12px;
  padding-top: 24px;
  padding-bottom: 12px;
  text-transform: uppercase;
  padding-left: ${PADDING_LEFT}px;
`;

const Divider = styled.hr`
  height: 0px;
  width: 80%;
  border: none;
  border-bottom: 1px solid #d7d7d7;
  padding: 0 15px;
`;

const MenuSubsection = ({
  icon: Icon, name, selected, path,
}) => (
  <MenuSubsectionContainer selected={selected} as={path && Link} to={path}>
    <Icon selected={selected} />
    <MenuItemName selected={selected}>{name}</MenuItemName>
  </MenuSubsectionContainer>
);

const ButtonWrapper = styled.div`
  padding: 10px ${PADDING_LEFT}px 20px;
  > button {
    background-color: #169aff;
    border: none;
    font-size: 14px;
    font-weight: 400;
  }
`;

const MenuButton = ({ onClick, name, icon: Icon }) => (
  <ButtonWrapper>
    <Button onClick={onClick}>
      <Icon />
      {name}
    </Button>
  </ButtonWrapper>
);

const Menu = () => {
  const { pathname } = useLocation();
  const { user } = React.useContext(UserContext);
  const [reportModal, setReportModal] = React.useState(false);

  const adminItems = [
    { name: 'Settings', icon: SettingIconStyled, path: '/settings' },
  ];
  if (isAdmin(user)) {
    adminItems.unshift(
      { name: 'Client List', icon: ClientListIconStyled, path: '/clients' },
      { name: 'Drivers', icon: CarIconStyled, path: '/drivers' },
      { name: 'Tablets', icon: TabletsIconStyled, path: '/tablets' },
      // { name: 'Upload Tablet', icon: TabletsIconStyled, path: '/devices/upload' },
      { name: 'Activity', icon: CarIconStyled, path: '/activity' },
      // { name: 'Shipping', icon: ShippingIconStyled, path: '/shipping' }
      // { name: 'Device', icon: TabletIconStyled, path: /\/device\/[\s\S]*/g },
    );
  } else {
    adminItems.push({
      name: 'Contact Support',
      icon: ContactIconStyled,
      path: '/contact',
    });
  }

  const sections = [
    {
      sectionName: 'General',
      items: [
        { name: 'Dashboard', icon: DashboardIconStyled, path: '/dashboard' },
      ],
    },
    {
      sectionName: 'Actions',
      items: [
        {
          name: 'Download Report',
          icon: DownloadIconStyled,
          onClick: () => setReportModal(true),
        },
      ],
    },
    {
      sectionName: 'Admin',
      items: adminItems,
    },
  ];

  return (
    <>
      {reportModal && (
        <DownloadReportModal
          isOpen={reportModal}
          onClose={() => setReportModal(false)}
        />
      )}
      {sections.map(({ sectionName, items }) => (
        <div key={`section-${sectionName}`}>
          <MenuTitle>{sectionName}</MenuTitle>
          {items.map((item) => (item.path ? (
            <MenuSubsection
              path={item.path}
              name={item.name}
              selected={pathname.match(new RegExp(item.path))}
              icon={item.icon}
              key={`item-subsection-${item.name}`}
            />
          ) : (
            <MenuButton
              onClick={item.onClick}
              icon={item.icon}
              name={item.name}
              key={`item-button-${item.name}`}
            />
          )))}
          <Divider />
        </div>
      ))}
      <MenuSubsectionContainer onClick={() => window.location.reload()}>
        <RefreshIconStyled />
        <MenuItemName>Refresh</MenuItemName>
      </MenuSubsectionContainer>
    </>
  );
};

export default Menu;

// <MenuContainer >
//     <LogoContainer><PortlInsightsLogo /></LogoContainer>
//     <MenuContentContainer >
/* </MenuContentContainer>
        </MenuContainer> */

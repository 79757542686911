import styled from 'styled-components';
import { ReactComponent as NotificationIcon } from './notification.svg';
import { ICON_CSS_CONFIG } from '../../../Utilities/cssConfig';

const NotificationIconStyled = styled(NotificationIcon)`
    height: 35px;
    stroke-width: 3;
    fill: ${({ selected }) => (selected ? ICON_CSS_CONFIG.selected : ICON_CSS_CONFIG.normal)};
`;

export default NotificationIconStyled;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import noScroll from 'no-scroll';

const modalStyles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '100',
    background: 'rgba(0, 0, 0, 0.4)',
    overflowY: 'auto',
    // flexDirection: 'column'
  },
  content: {
    position: 'static',
    // display: 'flex',
    justifyContent: 'center',
    padding: '15px 15px 0 15px',
    border: 'none',
    background: 'none',
    minWidth: '0px',
    overflow: 'visible',
    maxHeight: '100%',
  },
};

const ModalContainer = styled.div`
    background: white;
    border-radius: 10px;
    box-shadow: 7px 7px 7px 0 rgba(0, 0, 0, 0.25);
    max-width: ${({ maxWidth }) => (maxWidth || '560px')};
    padding: 35px 40px;
    text-align: ${({ center }) => (center || 'left')};
    //margin: 40px 0;
    //overflow: auto;
`;
const ModalTitle = styled.h2`
    color: #222222;
    font-size: 21px;
    font-weight: 500;
`;

ReactModal.setAppElement('body');

const Modal = ({
  children, isOpen, onClose, maxWidth, title, center, icon: Icon,
}) => {
  useEffect(() => {
    if (isOpen) {
      noScroll.on();
    }

    return () => noScroll.off();
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyles}
    >
      <ModalContainer maxWidth={maxWidth} center={center}>
        {Icon && <Icon size="medium" fillcolor="#169aff" />}
        {title && <ModalTitle>{title}</ModalTitle>}
        {children}
      </ModalContainer>
    </ReactModal>
  );
};

export default Modal;

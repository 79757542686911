import styled from 'styled-components';
import React from 'react';

const InputContainer = styled.div`
    position: relative;
    padding-top: 15px;
`;
const Input = styled.input`
    // border-radius: 10px;
    width: 100%;
    font-size: 14px;
    padding: 44px 25px 15px 25px;
    box-sizing: border-box;
    line-height: 1.29;
    height: 77px;
    border-radius: 10px;
    border: 1px solid ${(props) => ((props.error && props.touched) ? '#d80f4d' : '#d6d6d6')};
    background: url(${(props) => (props.error && props.touched) && './excla.png'}) no-repeat 95% ;
    background-color: ${(props) => ((props.error && props.touched) ? '#f9ecf2' : '#FFFFFF')};
    &:focus {
        outline: none;
        border: 2px solid #1FB6FF;
        caret-color: #1FB6FF;
    }
`;
const Label = styled.p`
    position: absolute;
    top: 30px;
    left: 24px;
    font-weight: 500;
`;

const InternalInput = ({
  placeholder, id, commonProps, type = 'text', label,
}) => (
  <InputContainer>
    {label && <Label>{label}</Label>}
    <Input
      placeholder={placeholder}
      id={id}
      onChange={commonProps.handleChange}
      onBlur={commonProps.handleBlur}
      value={commonProps.values[id]}
      error={commonProps.errors[id]}
      touched={commonProps.touched[id]}
      type={type}
    />
  </InputContainer>
);

export default InternalInput;

import React from 'react';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

// Components
import { ModalButton, ModalButtonCancel } from '../../Components/ModalButton';

const ButtonGroup = styled.div`
  text-align: right;
  margin-top: 35px;
`;

const CreateInput = styled(Field)`
  box-sizing: border-box;
  border: 1px solid #757575;
  border-radius: 5px;
  display: block;
  margin-top: 35px;
  padding: 11px;
  height: 40px;
  width: 477px; ;
`;

export const CreateText = styled.div`
  text-align: left;
  font-weight: 500;
  font-size: 21px;
  color: #222222;
`;

export const ErrorMessageStyled = styled.div`
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
  color: red;
`;

const name = Yup.string().trim().required('Please enter valid name.');

const FormSingleInput = ({
  toggle, createType, handleSubmit, formTitle,
}) => {
  const initialValues = { [createType]: '' };
  const ValidationSchema = Yup.object().shape({ [createType]: name });

  return (
    <>
      <CreateText>{formTitle}</CreateText>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={ValidationSchema}
      >
        <Form>
          <CreateInput name={createType} placeholder={`${createType} name`} />
          <ErrorMessage name={createType} component={ErrorMessageStyled} />
          <ButtonGroup>
            <ModalButtonCancel onClick={toggle}>cancel</ModalButtonCancel>
            <ModalButton type="submit">create</ModalButton>
          </ButtonGroup>
        </Form>
      </Formik>
    </>
  );
};

export default FormSingleInput;

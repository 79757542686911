import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Toggle from 'react-toggle';
import {
  fetchAllInstalledDrivers,
  generateShippingLabel,
  requestShippingDetails,
  updateDriver,
} from '../../Utilities/api';
import Button from '../../Components/Button';
import Modal from '../../Components/Modal';

const Header = styled.h4`
  padding: 0 45px;
  font-size: 30px;
  text-align: left;
  font-weight: 600;
  color: #222222;
`;

const DriverCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 10px;
  padding: 10px 20px;
`;
const ButtonWrapper = styled.div`
  width: 150px;
`;
const List = styled.ul`
  max-width: 900px;
  margin: 0 auto;
  padding: 0;
`;
const Name = styled.p`
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 5px;
`;
const Spacing = styled.div`
  height: 15px;
`;
const ModalText = styled.p`
  padding: 10px 0;
`;
const Bold = styled.span`
  font-weight: 500;
`;
const ModalButtonWrapper = styled(ButtonWrapper)`
  margin: 0 auto;
  padding-top: 20px;
`;
const JoinedText = styled.p`
  padding-bottom: 8px;
  font-size: 15px;
`;

const ShippedDriverCard = ({ driver, setShippedDrivers }) => {
  const [loading, setLoading] = useState(false);

  const handleRequestShippingDetails = async (driverData) => {
    if (loading) return null;

    setLoading(true);
    const {
      error,
      arrivedOn,
      expectedOn,
      mailedOn,
    } = await requestShippingDetails(driverData.trackingPin);
    setLoading(false);
    if (error || !mailedOn || !expectedOn) {
      return console.log('Error: ', error);
    }
    // else show details
    return setShippedDrivers((prevState) => prevState.map((someDriver) => {
      if (driver.email === someDriver.email) {
        return {
          ...someDriver,
          arrivedOn,
          expectedOn,
          mailedOn,
        };
      }
      return someDriver;
    }));
  };

  return (
    <DriverCard>
      <div>
        <Name>{`${driver.driverName.first} ${driver.driverName.last}`}</Name>
        <JoinedText>{driver.email}</JoinedText>
        <JoinedText>
          {driver.shipDate
            ? `Shipped on ${moment(driver.shipDate).format('MMMM D, YYYY')}`
            : `Joined on ${moment(driver.created).format('MMMM D, YYYY')}`}
        </JoinedText>
      </div>
      {driver.mailedOn ? (
        <div>
          <p>
            Mailed on
            {moment(driver.mailedOn).format('MMMM D, YYYY')}
          </p>
          {driver.arrivedOn ? (
            <p>
              Arrived on
              {moment(driver.arrivedOn).format('MMMM D, YYYY')}
            </p>
          ) : (
            <p>
              Expected on
              {moment(driver.expectedOn).format('MMMM D, YYYY')}
            </p>
          )}
        </div>
      ) : (
        driver.trackingPin && (
          <ButtonWrapper>
            <Button
              onClick={() => handleRequestShippingDetails(driver)}
              isLoading={loading}
            >
              Request Details
            </Button>
          </ButtonWrapper>
        )
      )}
      <ButtonWrapper>
        {driver.trackingPin && (
          <Button
            as="a"
            href={`https://www.canadapost.ca/trackweb/en#/search?searchFor=${driver.trackingPin}`}
            target="_blank"
          >
            Track
          </Button>
        )}
      </ButtonWrapper>
    </DriverCard>
  );
};

const Shipping = () => {
  const [showShipped, setShowShipped] = useState(false);
  const [requestedDrivers, setRequestedDrivers] = useState([]);
  const [shippedDrivers, setShippedDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState();

  const handleGenerateLabel = async (driver) => {
    const fields = {
      name: `${driver.driverName.first} ${driver.driverName.last}`,
      addressOne: driver.addressOne,
      addressTwo: driver.addressTwo,
      city: driver.city,
      province: driver.province,
      postalCode: driver.postalCode,
      email: driver.email,
    };
    const res = await generateShippingLabel(fields);

    if (!res.error) {
      // assume successful and set local driver state to show label has been created
      setRequestedDrivers((prevState) => prevState.map((driverElem) => {
        if (driverElem.email === fields.email) return { ...driverElem, trackingPin: 'something' };
        return driverElem;
      }));
    }

    // close modal
    setSelectedDriver(undefined);
  };

  useEffect(() => {
    const getShippingRequestedDrivers = async () => {
      const response = await fetchAllInstalledDrivers('ShippingRequested');

      if (response.error) return console.log(response);

      // console.log(response);
      // shows requestedDriver that joined first at top by default
      return setRequestedDrivers(response);
    };
    const getTabletShippedDrivers = async () => {
      const response = await fetchAllInstalledDrivers('TabletShipped');

      if (response.error) return console.log(response);

      // console.log(response);
      // shows requestedDriver that joined first at top by default
      return setShippedDrivers(response);
    };

    if (showShipped && shippedDrivers.length === 0) {
      getTabletShippedDrivers();
    }
    if (!showShipped && requestedDrivers.length === 0) {
      getShippingRequestedDrivers();
    }

    // eslint-disable-next-line
  }, [showShipped]);

  const changeDriverToShipped = async (driver) => {
    const { error } = await updateDriver(driver.email, {
      leadStatus: 'TabletShipped',
    });
    if (error) return console.log(error);
    setRequestedDrivers((prevState) => [
      ...prevState.filter((someDriver) => someDriver.email !== driver.email),
    ]);
    return setShippedDrivers([]);
  };

  return (
    <>
      <Modal
        isOpen={typeof selectedDriver === 'object'}
        onClose={() => setSelectedDriver(undefined)}
        title="Generate Label"
        center
      >
        {selectedDriver && selectedDriver.driverName && (
          <ModalText>
            Are you sure you want to generate a shipping label for
            <Bold>
              {selectedDriver.driverName.first}
              {' '}
              {selectedDriver.driverName.last}
            </Bold>
            ?
          </ModalText>
        )}
        <ModalText>
          <Bold>This will charge your card.</Bold>
        </ModalText>
        <ModalButtonWrapper>
          <Button onClick={() => handleGenerateLabel(selectedDriver)}>
            Yes, confirm
          </Button>
          <Spacing />
          <Button onClick={() => setSelectedDriver(undefined)} action="return">
            Cancel
          </Button>
        </ModalButtonWrapper>
      </Modal>
      <Header>Shipping</Header>
      <div>
        {requestedDrivers.length}
        {' '}
        Requested |
        {shippedDrivers.length}
        {' '}
        Shipped
        {' '}
        <Toggle
          checked={showShipped}
          onChange={() => setShowShipped(!showShipped)}
          icons={false}
        />
      </div>
      {showShipped ? (
        <List>
          {shippedDrivers.length > 0
            && shippedDrivers.map((driver) => (
              <ShippedDriverCard
                driver={driver}
                key={`hasshipped-key${driver.email}`}
                setShippedDrivers={setShippedDrivers}
              />
            ))}
        </List>
      ) : (
        <List>
          {requestedDrivers.length > 0
            && requestedDrivers.map((driver) => (
              <DriverCard key={`shipping-key${driver.email}`}>
                <div>
                  <Name>{`${driver.driverName.first} ${driver.driverName.last}`}</Name>
                  <JoinedText>{driver.email}</JoinedText>
                  <JoinedText>
                    Joined on
                    {moment(driver.created).format('MMMM D, YYYY')}
                  </JoinedText>
                  <p>
                    {driver.addressOne}
                    ,
                    {driver.addressTwo}
                  </p>
                  <p>
                    {driver.city}
                    ,
                    {driver.province}
                  </p>
                  <p>{driver.postalCode}</p>
                </div>
                <ButtonWrapper>
                  {driver.trackingPin ? (
                    <Button onClick={() => changeDriverToShipped(driver)}>
                      {' '}
                      Shipped!
                    </Button>
                  ) : (
                    <Button onClick={() => setSelectedDriver(driver)}>
                      Generate Label
                    </Button>
                  )}
                </ButtonWrapper>
              </DriverCard>
            ))}
        </List>
      )}
    </>
  );
};

export default Shipping;

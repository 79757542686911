import styled from 'styled-components';
import { ReactComponent as CarIcon } from './car.svg';
import { ICON_CSS_CONFIG } from '../../../Utilities/cssConfig';

const CarIconStyled = styled(CarIcon)`
    height: 24px;
    fill: ${({ selected }) => (selected ? ICON_CSS_CONFIG.selected : ICON_CSS_CONFIG.normal)};
`;

export default CarIconStyled;

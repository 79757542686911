import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { calcFontSize } from '../../Utilities/fontsize';

const Logo = styled.p`
    text-decoration: none;
    z-index: 1;
    font-size: ${calcFontSize(33, 65)};
    font-weight: bold;
    line-height: 1.22;
    letter-spacing: -1.5px;
    text-align: left;
    color: ${(props) => (props.islight ? 'white' : '#1fb6ff')} !important;
    margin: 0;

    :hover {
        cursor: pointer;
    }
`;

const PortlLogo = ({ islight }) => (
  <Logo islight={islight ? 1 : 0} as={Link} to="/">
    portl
  </Logo>
);

export default PortlLogo;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.19;
    color: #1FB6FF;

    :hover {
        font-weight: 600;
        color: ${({ hoverColor }) => (hoverColor || '#043650')};
    }
`;

export default StyledLink;

import styled from 'styled-components';
import { ReactComponent as DashboardIcon } from './dashboard.svg';
import { ICON_CSS_CONFIG } from '../../../Utilities/cssConfig';

const ContactIconStyled = styled(DashboardIcon)`
    height: 24px;
    fill: ${({ selected }) => (selected ? ICON_CSS_CONFIG.selected : ICON_CSS_CONFIG.normal)};
`;

export default ContactIconStyled;

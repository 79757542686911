/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import TooltipTrigger from 'react-popper-tooltip';

const TooltipContainer = styled.div`
  max-height: 100%;

  ${({ additionalStyling }) => additionalStyling}
`;

// const TooltipArrow = styled.div`
//     height: 1rem;
//     position: absolute;
//     width: 1rem;

//     &::before {
//         border-style: solid;
//         content: '';
//         display: block;
//         height: 0;
//         margin: auto;
//         width: 0;
//     }

//     &::after {
//         border-style: solid;
//         content: '';
//         display: block;
//         height: 0;
//         margin: auto;
//         position: absolute;
//         width: 0;
//     }

//     ${`[data-placement*='bottom']`} {
//        height: 900px;
//       }
// `

const Tooltip = (props) => {
  const {
    children, additionalStyling, tooltip, hideArrow,
  } = props;
  return (
    <TooltipTrigger
      {...props}
      tooltip={({
        arrowRef,
        tooltipRef,
        getArrowProps,
        getTooltipProps,
        placement,
      }) => (
        <TooltipContainer
          {...getTooltipProps({
            ref: tooltipRef,
          })}
          additionalStyling={additionalStyling}
        >
          {!hideArrow && (
            <div
              {...getArrowProps({
                ref: arrowRef,
                'data-placement': placement,
              })}
            />
          )}
          {tooltip}
        </TooltipContainer>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span
          {...getTriggerProps({
            ref: triggerRef,
            className: 'trigger',
          })}
        >
          {children}
        </span>
      )}
    </TooltipTrigger>
  );
};

export default Tooltip;

import axios from 'axios';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import FileDownload from 'js-file-download';
import { serverUrl, strapiUrl /* axiosConfig */ } from '../config';

// Endpoints
const createAdminEndpoint = '/admin/create';
const getAdminEndpoint = '/admin/info';
const loginAdminEndpoint = '/admin/login';
const getConnectedTablets = '/admin/sockets/online';
const getDriverTablet = '/admin/tablet/';
const getDriverTablets = '/admin/tablets';

// Insights
const getUserEndpoint = '/insights/info';
const loginInsightsEndpoint = '/insights/login';
const logoutEndpoint = '/insights/logout';
const getImpressionsEndpoint = '/insights/impressions';

const axiosConfig = {
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};
// const localaxiosConfig = {
//     headers: {
//         "Accept": "application/json",
//         "Content-Type": "application/json",
//         "Authorization": document.cookie.split('adminToken=')[1],
// Note: Log onto staging once first!
//     }
// }
// axios.interceptors.response.use((response) => {
//     if (response) {
//         //perform the manipulation here and change the response object
//         console.log("Axios interceptor....", response)
//         console.log(response.headers['Authorization'])
//         console.log(axios.defaults.headers)
//     }
//     return response;
// }, (error) => {
//     console.log("Interceptor error", error);
//     return Promise.reject(error.message);
// });

export const constructAdmin = async (admin) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}${createAdminEndpoint}`,
      admin,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const constructCompany = async (companyData, mediaAgency = false) => {
  try {
    const parsedData = { ...companyData };
    if (mediaAgency) {
      parsedData.repBy = mediaAgency;
    }
    const { data } = await axios.post(
      `${serverUrl}/insights/company`,
      parsedData,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const getAdminInfo = async () => {
  try {
    const { data } = await axios.get(serverUrl + getAdminEndpoint, axiosConfig);
    return data;
  } catch (error) {
    console.error('Error fetching Admin info: ', error);
    return { error };
  }
};

export const getUserInfo = async () => {
  try {
    const { data } = await axios.get(serverUrl + getUserEndpoint, axiosConfig);
    return data;
  } catch (error) {
    console.error('Error fetching User info: ', error);
    return { error };
  }
};

export const getOnlineTablets = async (setLoading) => {
  if (setLoading) {
    setLoading(true);
  }
  try {
    const { data } = await axios.get(serverUrl + getConnectedTablets, axiosConfig);
    return data;
  } catch (error) {
    console.error('Error fetching Admin info: ', error);
    return { error };
  }
};

export const getDriverAndTabletInfo = async (clientID) => {
  try {
    const { data } = await axios.get(
      serverUrl + getDriverTablet + clientID,
      axiosConfig,
    );
    return data;
  } catch (error) {
    console.error('Error fetching Admin info: ', error);
    return { error };
  }
};

export const getInsightCompanies = async (ids) => {
  let url = `${serverUrl}/insights/company`;
  if (ids) {
    url = `${url}/brands?ids=${ids.join(',')}`;
  }

  try {
    const { data } = await axios.get(url, axiosConfig);
    return data;
  } catch (error) {
    console.error('Error fetching Companies info: ', error);
    return { error };
  }
};

export const getInsightPlanners = async (ids) => {
  let url = `${serverUrl}/insights/planners/`;
  if (ids) {
    url = `${url}?ids=${ids.join(',')}`;
  }

  try {
    const { data } = await axios.get(url, axiosConfig);
    return data;
  } catch (error) {
    console.error('Error fetching Companies Planners ', error);
    return { error };
  }
};

export const loginAdmin = async (admin) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}${loginAdminEndpoint}`,
      { email: admin.email, password: admin.password },
      axiosConfig,
    );

    return data;
  } catch (error) {
    console.error('Error logging in', error.response && error.response.data);
    return { error };
  }
};

export const getTabletLogs = async (clientID, lines) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/tablet/logs`,
      { clientID, lines },
      axiosConfig,
    );

    return data;
  } catch (error) {
    console.error('Error logging in', error);
    return { error };
  }
};

export const reloadTablets = async (clientId, all) => {
  const payload = all ? { reloadAll: true } : { tablets: [clientId] };
  try {
    const { data } = await axios.post(
      `${serverUrl}/tablet/reload`,
      payload,
      axiosConfig,
    );

    return data;
  } catch (error) {
    console.error('Error logging in', error);
    return { error };
  }
};

export const createZendeskTicket = async (email, subject, commentBody) => {
  //  const detect = new MobileDetect(window.navigator.userAgent);

  const fullSubject = `[${process.env.REACT_APP_ENV || 'localhost'}] ${subject}`;
  const addInfoBody = {
    commentBody,
  };

  try {
    const body = {
      request: {
        requester: {
          name: 'Zendesk (Admin-App)',
          email,
        },
        subject: fullSubject,
        comment: {
          body: JSON.stringify(addInfoBody),
        },
        collaborators: [
          { name: 'Jay Craig', email: 'jay@portlmedia.com' },
          { name: 'Nolan Buzanis', email: 'nolan.buzanis@portlmedia.com' },
        ],
      },
    };

    const { data } = await axios.post(
      'https://portlmedia.zendesk.com/api/v2/requests.json',
      body,
    );
    return data;
  } catch (error) {
    console.error('Error creating Zendesk ticket: ', error);
    return { error };
  }
};

// Insights ------------------------------------

export const loginPlanner = async ({ email, password }) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}${loginInsightsEndpoint}`,
      { email, password },
      axiosConfig,
    );

    return data;
  } catch (error) {
    console.error('Error logging in', error.response && error.response.data);
    return { error };
  }
};

export const queryCreatives = async (ids) => {
  let filter = 'where: {ItemType: "directAd"}';
  if (!ids || (ids && ids.length === 0)) {
    return { error: 'Invalid argument.' };
  }
  filter = `where: { id_in: [${ids.toString()}] }`;
  const query = gql`
    query {
        welcomeitems(${filter}) {
        id
        Name
        AssetUrl
        CreativeType
        ItemType
        Active
        Approved
        }
    }
`;

  const httpLink = createHttpLink({ uri: `${strapiUrl}/graphql` });
  // return the headers to the context so httpLink can read them
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
    },
  }));
  const gqlClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return gqlClient.query({ query });
};

export const getImpressions = async (creativeId) => {
  try {
    const { data } = await axios.get(
      `${serverUrl}${getImpressionsEndpoint}/${creativeId}`,
      axiosConfig,
    );
    return data;
  } catch (error) {
    console.error('Error fetching creative', error.response && error.response.data);
    return { error };
  }
};

export const getCampaignImpressions = async (creativeIds = []) => {
  try {
    const stringifiedIds = JSON.stringify(creativeIds);
    const { data } = await axios.get(
      `${serverUrl}/insights/campaign/impressions/${stringifiedIds}`,
      axiosConfig,
    );

    return data;
  } catch (error) {
    console.log('Error fetching total impressions', error);
    return { error };
  }
};

export const updateInsightCompanies = async (updates) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/insights/company/update`,
      { ...updates },
      { withCredentials: true },
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const deleteInsightCompanies = async (_id, agencyID = false) => {
  try {
    const { data } = await axios.delete(`${serverUrl}/insights/company/${_id}`, {
      data: { parentCompany: agencyID },
      withCredentials: true,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

export const linkPlannerToCompany = async (membership) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/insights/membership`,
      { ...membership },
      { withCredentials: true },
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const getInsightBrands = async () => {
  try {
    const { data } = await axios.get(`${serverUrl}/insights/brands`, {
      withCredentials: true,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

export const logUserOut = async () => {
  try {
    await axios.get(`${serverUrl}${logoutEndpoint}`, axiosConfig);

    return 'success';
  } catch (error) {
    return { error };
  }
};

export const updatePlanner = async (planner) => {
  try {
    await axios.post(`${serverUrl}/insights/planner/update`, planner, axiosConfig);

    return 'success';
  } catch (error) {
    return { error };
  }
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    await axios.post(
      `${serverUrl}/insights/planner/update-password`,
      { oldPassword, newPassword },
      axiosConfig,
    );

    return 'success';
  } catch (error) {
    return { error };
  }
};

export const changeProfilePicture = async (profilePicture) => {
  const formData = new FormData();
  formData.append('profile', profilePicture);
  formData.append('fileType', profilePicture.type);
  try {
    const { data } = await axios.post(
      `${serverUrl}/insights/update-image`,
      formData,
      { withCredentials: true },
    );
    return data;
  } catch (error) {
    console.log('upload error', error);
    return { error };
  }
};

export const getPlannerCompany = async () => {
  try {
    const { data } = await axios.get(
      `${serverUrl}/insights/planner/company`,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

// experimental
export const fetchDistanceData = async (email, imei, startDate, endDate) => {
  try {
    const formatted = JSON.stringify({
      email,
      imei,
      startDate,
      endDate,
    });
    const { data } = await axios.post(
      `${serverUrl}/admin/heartbeats`,
      formatted,
      axiosConfig,
    );

    return data;
  } catch (error) {
    return { error };
  }
};

export const downloadImpressionData = async (creativeId) => {
  try {
    const params = {
      format: 'csv',
    };
    const { data } = await axios.get(
      `${serverUrl}${getImpressionsEndpoint}/${creativeId}`,
      {
        ...axiosConfig,
        responseType: 'blob',
        params,
      },
    );

    FileDownload(data, 'report.csv');
    return 'success';
  } catch (error) {
    return { error };
  }
};

export const uploadHivestackTablets = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  // formData.append('location', location);
  try {
    const { data } = await axios.post(`${serverUrl}/admin/hivestack`, formData, {
      withCredentials: true,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

export const uploadVistarTablets = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  // formData.append('location', location);
  try {
    const { data } = await axios.post(`${serverUrl}/admin/vistar`, formData, {
      withCredentials: true,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

export const createPlannerAndLinkCompanies = async (planner) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/insights/planner/create`,
      planner,
      { withCredentials: true },
    );
    return data;
  } catch (error) {
    return { error };
  }
};
export const fetchAllInstalledDrivers = async (status, setLoading) => {
  // TODO: change name
  if (setLoading) {
    setLoading(true);
  }
  try {
    const { data } = await axios.get(`${serverUrl}/admin/drivers`, {
      params: { status },
      ...axiosConfig,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

export const fetchAllTrips = async (page = 1, per_page = 10) => {
  // TODO: change name
  try {
    const { data } = await axios.get(`${serverUrl}/admin/trips`, {
      ...axiosConfig,
      params: { page, per_page },
    });
    return data;
  } catch (error) {
    return { error };
  }
};

export const generateShippingLabel = async ({
  name,
  addressOne,
  addressTwo,
  postalCode,
  city,
  province,
  email,
  IMEI,
  country,
  phone,
}) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/admin/shipping-label`,
      JSON.stringify({
        name,
        addressOne,
        addressTwo,
        postalCode,
        city,
        province,
        email,
        IMEI,
        country,
        phone,
      }),
      {
        ...axiosConfig,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${name}_label.pdf`); // any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
    return 'success'; // it is a pdf so download right away
  } catch (error) {
    return { error };
  }
};

export const generateReturnLabel = async ({
  name,
  addressOne,
  addressTwo,
  postalCode,
  city,
  province,
  email,
}) => {
  try {
    const response = await axios.post(
      `${serverUrl}/admin/return-label`,
      JSON.stringify({
        name,
        addressOne,
        addressTwo,
        postalCode,
        city,
        province,
        email,
      }),
      {
        ...axiosConfig,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${name}_return_label.pdf`); // any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
    return response;
  } catch (error) {
    return { error };
  }
};

export const requestShippingDetails = async (trackingPin) => {
  try {
    const { data } = await axios.get(`${serverUrl}/admin/track-package`, {
      ...axiosConfig,
      params: { id: trackingPin },
    });

    return data;
  } catch (error) {
    return { error };
  }
};

export const updateDriver = async (email, updates) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/admin/update-driver`,
      JSON.stringify({ email, ...updates }),
      axiosConfig,
    );

    return data;
  } catch (error) {
    return { error };
  }
};

export const deleteDriver = async (email) => {
  try {
    const { data } = await axios.delete(
      `${serverUrl}/admin/delete/${email}`,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const runDiagnostics = async () => {
  try {
    const { data } = await axios.get(
      `${serverUrl}/admin/tablet-diagnostics`,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const handleDriverLogout = async (imei) => {
  try {
    const url = `${serverUrl}/tablet/logout/${imei}`;
    await axios.post(url, {}, axiosConfig);
    return 'success';
  } catch (error) {
    return error;
  }
};

export const handleReplaceDriver = async (imei, email) => {
  try {
    const url = `${serverUrl}/tablet/driver/replace`;
    const body = JSON.stringify({
      email,
      imei,
    });
    const response = await axios.post(url, body, axiosConfig);
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAllTablets = async () => {
  try {
    const { data } = await axios.get(`${serverUrl}${getDriverTablets}`, axiosConfig);
    return data;
  } catch (error) {
    return { error };
  }
};

export const uploadTabletToHivestack = async (imei, city) => {
  try {
    if (!imei) return { error: 'Invalid imei.' };
    const body = JSON.stringify({
      imei: imei.toString(),
      city,
    });

    const { data } = await axios.post(
      `${serverUrl}/admin/hivestack`,
      body,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const uploadTabletToVistar = async (imei, city) => {
  try {
    if (!imei) return { error: 'Invalid imei!' };
    const body = JSON.stringify({
      imei: imei.toString(),
      city,
    });

    const { data } = await axios.post(
      `${serverUrl}/admin/vistar`,
      body,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const addCampaignToCompany = async (_id, campaign) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/insights/campaign`,
      { _id, campaign },
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const getInsightCompanyByQuery = async (query) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/insights/brands-by-company/`,
      query,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const deleteInsightsCampaign = async (name, brandId) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/insights/campaign-delete`,
      { name, brandId },
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const getInsightBrandAndCompany = async (brandId) => {
  try {
    const { data } = await axios.get(
      `${serverUrl}/insights/brand-and-company/${brandId}`,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const updateInsightCampaign = async (brandId, campaignId, updates) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/insights/campaign-edit`,
      { brandId, campaignId, updates },
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const downloadLabel = async ({ labelLink, mediaType }, name) => {
  try {
    const { data } = await axios.get(
      `${serverUrl}/admin/shipping-label?link=${labelLink}&mediaType=${mediaType}`,
      {
        ...axiosConfig,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${name}_shipping_label.pdf`); // any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.log('Something happened while downloading the shipping label', error);
  }
};

export const addDistanceDriver = async (values) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/admin/distance`,
      values,
      axiosConfig,
    );
    return data;
  } catch (error) {
    return { error };
  }
};

export const generateExchangeLabel = async ({
  name,
  addressOne,
  addressTwo,
  postalCode,
  city,
  province,
  email,
  IMEI,
  country,
  phone,
  returnLabel,
  shipments,
}) => {
  try {
    const { data } = await axios.post(
      `${serverUrl}/admin/exchange-shipping-label`,
      JSON.stringify({
        name,
        addressOne,
        addressTwo,
        postalCode,
        city,
        province,
        email,
        IMEI,
        country,
        phone,
        returnLabel,
        shipments,
      }),
      {
        ...axiosConfig,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      },
    );

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${name}_label.pdf`); // any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
    return 'success'; // it is a pdf so download right away
  } catch (error) {
    return { error };
  }
};

// export const updateHivestackTablet = async (hivestackId, updates) => {
// try{
//   const body = JSON.stringify({
//       hivestackId,
//       ...updates
//   });
//   const { data } = await axios.put(
//       `${serverUrl}/admin/hivestack/tablet`,
//       body,
//       axiosConfig,
//     );
//     return data;
// }catch(error){
//   return {error};
// }

// };

import styled from 'styled-components';
import { ReactComponent as ArrowNavigateIcon } from './arrowNavigate.svg';

const ArrowNavigateIconStyled = styled(ArrowNavigateIcon)`
    height: 53px;
    width: 30px;
    stroke-width: 3;
    fill: #757575;
    transition: all 0.25s;
    padding-right: 15px;
`;

export default ArrowNavigateIconStyled;

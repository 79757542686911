import styled from 'styled-components';
import { ReactComponent as SaveIcon } from './save.svg';

const DownloadIconStyled = styled(SaveIcon)`
    height: 23px;
    fill: ${({ color }) => color || 'white'};
    margin-right: 17px;
`;

export default DownloadIconStyled;

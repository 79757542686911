/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';

import Collapsible, {
  ROW_TEMPLATE,
  MAIN_SECTION_STYLE,
  DATA_STYLE,
  EDGE_SECTION,
} from '../../Components/Collapsible';

const HeaderRow = styled.div`
  ${ROW_TEMPLATE}
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: 2.22;
  color: #bdbdbd;
`;

const MainSection = styled.span`
  ${MAIN_SECTION_STYLE}
  font-weight: 500;
  cursor: pointer;
`;

const Data = styled.span`
  ${DATA_STYLE}
`;

const EndingSection = styled.span`
  width: ${EDGE_SECTION};
`;

const Table = ({
  list,
  headers,
  menuContent,
  setModalContent,
  plannerShortcutMenuOptions,
}) => {
  const [sortedField, setSortedField] = React.useState('dateAdded');

  let sortedContent;
  try {
    sortedContent = [...list];
  } catch (error) {
    console.log('Error: ', error);
    return <div> error </div>;
  }

  if (sortedField) {
    sortedContent.sort((compA, compB) => {
      if (compA[sortedField] < compB[sortedField]) return -1;
      if (compB[sortedField] < compA[sortedField]) return 1;

      return 0;
    });
  }

  const MAP_HEADER_TO_KEY = {
    'date added': 'dateAdded',
    type: 'type',
    status: 'disabled',
    impressions: 'impressions',
  };

  return (
    <>
      <HeaderRow>
        <MainSection onClick={() => setSortedField('name')}>
          {headers.mainHeader}
        </MainSection>
        {headers.subHeaders.map((header, index) => (
          <Data
            key={`header-${index}`}
            onClick={() => setSortedField(MAP_HEADER_TO_KEY[header])}
          >
            {header}
          </Data>
        ))}
        <EndingSection />
      </HeaderRow>
      {sortedContent.map((content, index) => (
        <Collapsible
          key={`row-${index}`}
          data={content}
          headers={headers.subHeaders}
          menuContent={menuContent}
          setModalContent={setModalContent}
          plannerShortcutMenuOptions={plannerShortcutMenuOptions}
        />
      ))}
    </>
  );
};

export default Table;

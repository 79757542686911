import React from 'react';
import styled from 'styled-components';
import Spacing from '../../Components/Spacing';
// import PortlLogo from '../../Components/PortlLogo'

const PortlInsights = styled.div`
    width: 100%;
`;

const AuthHeader = ({ children }) => (
  <>
    <Spacing />
    <PortlInsights>
      <img src="/assets/portlInsights.png" alt="portlInsights" />
    </PortlInsights>
    <Spacing />
    {children}
  </>
);

export default AuthHeader;

import React from 'react';
import styled from 'styled-components';

import SmallArrow from '../Icon/SmallArrow';

const FlexWrapper = styled.div`
    display: flex;
    height: 127px;
    border-radius: 10px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
    margin: 0 auto;
    overflow: hidden;
`;

const Bar = styled.div`
    width: 7px;
    background-color: ${(props) => {
    if (props.color) {
      return props.color < 100 ? '#ca0307' : '#13ba71';
    }
    return '#169aff';
  }};
`;

const ContentWrapper = styled.div`
    width: 240px; //247px;
    background-color: ${(props) => {
    if (props.color) {
      return props.color < 100 ? '#f9ecf2' : '#edf9f8';
    }
    return '#ffffff';
  }};
    color: #222222;
    padding: 14px;
    font-size: 13px;
`;

const Title = styled.div`
    font-size: 13px;
`;

const Impressions = styled.div`
    font-size: 31px;
    font-weight: 600;
    color: #003863;
    margin: 14px auto;
`;

const PercentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Percent = styled.span`
    padding-right: 8px;
    font-size: 14px;
`;

const SmallArrowIcon = styled(SmallArrow)`
    transform: ${(props) => (props.percent < 100 ? 'rotate(90deg)' : 'rotate(-90deg)')};
    ${(props) => props.percent === 0 && 'display: none;'}
`;

const ImpressionBox = ({
  title, number, percent, detail,
}) => (
  <FlexWrapper>
    <Bar color={percent} />
    <ContentWrapper color={percent}>
      <Title>
        {title}
      </Title>
      <Impressions>
        {number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </Impressions>
      <PercentWrapper>
        {Number.isInteger(percent) && (
        <>
          <SmallArrowIcon percent={percent} />
          <Percent>
            {percent === 0 ? 0 : Math.abs(percent - 100)}
            %
          </Percent>
        </>
        )}
        <span>{detail}</span>
      </PercentWrapper>
    </ContentWrapper>
  </FlexWrapper>
);

export default ImpressionBox;

import styled from 'styled-components';

const Spacing = styled.div`
  height: ${({ height }) => height || '50px'};

  ${({ type }) => {
    if (type === 'small') return 'height : 15px;';
    return type === 'medium'
      ? 'height : 25px;'
      : type === 'large' && 'height : 60px;';
  }}
`;

export default Spacing;

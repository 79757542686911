import React from 'react';
import styled from 'styled-components';

const LogoStyled = styled.img`
    display: block;
    height: 100%;
    max-width: 137px;
    max-height: 84px;
`;

const PortlInsightsLogo = () => <LogoStyled src="/assets/portlInsights.png" />;

export default PortlInsightsLogo;

import styled from 'styled-components';
import React from 'react';
import { ReactComponent as Auth0Icon } from './auth0.svg';

const Auth0IconStyled = styled(Auth0Icon)`
    height: 35px;
    width: 35px;
      stroke: #043650;
    stroke-width: 127px;
    fill: lavender;
    padding: 0 10px;
`;

const Auth0Link = styled.a`
    text-decoration: none;
    :hover {
        cursor: pointer;
        transform: scale(1.05);
    }
`;

const Auth0 = ({ url }) => (
  <Auth0Link href={url} target="_blank">
    <Auth0IconStyled />
  </Auth0Link>
);

export default Auth0;

import styled from 'styled-components';
import React from 'react';
import InputError from '../InputError';
import { BLOCK_USERS_FROM_STAGING } from '../../config';

const InputSectionStyled = styled.div`
  width: 100%;
  margin: 0 auto 18px;
`;

const InputErrorWithLink = styled(InputError)`
  :link {
    color: #1fb6ff;
    text-decoration: underline;
  }
`;

const Input = styled.input`
  width: 100%;
  border-radius: 10px;
  font-size: 18px;
  color: #9e9e9e;
  height: 55px;
  border: 1px solid
    ${(props) => (props.error && props.touched ? '#d80f4d' : '#d6d6d6')};
  background: url(${(props) => props.error && props.touched && './excla.png'})
    no-repeat 95%;
  background-color: ${(props) => (props.error && props.touched ? '#fae5ec' : '#FFFFFF')};
  padding-left: 15px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border: 2px solid #1fb6ff;
    caret-color: #1fb6ff;
  }
`;

const InputSection = ({
  placeholder, id, commonProps, type = 'text',
}) => {
  const redirect = commonProps.errors[id] === BLOCK_USERS_FROM_STAGING;
  const shouldRedirect = () => {
    if (redirect) window.location.href = 'https://drivers.portlmedia.com';
  };
  return (
    <InputSectionStyled>
      <Input
        placeholder={placeholder}
        id={id}
        onChange={commonProps.handleChange}
        onBlur={commonProps.handleBlur}
        value={commonProps.values[id]}
        error={commonProps.errors[id]}
        touched={commonProps.touched[id]}
        type={type}
        onClick={shouldRedirect}
      />
      {commonProps.errors[id]
        && commonProps.touched[id]
        && (redirect ? (
          <InputErrorWithLink
            as="a"
            href="https://drivers.portlmedia.com"
            data-test="errorMsg"
          >
            {commonProps.errors[id]}
          </InputErrorWithLink>
        ) : (
          <InputError data-test="errorMsg">{commonProps.errors[id]}</InputError>
        ))}
    </InputSectionStyled>
  );
};

export default InputSection;

import React from 'react';
import styled from 'styled-components';

import Auth0Icon from '../../Components/Icon/Auth0';
import HubspotIcon from '../../Components/Icon/Hubspot';

const ImageSection = styled.div`
    background-color: #1fb6ff;
    width: 30%;
    border-radius: 10px 0 0 10px;
`;

const DescriptionContainer = styled.div`
    width: 70%;
    text-align: left;
`;

const MemberSince = styled.div`
    color: #1fb6ff;
    font-weight: 700;
    background-color: #e8f0fe;
    width: 60%;
    margin: 15px auto;
    padding: 5px;
`;

const Name = styled.div`
    padding: 15px;
    font-size: 40px;
    font-weight: 600;
    color: #1fb6ff;
    
`;

const ImageContainer = styled.div`
    filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
`;

const Image = styled.div`
    clip-path: circle(92px at center);
    background: url('${(props) => props.image}');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 230px;
    
`;

const TabletInformation = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const DBInformation = styled.div`
    padding: 15px;
    text-align:right;
`;

const Key = styled.div`
    color: #043650;
    font-weight: 500;
    text-transform: uppercase;
`;

const Value = styled.div`
    font-weight: 300;
`;

const InfoSetStyled = styled.div`
    padding: 10px 15px;
    flex: 0 0 45%;
`;

const InfoSet = ({ keyName, val }) => (
  <InfoSetStyled>
    <Key>{keyName}</Key>
    <Value>{val}</Value>
  </InfoSetStyled>
);

const DriverCard = ({ driver = {}, tablet = {}, clientID }) => {
  console.info('DRIVER & TABLET: ', driver, tablet);
  const formatPhone = () => {
    if (driver && driver.phone) {
      return `${driver.phone.slice(0, 3)}-${driver.phone.slice(3, 6)}-${driver.phone.slice(6)}`;
    }
    return null;
  };
  const formatBrowser = () => {
    if (tablet && tablet.name) {
      return `Chrome${tablet.name.split('Chrome')[1].split('Safari')[0]}`;
    }
    return null;
  };

  const calculateStorage = (deviceStorage) => {
    if (deviceStorage && deviceStorage.quota) {
      const { quota, usage } = deviceStorage;
      return `${Math.floor(usage / quota) / 100}% used`;
    }
    return null;
  };

  const InfoSetArray = [
    { keyName: 'email', val: driver.email },
    { keyName: 'Phone', val: formatPhone() },
    { keyName: 'Driver Agreement', val: new Date(driver.agreed).toDateString() },
    { keyName: 'Browser Info', val: formatBrowser() },
    { keyName: 'Client ID', val: clientID },
    { keyName: 'IMEI', val: tablet.IMEI },
    { keyName: 'Web View Version', val: tablet.webviewVersion },
    { keyName: 'Commit Hash', val: tablet.commitHash },
    { keyName: 'Battery Level', val: `${tablet.batteryLevel}%` },
    { keyName: 'Connected to Charger', val: tablet.batteryCharging ? 'Yes' : 'No' },
    { keyName: 'Device Storage', val: calculateStorage(tablet.deviceStorage) },
  ];

  const convertDateToString = (created = '', agreed = '') => {
    // Agreed used for old accounts that do not have a created property
    const driverSince = created ? new Date(created) : new Date(agreed.replace(/["]+/g, ''));

    const dateOptions = {
      month: 'short',
      year: 'numeric',
    };
    const dates = driverSince.toLocaleDateString('en-US', dateOptions).split(' ');
    return `${dates[0]}. ${dates[1]}`;
  };

  return (
    <>
      <ImageSection>
        <ImageContainer><Image image={driver.profile} alt="driverimage" /></ImageContainer>
        <MemberSince>Member Since:</MemberSince>
        <MemberSince>{convertDateToString(driver.created, driver.agreed)}</MemberSince>
      </ImageSection>
      <DescriptionContainer>
        <Name>{driver.driverName && `${driver.driverName.first} ${driver.driverName.last}`}</Name>
        <TabletInformation>
          {InfoSetArray.map((item) => (
            <InfoSet
              keyName={item.keyName}
              val={item.val}
              key={item.keyName}
            />
          ))}
        </TabletInformation>
        <DBInformation>
          <HubspotIcon url={`https://app.hubspot.com/reports-dashboard/2867024/sales?globalSearchQuery=${driver.email}`} />
          <Auth0Icon url={`https://manage.auth0.com/dashboard/us/dev-2nvx8dom/users/auth0|${driver.email}`} />
        </DBInformation>
      </DescriptionContainer>
    </>
  );
};

export default React.memo(DriverCard);

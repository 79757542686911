import styled from 'styled-components';
import { ReactComponent as ShortcutMenuIcon } from './shortcutMenu.svg';
import { ICON_CSS_CONFIG } from '../../../Utilities/cssConfig';

const ShortcutMenuIconStyled = styled(ShortcutMenuIcon)`
    height: 34px;
    fill: ${({ selected }) => (selected ? ICON_CSS_CONFIG.selected : ICON_CSS_CONFIG.normal)};
`;

export default ShortcutMenuIconStyled;

import React from 'react';
import styled from 'styled-components';
import { Link, useLocation, useHistory } from 'react-router-dom';

import Button from '../../Components/Button';
import Spacing from '../../Components/Spacing';
import Loading from '../../Components/Loading';
// import Container from '../../Components/MainContentContainer'

import LogsStreamer from '../../Compositions/LogsStreamer';
import DriverCard from '../../Compositions/DriverCard';
import ActionCard from '../../Compositions/ActionCard';
import { saveLogsToText } from '../../Utilities/helper';
import { getDriverAndTabletInfo, getTabletLogs } from '../../Utilities/api';

// TODO: <Prompt> for pausing stream when streaming
// Unmounting component leaves even dangling, must fix before pushing.
// new logs don't scroll to bottom, need to fix
// TODO: Prompt cannot detect logout...

const CardContainer = styled.div`
  flex: 1 1 75%;
  margin: 15px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  min-height: 300px;
`;

const LogsContainer = styled.div`
  margin: 0 auto;
  width: 97%;
  overflow: scroll;
  border: 1px solid #d7d7d7;
  border-radius: 15px;
  padding-bottom: 30px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

const StreamMenu = styled.div`
  border-bottom: 1px solid #d7d7d7;
  width: 96%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  margin: 0 auto;
`;

const LogCount = styled.div`
  border-radius: 10px;
  display: flex;
  margin-right: 25px;
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-weight: 500;
  min-width: 125px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

const LogLabel = styled.div`
  background-color: #e8f0fe;
  padding: 12px;
  border-radius: 10px 0 0 10px;
`;

const Count = styled.div`
  margin: 0 auto;
  padding: 0 15px;
`;

const StreamTitle = styled.div`
  font-size: 16px;
  align-self: center;
  padding: 5px;
  font-weight: 600;
  border-bottom: 1px solid #d7d7d7;
  width: 97%;
  padding-top: 15px;
  margin: 0 auto;
  text-align: left;
`;

const DriverInfoWrapper = styled.div`
  display: flex;
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  font-weight: 300;
  width: 96%;
  color: red;
`;

const ReloadButton = ({ show }) => show && (
<>
  <Spacing />
  <Link to="/drivers">
    <Button type="small" show={show}>
      Return
    </Button>
  </Link>
</>
);

const errorHandling = ({ error }) => {
  const CLIENT_DISCONNECTED = 'Socket not found';
  let msg;
  switch (error.msg) {
    case CLIENT_DISCONNECTED:
      msg = 'Client Disconnected.';
      break;
    default:
      msg = `Unknown Error: ${JSON.stringify(error)}. `;
  }
  return msg;
};

const DriverAndTabletSection = ({ clientID, driver }) => {
  const [tabletInfo, setTabletInfo] = React.useState();
  const [showReload, setReload] = React.useState(false);
  const [errorMessage, setErrors] = React.useState(undefined);

  React.useEffect(() => {
    // load the driver information
    let isMounted = true;
    const timeout = setTimeout(() => setReload(true), 5 * 1000); // 5 seconds
    if (!driver) {
      isMounted = false;
      clearTimeout(timeout);
      return null;
    }

    getDriverAndTabletInfo(clientID).then((data) => {
      if (data.error && isMounted) {
        setErrors({
          field: 'DriverInfo',
          error: data.error.response && data.error.response.data,
          code: data.error.status,
        });
        setReload(true);
        return;
      }
      if (isMounted) setTabletInfo(data.tabletInfo);
    });

    return () => {
      isMounted = false;
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardContainer>
      {driver && tabletInfo ? (
        <DriverCard driver={driver} tablet={tabletInfo} clientID={clientID} />
      ) : (
        <Loading paddingTop="150">
          <ReloadButton show={showReload} />
          {errorMessage && (
            <ErrorMessage>
              <Spacing height="15" />
              <p>{errorHandling(errorMessage)}</p>
              <Spacing height="15" />
            </ErrorMessage>
          )}
        </Loading>
      )}
    </CardContainer>
  );
};

const ButtonWrapper = styled.div`
  margin-right: 25px;
`;

const LogRequestInput = styled.input`
  border: none;
`;

const Device = ({ clientId }) => {
  const [errors, setErrors] = React.useState({
    field: '',
    error: '',
  });

  const [indexedDBLogs, setIndexedDBLogs] = React.useState({
    logs: [],
    requesting: false,
  });
  const [lineInput, setLineInput] = React.useState('');

  const { state } = useLocation();
  const history = useHistory();

  const driver = state && state.driver ? state.driver : {};

  if (Object.keys(driver).length === 0) {
    history.push('/devices');
  }

  const handleLineInput = (e) => {
    setLineInput(e.target.value);
  };

  const handleRequestLogs = async () => {
    setIndexedDBLogs({ logs: [], requesting: true });

    if (Number.isNaN(lineInput) || lineInput <= 0 || lineInput >= 10000) {
      setLineInput(0);
      return setIndexedDBLogs({ logs: [], requesting: false });
    }

    const indexedLogs = await getTabletLogs(clientId, parseInt(lineInput, 10)); // TODO: block out
    if (indexedDBLogs.error) {
      setErrors({
        field: 'logRequest',
        error: indexedDBLogs.error,
      });
      console.log('Error On IndexedDB Logs: ', indexedDBLogs.error);
      return false;
    }

    let logBlob = `Log Retrieval Time: ${new Date().toLocaleDateString()}\nTotal Log Count: ${
      indexedLogs.totalCount
    } entries \n\n`;
    let parsedEntry;
    if (indexedLogs.logs) {
      parsedEntry = indexedLogs.logs.map(
        (log) => `[${new Date(log.time).toLocaleString()}][ID ${log.id}]: ${
          log.content
        }`,
      );
    }

    logBlob += parsedEntry.join('\n');

    return setIndexedDBLogs({ logs: logBlob, requesting: false });
  };

  return (
    <>
      <DriverInfoWrapper>
        <DriverAndTabletSection driver={driver} clientID={clientId} />
        <ActionCard clientID={clientId} />
      </DriverInfoWrapper>
      <Spacing />
      <LogsStreamer clientID={clientId} />
      <Spacing />
      <LogsContainer>
        <StreamTitle>Get Logs From Tablet</StreamTitle>
        <StreamMenu>
          <LogCount>
            <LogLabel>Lines</LogLabel>
            <Count>
              {' '}
              <LogRequestInput
                value={lineInput}
                onChange={handleLineInput}
              />
              {' '}
            </Count>
          </LogCount>
          <ButtonWrapper>
            <Button type="small" onClick={handleRequestLogs}>
              Request Logs
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              type="small"
              disabled={indexedDBLogs.logs.length === 0}
              onClick={() => saveLogsToText(indexedDBLogs.logs, 'request.txt')}
            >
              Download
            </Button>
          </ButtonWrapper>
        </StreamMenu>
        {indexedDBLogs.logs.length === 0
          ? indexedDBLogs.requesting && (
          <>
            <Loading paddingTop="30" />
            {errors.field === 'logRequest' && (
            <ErrorMessage>
              {' '}
              {errors.error}
            </ErrorMessage>
            )}
          </>
          )
          : ''}
      </LogsContainer>
    </>
  );
};

export default Device;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  updateDriver,
  reloadTablets,
  downloadLabel,
} from '../../Utilities/api';
import {
  DeleteModal,
  GenerateShippingModal,
  EditModal,
  OverrideUrlModal,
  GenerateReturnLabelModal,
  FailDriverModal,
  ReplaceDriverModal,
  AddDistanceModal,
  GenerateExchangeShippingModal,
} from './modals';
import {
  Dot, DotsContainer, PopupContainer, PopupItem,
} from './style';
import Tooltip from '../../Components/Tooltip';

const PopupMenu = ({ items }) => (
  <PopupContainer>
    {items.map(({ text, action }) => (
      <PopupItem key={text} onClick={action}>
        {text}
      </PopupItem>
    ))}
  </PopupContainer>
);

const Dots = ({ driver, setDrivers, allowedActions }) => {
  const [openModal, setOpenModal] = useState('');
  const history = useHistory();

  const changeDriverLeadStatus = async (leadStatus) => {
    const { error } = await updateDriver(driver.email, { leadStatus });
    if (error) return console.log(error);
    return setDrivers((prevState) => [
      ...prevState.filter((someDriver) => someDriver.email !== driver.email),
    ]);
  };

  const changeDriverToDepositRequired = () => changeDriverLeadStatus('DepositRequired');

  const changeDriverToChurned = () => changeDriverLeadStatus('Churned');

  const changeDriverToQualified = () => changeDriverLeadStatus('Qualified');

  const handleReloadAllTablets = async (tabletIMEI) => {
    await reloadTablets(tabletIMEI, false);
    window.location.reload();
  };

  const tabletIMEI = driver.tablets && driver.tablets.length > 0
    ? driver.tablets[driver.tablets.length - 1].IMEI
    : null;

  const outgoingTrackPin = driver.trackingPin
      || (driver.shipments
        && driver.shipments.length > 0
        && driver.shipments[0].type === 'outgoing'
        && driver.shipments[0].trackingPin);

  const incomingTrackPin = driver.returnTrackingPin
      || (driver.shipments
        && driver.shipments.length > 0
        && driver.shipments[0].type === 'incoming'
        && driver.shipments[0].trackingPin);

  const popupMenuItems = [
    { text: 'Edit', action: () => setOpenModal('EditDriver') },
    { text: 'Move to Qualified', action: changeDriverToQualified },
    {
      text: 'Reload Tablet',
      action: () => handleReloadAllTablets(tabletIMEI),
      isAllowed: tabletIMEI,
    },
    {
      text: 'Live View',
      action: () => history.push(`/device/${tabletIMEI}`, { driver }),
    },
    {
      text: 'Generate Label',
      action: () => setOpenModal('GenerateLabel'),
      isAllowed: !outgoingTrackPin,
    },
    {
      text: 'Generate Return Label',
      action: () => setOpenModal('GenerateReturnLabel'),
      isAllowed: !incomingTrackPin && driver.country !== 'US',
    },
    {
      text: 'Track →',
      action: () => window.open(
        `https://www.canadapost.ca/track-reperage/en#/details/${outgoingTrackPin}`,
      ),
      isAllowed: outgoingTrackPin,
    },
    {
      text: 'Track Return →',
      action: () => window.open(
        `https://www.canadapost.ca/track-reperage/en#/details/${incomingTrackPin}`,
      ),
      isAllowed: incomingTrackPin,
    },
    {
      text: 'Check Activity',
      action: () => history.push('/activity', { driver }),
    },
    {
      text: 'Move to Churned',
      action: changeDriverToChurned,
      isAllowed: incomingTrackPin,
    },
    { text: 'Override URL', action: () => setOpenModal('OverrideUrl') },
    {
      text: 'Move to Unqualified',
      action: () => setOpenModal('MoveToUnqualified'),
      isAllowed: !outgoingTrackPin,
    },
    { text: 'Delete', action: () => setOpenModal('DeleteDriver') },
    { text: 'Replace or Logout', action: () => setOpenModal('ReplaceDriver') },
    { text: 'Accept application', action: changeDriverToDepositRequired },
    {
      text: 'Download Shipping Label',
      action: () => downloadLabel(driver.shipments[0], driver.driverName.first),
      isAllowed: outgoingTrackPin,
    },
    {
      text: 'Add Distance',
      action: () => setOpenModal('AddDistance'),
    },
    {
      text: 'Generate Exchange Label',
      action: () => setOpenModal('ExchangeLabel'),
    },
    {
      text: 'Generate Exchange Label W/ Return',
      action: () => setOpenModal('ExchangeLabelAndReturn'),
    },
  ];

  // select with options to allow
  const validMenuItems = popupMenuItems.filter((item) => {
    if ('isAllowed' in item) {
      return allowedActions.includes(item.text) && item.isAllowed;
    }
    return allowedActions.includes(item.text);
  });

  return (
    <>
      <ReplaceDriverModal
        isOpen={openModal === 'ReplaceDriver'}
        onClose={() => setOpenModal(undefined)}
        imei={tabletIMEI}
      />
      <OverrideUrlModal
        isOpen={openModal === 'OverrideUrl'}
        onClose={() => setOpenModal(undefined)}
        imei={tabletIMEI}
      />
      <GenerateShippingModal
        driver={driver}
        isOpen={openModal === 'GenerateLabel'}
        onClose={() => setOpenModal(undefined)}
        setDrivers={setDrivers}
      />
      <GenerateReturnLabelModal
        driver={driver}
        isOpen={openModal === 'GenerateReturnLabel'}
        onClose={() => setOpenModal(undefined)}
        setDrivers={setDrivers}
      />
      <DeleteModal
        driver={driver}
        isOpen={openModal === 'DeleteDriver'}
        onClose={() => setOpenModal(undefined)}
        setDrivers={setDrivers}
      />
      <EditModal
        driver={driver}
        isOpen={openModal === 'EditDriver'}
        onClose={() => setOpenModal(undefined)}
        setDrivers={setDrivers}
      />
      <AddDistanceModal
        driver={driver}
        isOpen={openModal === 'AddDistance'}
        onClose={() => setOpenModal(undefined)}
      />
      <FailDriverModal
        driver={driver}
        isOpen={openModal === 'MoveToUnqualified'}
        onClose={() => setOpenModal(undefined)}
        setDrivers={setDrivers}
      />
      <GenerateExchangeShippingModal
        driver={driver}
        isOpen={openModal === 'ExchangeLabel'}
        onClose={() => setOpenModal(undefined)}
        setDrivers={setDrivers}
      />
      <GenerateExchangeShippingModal
        driver={driver}
        isOpen={openModal === 'ExchangeLabelAndReturn'}
        onClose={() => setOpenModal(undefined)}
        setDrivers={setDrivers}
        returnLabel
      />
      <Tooltip
          // onVisibilityChange={toggleMenuSelected}
        placement="bottom-end"
        trigger="click"
        tooltip={<PopupMenu items={validMenuItems} />}
      >
        <DotsContainer>
          <Dot />
          <Dot />
          <Dot />
        </DotsContainer>
      </Tooltip>
    </>
  );
};

export default Dots;

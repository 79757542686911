import React, { useContext } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

// API
import { loginPlanner } from '../../Utilities/api';

// Context
import { UserContext } from '../../context';

// Compositions
import AuthTemplate from '../../Compositions/AuthTemplate';
import InputSection from '../../Components/InputSection';
import Heading from '../../Components/Heading';
import Button from '../../Components/Button';

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Please enter a valid email.')
    .required('Please enter a valid email.'),
  password: Yup.string().required('Please enter your password.'),
});

const LoginWrapper = styled.div`
    padding: 60px 70px 0 80px;
`;

const Note = styled.div`
    padding-top: 20px;
    color: #757575;
    text-align: left;
`;

const StyledLink = styled(Link)`
    font-weight: 500;
    color: #1fb6ff !important;
`;

const generateForm = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  const commonProps = {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
  };
  return (
    <form onSubmit={handleSubmit}>
      <InputSection placeholder="Email Address" id="email" commonProps={commonProps} />
      <InputSection
        placeholder="Password"
        id="password"
        commonProps={commonProps}
        type="password"
      />
      {/* <ForgotPassword to={window.location.pathname.replace("/login", "/forgotPassword")}>
      Forgot password?
      </ForgotPassword> */}

      <Button type="submit" disabled={isSubmitting} fontWeight="500" fontSize="21px">
        Log In
      </Button>
      <Note>
        {"Don't have an account yet? "}
        <StyledLink to="/">Contact us.</StyledLink>
      </Note>
    </form>
  );
};

const Login = () => {
  const { setUser } = useContext(UserContext);

  const initialValues = { email: '', password: '' };

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    // Must lowercase because auth0 forces lowercase but dynamo stores as-is.
    const planner = { email: values.email.toLowerCase().trim(), password: values.password };

    setSubmitting(true);

    const { error } = await loginPlanner(planner);

    setSubmitting(false);

    if (error && error.response && error.response.status === 403) {
      return setFieldError('email', 'Email or password is incorrect. Please try again.');
    }

    if (error && error.response && error.response.status === 429) {
      return setFieldError('email', 'Too many requests have been made, please wait and try again.');
    }

    if (error) {
      return setFieldError('email', 'Undefined error, Please contact Portl.');
    }

    return setUser(undefined);
  };

  return (
    <AuthTemplate>
      <LoginWrapper>
        <Heading title="Welcome to Insights!" subtitle="Log in" />
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidationSchema}
        >
          {generateForm}
        </Formik>
      </LoginWrapper>
    </AuthTemplate>
  );
};

export default Login;

import io from 'socket.io-client';
import { serverUrl } from '../config';

const websocketServer = serverUrl.indexOf('localhost') >= 0 ? 'http://localhost:5000' : `${serverUrl}:5000`;
let socket;

export const initConnection = () => {
  if (socket) return socket;
  // console.log('initiating socket connection...');
  socket = io(websocketServer, { forceNew: true, transports: ['websocket'] });
  return socket;
};

export const enableStream = (start, clientID, updateState) => {
  updateState(start);
  if (start) {
    // getTimeLapse();
    socket.emit('adminRequest', clientID);
  } else {
    socket.emit('adminDisconnecting', clientID);
  }
};

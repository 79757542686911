import React from 'react';
import styled from 'styled-components';

// Component
import PortlLogo from '../PortlLogo';

const Background = styled.div`
    background-color: #169aff;
    width: 100vw;
    height: 200px;
    text-align: start;
    padding: 26px 0 0 80px;
`;

const Footer = () => (
  <Background>
    <PortlLogo islight />
  </Background>
);

export default Footer;

import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createZendeskTicket } from '../../Utilities/api';
import { UserContext } from '../../context';

// Compositions
import InternalInput from '../../Components/InternalInput';
import Button from '../../Components/Button';

const ValidationSchema = Yup.object().shape({
  subject: Yup.string().required('Please enter a subject.'),
  message: Yup.string().required('Please enter a message.'),
});

const FormContainer = styled.form`
    margin: 0 auto;
    width: 60%;
    max-width: 700px;
    padding-top: 100px;
`;
const ButtonContainer = styled.div`
    width: 150px;
    padding-top: 20px;
`;
const ErrorMsgContainer = styled.div`
    padding-left: 24px;
    padding-top: 15px;
    text-align: left;
`;
const ErrorMsgRed = styled.div`
    font-size: 16px;
    color: #ca0307;
    line-height: 2.5;
    font-weight: 500;
`;
const MsgBlack = styled.div`
    font-size: 14px;
    padding-bottom: 10px;
    line-height: 1.57;
`;
const SuccessSection = styled.div`
    text-align: left;
    border-radius: 10px;
    border: solid 1px #d6d6d6;
    background-color: #edf9f8;
    margin-bottom: 15px;
    padding: 15px 24px;
`;
const SuccessMsgGreen = styled.div`
    font-size: 16px;
    color: #13ba71;
    line-height: 2.5;
    font-weight: 500;
`;

const ContactUs = () => {
  const { user } = React.useContext(UserContext);
  const [status, setStatus] = React.useState();
  const initialValues = { subject: '', message: '' };
  const generateForm = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  }) => {
    const commonProps = {
      values,
      errors,
      handleChange,
      handleBlur,
      touched,
    };
    return (
      <FormContainer onSubmit={handleSubmit}>
        {(status === 'success')
          ? (
            <>
              <SuccessSection>
                <SuccessMsgGreen>Sent!</SuccessMsgGreen>
                <MsgBlack>Thanks for the message.</MsgBlack>
                <MsgBlack>
                  A ticket has been created and a member of
                  our service team will get back to you shortly!
                </MsgBlack>
              </SuccessSection>
              <ButtonContainer>
                <Button type="button" fontSize="14px" color="#169aff" borderColor="#169aff" onClick={() => window.location.reload()}>
                  Create new ticket
                </Button>
              </ButtonContainer>
            </>
          )
          : (
            <>
              <InternalInput placeholder="Hello" id="subject" commonProps={commonProps} label="Subject" />
              <InternalInput
                placeholder="Please enter your message here."
                id="message"
                commonProps={commonProps}
                label="Message"
              />
              {(status === 'error')
                && (
                  <ErrorMsgContainer>
                    <ErrorMsgRed>
                      Oops. There was a problem sending your message.
                    </ErrorMsgRed>
                    <MsgBlack>Please try again or refresh your browser.</MsgBlack>
                  </ErrorMsgContainer>
                )}
              <ButtonContainer>
                <Button color="#169aff" borderColor="#169aff" type="submit" disabled={isSubmitting} fontSize="14px">
                  {(status === 'error')
                    ? 'Retry'
                    : 'Submit'}
                </Button>
              </ButtonContainer>
            </>
          )}
      </FormContainer>
    );
  };

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);

    const result = await createZendeskTicket(user.email, `Insights Message from ${user.email} - ${values.subject}`, values.message);

    setSubmitting(false);
    if (result.error) {
      setStatus('error');
      setFieldError('subject', 'Issue submitting ticket.'); // Setting error field so that input turns red
      setFieldError('message', 'Issue submitting ticket.');
      console.log('createZendeskTicket() error', result.error);
      return;
    }
    setStatus('success');
  };
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
    >
      {generateForm}
    </Formik>
  );
};

export default ContactUs;

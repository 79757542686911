import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import styled from 'styled-components';

// Utils
import { BLOCK_USERS_FROM_STAGING } from '../../config';
import { email } from '../../Utilities/validation';
import { loginAdmin } from '../../Utilities/api';
// import { renderRedirect } from '../../Utilities/helper';

// Components
import StyledLink from '../../Components/StyledLink';
import Button from '../../Components/Button';
import InputSection from '../../Components/InputSection';
import Spacing from '../../Components/Spacing';
import BackgroundGradient from '../../Components/BackgroundGradient';

// Compositions
import AuthHeader from '../../Compositions/AuthHeader';

// Context
import { UserContext } from '../../context';

const ValidationSchema = Yup.object().shape({
  email,
  password: Yup.string().required('Please enter your password.'),
});

const loginFieldErrors = {
  400: 'An error occurred. Please contact admin for help.',
  404: 'Unable to locate user in database. Please contact an admin.',
  403: 'Forbidden Request.',
  401: 'Admin is not approved. Please contact admin for help',
  429: 'Too many login attempts. Please wait and try again.',
};

const Note = styled.div`
  padding-top: 20px;
  color: #757575;
`;

const ButtonContainer = styled.div`
  padding-top: 20px;
  
`;

const StyledForm = styled.form`
    max-width:60%;
    margin: 0 auto;
    
`;

const Input = ({
  placeholder, id, commonProps, type,
}) => (
  <>
    <InputSection placeholder={placeholder} id={id} commonProps={commonProps} type={type} />
    <Spacing height="15px" />
  </>
);

const generateForm = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  const commonProps = {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
  };
  return (
    <StyledForm onSubmit={handleSubmit}>
      <Input placeholder="Email Address" id="email" commonProps={commonProps} />
      <Input
        placeholder="Password"
        id="password"
        commonProps={commonProps}
        type="password"
      />
      <Spacing type="small" />
      <ButtonContainer>
        <Button type="submit" disabled={isSubmitting}>
          Log In
        </Button>
      </ButtonContainer>
      <Note>
        {"Don't have an account yet? "}
        <StyledLink to="/signup">Sign up.</StyledLink>
      </Note>
    </StyledForm>
  );
};

const AdminLogin = () => {
  const { setUser } = React.useContext(UserContext);

  const initialValues = { email: '', password: '' };

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    // Must lowercase because auth0 forces lowercase but dynamo stores as-is.
    const admin = {
      email: values.email.toLowerCase().trim(),
      password: values.password,
    };

    if (!admin.email.includes('portlmedia.com')) {
      // block non-portlmedia users to sign up to prod
      return setFieldError('email', BLOCK_USERS_FROM_STAGING);
    }

    setSubmitting(true);
    const loginResponse = await loginAdmin(admin);

    setSubmitting(false);

    if (loginResponse.error) {
      const errorStatus = loginFieldErrors[loginResponse.error.response.status];
      const errorMsg = errorStatus || `Undefined Error, Please contact admin. Code: ${loginResponse.error.response.status}`;
      return setFieldError('email', errorMsg);
    }

    return setUser(undefined);
  };

  return (
    <BackgroundGradient>
      <AuthHeader />
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={ValidationSchema}
      >
        {generateForm}
      </Formik>
    </BackgroundGradient>
  );
};
export default AdminLogin;

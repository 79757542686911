import styled, { css } from 'styled-components';
import { ReactComponent as Add } from './add.svg';
import { ReactComponent as ChangeIcon } from './changeIcon.svg';
import { ReactComponent as Deactivate } from './deactivate.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as ManageCampaign } from './manageCampaign.svg';
import { ReactComponent as Navigate } from './navigate.svg';
import { ReactComponent as ImpersonateIcon } from './impersonate.svg';
import { ReactComponent as PlannerIcon } from './planner.svg';
import { ReactComponent as BrandIcon } from './brand.svg';
import { ReactComponent as CreativeIcon } from './creative.svg';

const IconGroupCSS = css`
    display: block;
    margin: auto;
    height: 24px;
    width: 24px;
    stroke-width: 3;
    ${(props) => (props.size === 'medium' ? 'height: 50px; width: 50px; padding-bottom: 10px;' : ' height: 24px; width: 24px;')}
    fill: ${(props) => (props.fillcolor ? props.fillcolor : '#757575')};
`;

export const AddIconStyled = styled(Add)`
 ${IconGroupCSS}
`;

export const DeactivateStyled = styled(Deactivate)`
 ${IconGroupCSS}
`;
export const ChangeIconStyled = styled(ChangeIcon)`
 ${IconGroupCSS}
`;
export const DeleteIconStyled = styled(Delete)`
 ${IconGroupCSS}
`;

export const ManageCampaignIconStyled = styled(ManageCampaign)`
 ${IconGroupCSS}
`;
export const NavigateIconStyled = styled(Navigate)`
 ${IconGroupCSS}
`;

export const ImpersonateIconStyled = styled(ImpersonateIcon)`
 ${IconGroupCSS}
`;

export const PlannerIconStyled = styled(PlannerIcon)`
${IconGroupCSS}
`;

export const BrandIconStyled = styled(BrandIcon)`
${IconGroupCSS}
`;
export const CreativeIconStyled = styled(CreativeIcon)`
${IconGroupCSS}
`;

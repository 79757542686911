import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import styled from 'styled-components';

// User Context
import { UserContext } from '../../context';

// Components
import Button from '../../Components/Button';
import ArrowNavigateIcon from '../../Components/Icon/ArrowNavigate';
import Logout from '../../Components/Icon/Logout';

// Compositions
import UploadImageModel from '../../Compositions/UploadImageModel';

// Api
import {
  updatePlanner,
  changePassword,
  changeProfilePicture,
  getPlannerCompany,
} from '../../Utilities/api';

const SUCCESS = 'Success';
const FAIL = 'Fail';

const SettingsWrapper = styled.div`
    max-width: 1025px;
    min-width: 871px;
    height: 809px;
    width: 90%;
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    display: grid;
    grid-template-rows: 68.5px 1fr;
    grid-template-columns: 323.5px 1fr;
    color: #222222;
    text-align: left;
`;

const InfoWrapperStyled = styled.div`
    font-weight: 600;
    padding: 16px 25px;
    border-right: solid 1px #dedede;
`;

const CompanyStyled = styled.div`
    font-size: 12px;
    font-weight: 300;
    padding-top: 3px;
`;

const InfoWrapper = ({ name, company }) => (
  <InfoWrapperStyled>
    {name}
    <CompanyStyled>
      {company}
    </CompanyStyled>
  </InfoWrapperStyled>
);

const OptionsWrapper = styled.div`
    border-right: solid 1px #dedede;
    text-align: left;
`;

const OptionItem = styled.div`
    padding: 17px 22px;
    cursor: pointer;
    border-left: solid 3px #ffffff;
    border-top: solid 1px #dedede;
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.logout ? 'end' : 'space-between')};
    ${(props) => props.active
        && `border-left-color: #169aff;
        background-color: #e8f0fe;`
}
    :hover {
        border-left-color: #169aff;
        background-color: #F6F6FF;
    }
`;

const ArrowNavigateIconStyled = styled(ArrowNavigateIcon)`
    width: 24px;
    height: 24px;
    padding: 0;
    fill: #222222;
`;

const optionsList = [
  'Username',
  'Login email',
  'Password',
  'Change profile picture',
];

const Options = ({ onClick, active, setLogoutModalOpen }) => (
  <OptionsWrapper>
    {optionsList.map((option) => (
      <OptionItem
        onClick={() => onClick(option)}
        active={active === option}
        key={`options${option}`}
      >
        {option}
        <ArrowNavigateIconStyled />
      </OptionItem>
    ))}
    <OptionItem logout onClick={() => setLogoutModalOpen(true)}>
      <Logout />
      {' '}
      Log out
    </OptionItem>
  </OptionsWrapper>
);

const SubtitleWrapper = styled.div`
    padding: 25px;
    font-weight: 600;
`;

const Subtitle = ({ active }) => (
  <SubtitleWrapper>
    Change
    {' '}
    {active.toLowerCase()}
  </SubtitleWrapper>
);

const Content = styled.div`
    padding: 10px 25px;
    font-weight: 500;
    border-top: solid 1px #dedede;
`;

const InputContainer = styled.div`
    border-bottom: solid 1px #dedede;
    padding: 15px 0 5px;
`;

const Input = styled.input`
    border: none;
    font-size: 14px;
    padding: 10px 0;
    width: 100%;
    background-color: ${(props) => {
    if (props.error && props.touched) return '#fae5ec';
    return props.disabled ? '#f1f1f1' : '#ffffff';
  }};

    &:focus {
        outline: none;
    }
`;

const ErrorMsg = styled.label`
    padding-top: 4px;
    font-size: 11px;
    color: #d80f4d;
    margin: 0;
`;

const InputField = ({
  placeholder = '', id, commonProps, type = 'text', label, disabled,
}) => (
  <InputContainer>
    {label}
    {' '}
    <br />
    <Input
      id={id}
      placeholder={placeholder}
      onChange={commonProps.handleChange}
      onBlur={commonProps.handleBlur}
      value={commonProps.values[id]}
      error={commonProps.errors[id]}
      touched={commonProps.touched[id]}
      type={type}
      disabled={disabled}
    />
    {commonProps.errors[id] && commonProps.touched[id]
                && (
                <ErrorMsg id={id}>
                  {commonProps.errors[id]}
                </ErrorMsg>
                )}
  </InputContainer>
);

const ButtonWrapper = styled.div`
    width: 143px;
    height: 43px;
    margin-top: 25px;

    > button {
        background-color: #169aff;
        border: none;
        font-size: 14px;
        font-weight: 500;
        height: 43px;
    }
`;

const ButtonStyled = ({ isSubmitting }) => (
  <ButtonWrapper>
    <Button type="submit" disabled={isSubmitting}>
      Save changes
    </Button>
  </ButtonWrapper>
);

const Username = ({ user, setUser, setAlert }) => {
  const initialValues = { username: user.name };

  const generateForm = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  }) => {
    const commonProps = {
      values,
      errors,
      handleChange,
      handleBlur,
      touched,
    };
    return (
      <form onSubmit={handleSubmit}>
        <InputField id="username" commonProps={commonProps} label="Username" />
        <ButtonStyled isSubmitting={isSubmitting} />
      </form>
    );
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (values.username === user.name) return null;
    const name = values.username.trim();

    const response = await updatePlanner({ name });
    setSubmitting(false);

    if (response.error) return setAlert({ status: FAIL, isOpen: true });

    setUser({ ...user, name: values.username });

    return setAlert({ status: SUCCESS, isOpen: true });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {generateForm}
    </Formik>
  );
};

const Email = ({ user, setUser, setAlert }) => {
  const initialValues = { email: user.email };

  const ValidationSchema = Yup.object({
    newEmail: Yup.string()
      .trim()
      .email('Please enter a valid email.')
      .required('Please enter a valid email.'),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref('newEmail')], 'Email must match')
      .required('Email confirm is required'),
  });

  const generateForm = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  }) => {
    const commonProps = {
      values,
      errors,
      handleChange,
      handleBlur,
      touched,
    };
    return (
      <form onSubmit={handleSubmit}>
        <InputField id="email" commonProps={commonProps} label="Current login email" disabled />
        <InputField id="newEmail" commonProps={commonProps} label="New login email" placeholder="New email" />
        <InputField id="confirmEmail" commonProps={commonProps} label="Confirm login email" placeholder="Confirm email" />
        <ButtonStyled isSubmitting={isSubmitting} />
      </form>
    );
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const email = values.newEmail.trim();

    const response = await updatePlanner({ email });
    setSubmitting(false);

    if (response.error) return setAlert({ status: FAIL, isOpen: true });

    setUser({ ...user, email });
    return setAlert({ status: SUCCESS, isOpen: true });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
    >
      {generateForm}
    </Formik>
  );
};

const Password = ({ setAlert }) => {
  const initialValues = { password: '', newPassword: '', confirmPassword: '' };

  const ValidationSchema = Yup.object({
    password: Yup.string().required('Please enter your current password.'),
    newPassword: Yup.string()
      .matches(/[a-z]/, 'Password must have at least one lowercase letter.')
      .matches(/[A-Z]/, 'Password must have at least one uppercase letter.')
      .matches(/.*[0-9].*/, 'Password must have at least one number.')
      .min(6, 'Password must have at least 6 characters.')
      .required('Please enter a valid password.'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Password must match.')
      .required('Password confirm is required.'),
  });

  const generateForm = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  }) => {
    const commonProps = {
      values,
      errors,
      handleChange,
      handleBlur,
      touched,
    };
    return (
      <form onSubmit={handleSubmit}>
        <InputField id="password" commonProps={commonProps} label="Type current password" placeholder="Current password" type="password" />
        <InputField id="newPassword" commonProps={commonProps} label="Type new password" placeholder="New password" type="password" />
        <InputField id="confirmPassword" commonProps={commonProps} label="Confirm new password" placeholder="Confirm password" type="password" />
        <ButtonStyled isSubmitting={isSubmitting} />
      </form>
    );
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const response = await changePassword(values.password, values.newPassword);
    setSubmitting(false);

    if (response.error) return setAlert({ status: FAIL, isOpen: true });

    return setAlert({ status: SUCCESS, isOpen: true });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={ValidationSchema}
      initialValues={initialValues}
    >
      {generateForm}
    </Formik>
  );
};

const Image = styled.div`
    margin-top: 10px;
    width: 85px;
    height: 85px;
    background: url('${({ src }) => src}') center center no-repeat;
    background-size: cover;
`;

const ImageWrapper = styled.div`
    margin: auto;
    width: fit-content;
`;

const LinkStyled = styled.p`
    font-weight: 500;
    color: #169aff;
    cursor: pointer;
    margin-top: 25px;
`;

const ProfilePicture = ({ user, setUser, setAlert }) => {
  const [openModal, setOpenModel] = React.useState(false);
  const [currentPic, setCurrentPic] = React.useState(user.photo);
  const [creative, setCreative] = React.useState({
    url: undefined,
    blob: undefined,
  });

  const handleCloseModel = () => setOpenModel(false);

  const handleSubmit = async () => {
    const response = await changeProfilePicture(creative.blob);

    if (response.error) return setAlert({ status: FAIL, isOpen: true });

    setUser({ ...user, photo: creative.url });
    setAlert({ status: SUCCESS, isOpen: true });
    setCurrentPic(creative.url);
    return handleCloseModel();
  };

  return (
    <>
      <Image src={currentPic} />
      <LinkStyled onClick={() => setOpenModel(true)}>
        Change profile picture
      </LinkStyled>
      <UploadImageModel
        onCloseModel={handleCloseModel}
        openModal={openModal}
        creative={creative}
        setCreative={setCreative}
        onSubmit={handleSubmit}
      >
        <ImageWrapper>
          <Image src={creative.url} />
        </ImageWrapper>
      </UploadImageModel>
    </>
  );
};

const AlertStyled = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 83px;
    background-color: ${(props) => (props.showAlert.status === SUCCESS ? '#169aff' : '#ca0307')};
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
    ${(props) => props.showAlert.isOpen
        && `
        visibility: visible;
        opacity: 1;
        `
};
`;

const AlertMessage = styled.div`
    color: #ffffff;
    padding: 32px;
`;

const Alert = ({ alert }) => (
  <AlertStyled showAlert={alert}>
    <AlertMessage>
      {alert.status === SUCCESS
        ? 'Your settings have been saved.'
        : 'There was an error changing your settings. Please try again.'}
    </AlertMessage>
  </AlertStyled>
);

const Settings = ({ setLogoutModalOpen }) => {
  const { user, setUser } = React.useContext(UserContext);
  const [active, setActive] = React.useState('Username');
  const [alert, setAlert] = React.useState({ state: null, isOpen: false });

  const handleOnClick = (opt) => {
    setActive(opt);
  };

  const getContent = () => {
    switch (active) {
      case 'Login email':
        return <Email user={user} setUser={setUser} setAlert={setAlert} />;
      case 'Password':
        return <Password setAlert={setAlert} />;
      case 'Change profile picture':
        return <ProfilePicture user={user} setUser={setUser} setAlert={setAlert} />;
      default:
        return <Username user={user} setUser={setUser} setAlert={setAlert} />;
    }
  };

  React.useEffect(() => {
    const getInfo = async () => {
      const response = await getPlannerCompany();
      if (response.error) return;

      setUser({ ...user, company: response.length > 0 && response[0].name });
    };

    getInfo();
    // eslint-disable-next-line
    }, []);

  React.useEffect(() => {
    let isMounted = true;
    let timeOut;

    if (isMounted) {
      timeOut = setTimeout(() => {
        setAlert({ ...alert, isOpen: false });
      }, 3000);
    }

    return () => {
      clearTimeout(timeOut);
      isMounted = false;
    };
    // eslint-disable-next-line
    }, [alert]);

  return (
    <SettingsWrapper>
      <InfoWrapper name={user.name} company={user.company} />
      <Subtitle active={active} />
      <Options onClick={handleOnClick} active={active} setLogoutModalOpen={setLogoutModalOpen} />
      <Content>
        {getContent()}
      </Content>
      <Alert alert={alert} />
    </SettingsWrapper>
  );
};

export default Settings;

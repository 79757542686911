import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';

// Dashboard Context
import { DashboardContext, UserContext } from '../../context';

// Component
import Dropdown from '../../Components/Dropdown';

// Api
import { getInsightBrands, createZendeskTicket } from '../../Utilities/api';

const MenuSelectionStyled = styled.div`
  display: grid;
  grid-template-columns: 246px minmax(200px, 246px) minmax(304px, 374px);
  grid-gap: 17.5px;
  height: 32px;
`;

const MenuSelection = ({ hideCreative }) => {
  const {
    listBrands,
    setListBrands,
    campaign,
    setCampaign,
    brand,
    setBrand,
    creative,
    setCreative,
  } = useContext(DashboardContext);
  const { user } = useContext(UserContext);

  const [listCampaigns, setListCampaigns] = useState([]);
  const [listCreatives, setListCreatives] = useState([]);

  const handleBrandChange = (selected) => {
    setBrand(selected);
  };

  const handleCampaignChange = (selected) => {
    setCampaign(selected);
  };

  const handleCreativeChange = (selected) => {
    setCreative(selected);
  };

  useEffect(() => {
    let isMounted = true;

    const getInfo = async () => {
      if (listBrands && listBrands.length > 0) return;
      const response = await getInsightBrands();

      if (response.error) {
        if (response.error.response.status !== 401) {
          createZendeskTicket(
            user.email,
            'Error fetching information on MenuSelection Component - Insights',
            `Error fetching brands information - ${response.error}`,
          );
        }
        setBrand('Error');
        return;
      }

      const brands = [];
      response.forEach((brandElem) => {
        if (
          (brandElem.type === 'Brand' || brandElem.type === 'Direct Brand')
          && brandElem.campaigns
        ) {
          brands.push({
            name: brandElem.name,
            campaigns: brandElem.campaigns,
          });
        }
      });

      if (isMounted) {
        setListBrands(brands);
        if (brands.length > 0) setBrand({ value: brands[0], label: brands[0].name });
      }
    };

    getInfo();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const campaigns = [];

    listBrands.forEach((brandItem) => {
      if (brand.value && brandItem.name !== brand.value.name) return;

      brandItem.campaigns.forEach(
        ({
          name, creatives, startDate, endDate, goal, impressions,
        }) => {
          if (!creatives) return;

          campaigns.push({
            name,
            creatives,
            startDate: moment(startDate),
            endDate: moment(endDate),
            impressions,
            goal,
          });
        },
      );
    });

    setListCampaigns(campaigns);
    if (campaigns[0]) setCampaign({ value: campaigns[0], label: campaigns[0].name });

    // eslint-disable-next-line
  }, [brand]);

  useEffect(() => {
    const creatives = [];

    listCampaigns.forEach((campaignItem) => {
      if (campaign.value && campaignItem.name !== campaign.value.name) return;

      campaignItem.creatives.forEach(({ name, creativeId }) => {
        creatives.push({ name, creativeId });
      });
    });

    setListCreatives(creatives);
    if (creatives[0]) setCreative({ value: creatives[0], label: creatives[0].name });

    // eslint-disable-next-line
  }, [campaign]);

  return (
    <MenuSelectionStyled>
      <Dropdown
        items={listBrands}
        value={brand}
        onSelect={handleBrandChange}
        placeholder="Select Brand"
      />
      <Dropdown
        items={listCampaigns}
        value={campaign}
        onSelect={handleCampaignChange}
        placeholder="Select Campaign"
      />
      {hideCreative || (
        <Dropdown
          items={listCreatives}
          value={creative}
          onSelect={handleCreativeChange}
          placeholder="Select Creative"
        />
      )}
    </MenuSelectionStyled>
  );
};

export default MenuSelection;

/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { memo } from 'react';

const SvgComponent = ({ color, ...props }) => (
  <svg
    width={36}
    height={36}
    viewBox="-4 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>map-marker</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M14 0c7.732 0 14 5.641 14 12.6C28 23.963 14 36 14 36S0 24.064 0 12.6C0 5.641 6.268 0 14 0Z"
        fill={color}
      />
      <circle fill="#ffffff" fillRule="nonzero" cx={14} cy={14} r={7} />
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;

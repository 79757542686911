import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import {
  fetchAllTablets,
  uploadTabletToHivestack,
  uploadTabletToVistar,
} from '../../Utilities/api';
import DropdownMenu from '../../Components/DropdownMenu';
import Loading from '../../Components/Loading';

const localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(localizedFormat);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'CAD',
});

const Container = styled.div`
  text-align: left;
  padding: 25px;
`;
const Header = styled.div`
  padding: 0 20px 20px;
  font-size: 30px;
  font-weight: 600;
  color: #222222;
`;

const Tablets = () => {
  const [allTablets, setAllTablets] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let isMounted = true;

    const fetchTablets = async () => {
      const tabs = await fetchAllTablets();
      setLoading(false);
      if (tabs.error) {
        return console.log(tabs.error);
      }
      return isMounted && setAllTablets(tabs);
    };

    fetchTablets();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleTabletUploadComplete = ({
    added, error, failed, invalid,
  }) => {
    if (error) {
      const { response } = error;
      if (response?.status === 404) {
        return enqueueSnackbar('No driver found for that tablet.', {
          variant: 'error',
        });
      }
      if (response?.status === 400) {
        return enqueueSnackbar('Invalid imei.', {
          variant: 'error',
        });
      }

      if (response?.status === 500) {
        return enqueueSnackbar(response?.data?.error, {
          variant: 'error',
        });
      }

      if (typeof error === 'string') {
        return enqueueSnackbar(error, {
          variant: 'error',
        });
      }
      return console.error(error);

      // return specific alerts based on type of error
    }

    const tabsAdded = added.map(
      ({
        imei,
        city,
        floor_cpm: hivestackCpm,
        name,
        cpm_floor_cents: vistarCpm,
        id,
        partner_venue_id: partnerVenueId,
      }) => {
        // hivestack upload
        if (hivestackCpm) {
          return {
            imei,
            hivestackSite: city,
            hivestackCpm,
            hivestackName: name,
            hivestackId: id,
          };
        }
        // otherwise vistar upload
        return {
          imei: partnerVenueId,
          vistarCpm: vistarCpm / 100,
          vistarId: id,
          vistarName: name,
        };
      },
    );

    setAllTablets((prevTabs) => prevTabs.map((tab) => {
      const updatedTab = tabsAdded.find(({ imei }) => imei === tab.imei);
      return updatedTab || tab;
    }));

    failed.forEach((failedTab) => {
      const { imei } = failedTab;
      enqueueSnackbar(`Failed to upload ${imei}: ${failedTab.error}.`, {
        variant: 'error',
      });
      console.error('Failed to upload: ', failedTab);
    });
    invalid.forEach((invalidTab) => console.warn('Invalid tablet upload: ', invalidTab));

    if (tabsAdded.length > 0) {
      const addedTabsInString = Object.values(tabsAdded).map(({ imei }) => imei);
      enqueueSnackbar(`Uploaded sucessfully: ${addedTabsInString.toString()}.`, {
        varient: 'success',
      });
    }

    return null;
  };

  const visibleColumns = [
    {
      name: 'Imei',
      selector: ({ imei }) => imei,
      sortable: true,
    },
    {
      name: 'Latest Sign In (QR Scan)',
      selector: ({ lastSignedIn }) => new Date(lastSignedIn),
      format: ({ lastSignedIn }) => dayjs(lastSignedIn).format('lll'),
      sortable: true,
    },
    {
      name: 'Hivestack Name',
      selector: ({ hivestackName }) => hivestackName,
      sortable: true,
    },
    {
      name: 'Hivestack Site',
      selector: ({ hivestackSite }) => hivestackSite,
      sortable: true,
    },
    {
      name: 'Hivestack CPM',
      selector: ({ hivestackCpm }) => hivestackCpm,
      sortable: true,
      format: ({ hivestackCpm }) => hivestackCpm && formatter.format(hivestackCpm),
    },
    {
      name: 'Vistar Name',
      selector: ({ vistarName }) => vistarName,
      sortable: true,
    },
    {
      name: 'Vistar CPM',
      selector: ({ vistarCpm }) => vistarCpm,
      sortable: true,
      format: ({ vistarCpm }) => vistarCpm && formatter.format(vistarCpm),
    },
    {
      name: 'Action',
      button: true,
      cell: ({ imei, hivestackId, vistarId }) => {
        const items = [];
        if (!hivestackId) {
          items.push({
            text: 'Upload to hivestack',
            action: () => uploadTabletToHivestack(imei),
            onSuccess: handleTabletUploadComplete,
          });
        }
        if (!vistarId) {
          items.push({
            text: 'Upload to vistar',
            action: () => uploadTabletToVistar(imei),
            onSuccess: handleTabletUploadComplete,
          });
        }

        return <DropdownMenu items={items} />;
      },
    },
  ];

  return (
    <Container>
      <Header>
        Tablets
        {allTablets.length ? `(${allTablets.length})` : ''}
      </Header>
      {isLoading ? (
        <Loading text="Querying ad partners" />
      ) : (
        <DataTable
          noHeader
          columns={visibleColumns}
          data={allTablets}
          striped
          pagination
          subHeader={false}
          subHeaderAlign="left"
        />
      )}
    </Container>
  );
};

export default Tablets;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import Modal from '../../Components/Modal';
import AddClient from '../../Components/AddButton';

// Compositions
import Table from '../../Compositions/Table';
import DashboardTableTopBar from '../../Compositions/DashboardTableTopBar';
import {
  CreateText,
} from '../../Compositions/FormSingleInput';
import ModalButtonGroup from '../../Compositions/ModalButtonGroup';
import { AddCampaign, AddCreativeModal } from '../../Compositions/Modals';

// API
import {
  // getInsightCompanies,
  deleteInsightsCampaign,
  addCampaignToCompany,
  getInsightBrandAndCompany,
  updateInsightCampaign,
} from '../../Utilities/api';

// UTILS
import { DIVIDER, DELETE, EDIT_CAMPAIGN } from '../../Utilities/macros';

// ICON
import {
  ManageCampaignIconStyled,
  AddIconStyled,
  DeleteIconStyled,
} from '../../Components/Icon/CollapsibleMenuIconGroup';

const FormTitle = styled(CreateText)`
  margin-bottom: 35px;
`;

const ModalTitle = styled.p`
  color: #222222;
  font-size: 21px;
  font-weight: 500;
  text-align: ${({ alignment }) => alignment || 'center'};
`;

const ModalSubtitle = styled.p`
  line-height: 1.56;
  padding: 30px;
  text-align: center;
  color: #757575;
`;

const shortCutMenuOptions = [
  {
    name: 'Edit Campaign',
    icon: ManageCampaignIconStyled,
  },
  {
    name: 'Add Creative',
    icon: AddIconStyled,
  },
  {
    name: DIVIDER,
  },
  {
    name: 'Delete',
    icon: DeleteIconStyled,
    action: () => console.log('Change Icon Action'),
  },
];

const plannerShortcutMenuOptions = undefined;

const brandHeaders = {
  mainHeader: 'Campaign',
  subHeaders: ['date added', 'impression'],
};

const CampaignsTopBarContent = ({
  brandLogo,
  brandName,
  brandId,
  setTableContent,
}) => {
  const [modalContent, setModalContent] = useState('');
  const closeModal = () => {
    setModalContent(undefined);
  };

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    const datePattern = 'YYYY-MM-DDTHH:mm:ss';
    const formattedStartDate = (values.startDate).format(datePattern);
    const formattedEndDate = (values.endDate).format(datePattern);

    if (formattedStartDate > formattedEndDate) return setFieldError('startDate', 'Start date cannot be after end date');

    const campaign = {
      name: values.campaignName,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      goal: parseInt(values.impressionGoal, 10),
      dateAdded: new Date(),
    };

    setSubmitting(true);
    const results = await addCampaignToCompany(brandId, campaign);
    setSubmitting(false);

    if (results.error) return setFieldError('campaignName', results.error);
    setTableContent((prevCampaigns) => (
      [...prevCampaigns, { ...campaign, logo: brandLogo }]
    ));
    return closeModal();
  };

  return (
    <>
      <Modal isOpen={Boolean(modalContent)} onClose={closeModal}>
        <FormTitle>{`Add campaign for ${brandName}`}</FormTitle>
        <AddCampaign handleSubmit={handleSubmit} closeModal={closeModal} />
      </Modal>
      <AddClient onClick={() => setModalContent('Brand')}>+</AddClient>
    </>
  );
};

const DeleteModal = ({
  title, name, subtitle, action,
}, closeModal) => (
  <>
    <ModalTitle>
      {title}
      {name}
      ?
    </ModalTitle>
    <ModalSubtitle>{subtitle}</ModalSubtitle>
    <ModalButtonGroup closeModal={closeModal} action={action} text="Delete" />
  </>
);

const EditModal = (
  {
    title,
    editValues,
    brandId,
    campaignId,
  },
  closeModal,
  tableContent,
  setTableContent,
) => {
  const editSubmit = async (values, { setSubmitting, setFieldError }) => {
    // check to see which values have changed from edit values(initial values)
    const changedValues = Object.entries(values)
      .reduce((acc, [key, value]) => {
        const hasChanged = editValues[key] !== value;

        if (hasChanged) {
          acc[key] = value;
        }
        return acc;
      }, {});

    // format edited dates to correct format
    const datePattern = 'YYYY-MM-DDTHH:mm:ss';
    if (changedValues && changedValues.startDate) {
      changedValues.startDate = (changedValues.startDate).format(datePattern);
    }
    if (changedValues && changedValues.endDate) {
      changedValues.endDate = (changedValues.endDate).format(datePattern);
    }

    // set error if start date is after end date
    if ((changedValues.startDate || values.startDate) > (changedValues.endDate || values.endDate)) return setFieldError('startDate', 'Start date cannot be after end date');

    // format changed values to correct format for state/server updates
    const {
      campaignName,
      impressionGoal,
      startDate,
      endDate,
    } = changedValues;

    const updates = {
      name: campaignName || values.campaignName,
      goal: parseInt(impressionGoal, 10) || values.impressionGoal,
      startDate: startDate || values.startDate,
      endDate: endDate || values.endDate,
      dateAdded: editValues.dateAdded,
    };

    setSubmitting(true);
    const results = await updateInsightCampaign(brandId, campaignId, updates);
    setSubmitting(false);

    if (results.error) return setFieldError('campaignName', results.error);

    setTableContent(tableContent.map((campaign) => {
      const { _id: id } = campaign;
      if (id === campaignId) return { ...campaign, ...updates };
      return campaign;
    }));

    return closeModal();
  };

  return (
    <>
      <ModalTitle>
        {title}
      </ModalTitle>
      <AddCampaign
        handleSubmit={editSubmit}
        closeModal={closeModal}
        editValues={editValues}
      />
    </>
  );
};

const Campaigns = ({ match }) => {
  // TODO: destructure?
  const [tableContent, setTableContent] = useState([]);
  const [modalContent, setModalContent] = useState(undefined);

  const [headers, setHeaders] = useState(brandHeaders);
  const [brand, setBrand] = useState({});
  const brandId = match.params && match.params.id;
  // Direct Brand? Brand?
  // If Brand: should have reppedBy, if no reppedBy, push back to previous page.
  const [reppedBy, setReppedBy] = useState({
    name: '',
    id: '',
  });

  const closeModal = () => setModalContent(undefined);

  useEffect(() => {
    let isMounted = true;

    const fetchCampaignsAndCompany = async (id) => {
      // response returns an array of objects
      const response = await getInsightBrandAndCompany(id);
      if (response.error) {
        return console.log('Error fetching brands and company: ', response.error);
      }
      const data = response[0];
      if (data.campaigns && data.campaigns.length > 0 && isMounted) {
        const campaigns = data.campaigns.map((campaign) => ({
          ...campaign,
          logo: data.logo,
        }));

        setTableContent(campaigns);
      }

      if (response && data.company.length > 0 && isMounted) {
        const { _id: companyId, name: companyName } = data.company[0];
        setReppedBy({
          name: companyName,
          id: companyId,
        });
      }
      return isMounted && setBrand(data);
    };

    if (brandId) {
      fetchCampaignsAndCompany(brandId);
      setHeaders(brandHeaders);
    }
    return () => { isMounted = false; };
  }, [brandId]);

  const mediaAgencyDirect = [
    {
      sectionName: 'Client List',
      route: '/clients',
    },
    {
      sectionName: brand && brand.name ? `Manage Campaigns - ${brand.name}` : '',
    },
  ];

  const mediaAgencyAdAgency = [
    {
      sectionName: 'Client List',
      route: '/clients',
    },
    {
      sectionName: reppedBy && reppedBy.name ? `Manage Brands - ${reppedBy.name}` : '',
      route: reppedBy && reppedBy.id ? `/client/${reppedBy.id}/brands` : '/clients',
    },
    {
      sectionName: brand && brand.name ? `Manage Campaigns - ${brand.name}` : '',
    },
  ];

  const mediaAgencyLocation = reppedBy && reppedBy.name ? mediaAgencyAdAgency : mediaAgencyDirect;

  // const handleLinkPlannerFormSubmit = async (data) => {
  //   const payload = {
  //     plannersToAdd: [],
  //     plannersToDelete: [],
  //     companyId: data.companyId,
  //   };

  //   // Add if this planner does not exist in modalContent.plannersChecked
  //   data.planners.forEach((planner) => {
  //     if (!modalContent.plannersChecked.includes(planner)) {
  //       payload.plannersToAdd.push(planner);
  //     }
  //   });

  //   // Delete if planner is no longer checked in the form.
  //   modalContent.plannersChecked.forEach((checkedPlanner) => {
  //     if (!data.planners.includes(checkedPlanner)) {
  //       payload.plannersToDelete.push(checkedPlanner);
  //     }
  //   });

  //   if (
  //     payload.plannersToAdd.length === 0
  //     && payload.plannersToDelete.length === 0
  //   ) {
  //     closeModal();
  //     return console.log('Nothing needs to be updated, aborted');
  //   }

  //   const results = await linkPlannerToCompany(payload);

  //   if (results.error) {
  //     return console.warn('Error: ', results.error);
  //   }
  //   // closeModal(); //TODO: mutate location.state?
  //   window.location.reload();
  // };

  // const setModaltoForm = async (targetDocument) => {
  //   // Find planners of this agency, and of this brand
  //   const plannerListWithAgency = await getInsightPlanners([
  //     brandId,
  //     targetDocument._id,
  //   ]);

  //   // Something went wrong
  //   if (
  //     plannerListWithAgency.error /* || plannerListWithAgency.masterList.length === 0 */
  //   ) {
  //     return console.warn(
  //       'Error on getting planners',
  //       plannerListWithAgency.error,
  //     );
  //   }

  //   const { masterList, assignedList } = plannerListWithAgency;

  //   // Map planner ID & name for form, marked planner that already has membership.
  //   const planners = masterList.planner
  //     ? masterList.planner.map((planner) => ({
  //       id: planner._id,
  //       name: planner.name,
  //     }))
  //     : [];
  //   const plannersChecked = assignedList.planner
  //     && assignedList.planner.map((planner) => planner._id);

  //   // Form set up:
  //   setModalContent({
  //     ...targetDocument,
  //     optionType: targetDocument.optionType,
  //     agencyName: brandName,
  //     name: targetDocument.name,
  //     _id: targetDocument._id,
  //     title: `Link planner to ${targetDocument.name} - ${brandName} `,
  //     planners,
  //     plannersChecked: plannersChecked || [],
  //     buttonAction: 'create',
  //   });
  // };

  const deleteCampaign = async (name) => {
    const results = await deleteInsightsCampaign(name, brandId);
    if (results.error) {
      return console.log('ERROR: ', results.error);
    }
    setTableContent((prevState) => prevState.filter((campaign) => campaign.name !== name));
    return closeModal();
  };

  const addNewCampaign = async (values, { setSubmitting, setFieldError }) => {
    const {
      creatives,
      creativeName,
      creativeId,
      name,
      dateAdded,
      endDate,
      goal,
      startDate,
      _id: campaignId,
    } = values;

    const newCreativesArray = creatives || [];

    newCreativesArray.push({
      name: creativeName,
      creativeId,
    });

    const updates = {
      name,
      goal,
      startDate,
      endDate,
      dateAdded,
      creatives: newCreativesArray,
    };

    setSubmitting(true);
    const response = await updateInsightCampaign(brandId, campaignId, updates);
    setSubmitting(false);

    if (response.error) return setFieldError('campaignName', response.error);

    return window.location.reload();
  };

  const setModalToDelete = (targetDocument) => {
    const { name: campaignName } = targetDocument;
    setModalContent({
      ...targetDocument,
      optionType: 'Delete',
      title: 'Do you want to permanently delete ',
      icon: DeleteIconStyled,
      subtitle:
        'Please note that this will delete all data associated with this client',
      action: () => deleteCampaign(campaignName),
    });
  };

  const setModalToEdit = (targetDocument) => {
    const {
      _id: campaignId,
      name: campaignName,
      startDate,
      endDate,
      goal,
      dateAdded,
    } = targetDocument;

    const editValues = {
      campaignName,
      startDate,
      endDate,
      impressionGoal: goal,
      dateAdded,
    };

    setModalContent({
      ...targetDocument,
      optionType: 'Edit',
      title: `Do you want to edit campaign ${campaignName}?`,
      editValues,
      brandId,
      campaignId,
    });
  };

  const setModalToAddCreative = (targetDocument) => {
    setModalContent({
      optionType: 'Add Creative',
      title: 'Do you want to add a new campaign?',
      ...targetDocument,
      brandId,
      action: addNewCampaign,
    });
  };

  const handleModalContent = async (targetDocument) => {
    switch (targetDocument.optionType) {
      case DELETE:
        return setModalToDelete(targetDocument);
      case EDIT_CAMPAIGN:
        return setModalToEdit(targetDocument);
      case 'Add Creative':
        return setModalToAddCreative(targetDocument);
      default:
        return console.log('Option not implemented yet...');
    }
  };

  const switchModals = () => {
    switch (modalContent.optionType) {
      case 'Delete':
        return DeleteModal(modalContent, closeModal);
      case 'Edit':
        return EditModal(modalContent, closeModal, tableContent, setTableContent);
      case 'Add Creative':
        return <AddCreativeModal modalContent={modalContent} closeModal={closeModal} />;
      default:
        return <>Modal Not Implemented Yet</>;
    }
  };

  return (
    <>
      {modalContent && (
        <Modal
          isOpen={Boolean(modalContent)}
          onClose={closeModal}
          icon={modalContent.icon}
        >
          {switchModals()}
        </Modal>
      )}
      <DashboardTableTopBar location={mediaAgencyLocation}>
        <CampaignsTopBarContent
          brandName={brand && brand.name}
          brandId={brandId}
          setTableContent={setTableContent}
          brandLogo={brand && brand.logo}
        />
      </DashboardTableTopBar>
      <Table
        list={tableContent}
        headers={headers}
        menuContent={shortCutMenuOptions}
        setModalContent={handleModalContent}
        plannerShortcutMenuOptions={plannerShortcutMenuOptions}
      />
    </>
  );
};

export default Campaigns;

import React from 'react';
import styled from 'styled-components';

import NotificationIcon from '../../Components/Icon/Notification';
import SearchIcon from '../../Components/Icon/Search';

const IconTray = styled.div`
   display: flex;
   justify-content: flex-end;
   align-items: center;
`;

const NotificationWrap = styled(NotificationIcon)`
    padding: 5px;
`;

const SearchWrap = styled(SearchIcon)`
    padding: 5px;
`;

const ClientLogo = styled.div`
    margin-left: 10px;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background: ${(props) => (props.photo ? `url(${props.photo}) center no-repeat` : '#e8f0fe')};
    background-size: cover;
`;

const MenuTop = ({ photo }) => (
  <>
    {/** FOR FUTURE USAGE SOME OTHER FLEX ITEM */}
    <IconTray>
      <SearchWrap />
      <NotificationWrap />
      <ClientLogo photo={photo} />
    </IconTray>
  </>
);

export default MenuTop;

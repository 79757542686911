import React from 'react';
import moment from 'moment';
import { BadgeIcon, AdPlatformImg } from './style';

export const typesOfDrivers = [
  {
    value: 'Online',
    label: 'Online',
    allowedActions: [
      'Edit',
      'Reload Tablet',
      'Live View',
      'Check Activity',
      'Replace or Logout',
      'Override URL',
      'Generate Exchange Label',
      'Generate Exchange Label W/ Return',
    ],
    showColumns: [
      'name', 'email', 'phone', 'rank', 'points', 'tier', 'city', 'province', 'country', 'postalCode', 'imei', 'tabletInstallDate',
    ],
  },
  {
    value: 'Installed',
    label: 'Installed',
    allowedActions: [
      'Edit',
      'Check Activity',
      'Add Distance',
      'Generate Exchange Label',
      'Generate Exchange Label W/ Return',
    ],
    showColumns: [
      'name', 'email', 'phone', 'rank', 'points', 'tier', 'city', 'province', 'country', 'postalCode', 'imei', 'tabletInstallDate',
    ],
  },
  {
    value: 'NewLead',
    label: 'New Lead',
    allowedActions: ['Edit', 'Delete'],
    showColumns: ['name', 'email', 'phone', 'city', 'province', 'country', 'created', 'referrer', 'referralCode'],
  },
  {
    value: 'Qualified',
    label: 'Qualified',
    allowedActions: ['Edit', 'Delete'],
  },
  {
    value: 'ApplicationReview',
    label: 'Awaiting review',
    allowedActions: ['Edit', 'Accept application', 'Move to Unqualified', 'Delete'],
  },
  {
    value: 'DepositRequired',
    label: 'Deposit Required',
    allowedActions: ['Edit', 'Delete'],
  },
  {
    value: 'Unqualified',
    label: 'Unqualified',
    allowedActions: ['Edit', 'Accept application', 'Move to Qualified', 'Delete'],
  },
  {
    value: 'ShippingRequested',
    label: 'Requested a tablet',
    allowedActions: ['Edit', 'Generate Label'],
    showColumns: [
      'name', 'email', 'phone', 'city', 'province', 'country', 'postalCode', 'created',
    ],
  },
  {
    value: 'TabletShipped',
    label: 'Shipped a tablet',
    allowedActions: ['Edit', 'Track →', 'Download Shipping Label'],
    showColumns: [
      'name', 'email', 'phone', 'province', 'country', 'imei', 'city', 'shipped', 'delivered', 'created', 'postalCode',
    ],
  },
  {
    value: 'ReturnRequested',
    label: 'Requesting a return',
    allowedActions: [
      'Edit', 'Generate Return Label', 'Track Return →', 'Move to Churned',
    ],
    showColumns: [
      'name', 'email', 'phone', 'province', 'country', 'imei', 'address', 'city', 'reason', 'labelGenerated', 'created',
    ],
  },
  {
    value: 'Churned',
    label: 'Churned',
    allowedActions: ['Edit', 'Delete'],
    showColumns: [
      'name', 'email', 'phone', 'province', 'country', 'imei', 'address', 'city', 'reason', 'labelGenerated', 'created',
    ],
  },
];

export const DEFAULT_COLUMNS = [
  'name', 'email', 'phone', 'city', 'province', 'country', 'postalCode', 'created', 'referrer', 'referralCode',
];

export const typesOfSearchBy = [
  { value: 'email', label: 'Email' },
  { value: 'fullName', label: 'Name' },
  { value: 'phone', label: 'Phone Num' },
  { value: 'IMEI', label: 'IMEI' },
  { value: 'referralCode', label: 'Ref Code' },
  { value: 'trackingPin', label: 'Tracking Pin' },
];

const mapTierToImg = {
  'Portl Blue': 'blue-badge-sm.webp',
  Platinum: 'platinum-badge-sm.webp',
  Gold: 'gold-badge-sm.webp',
  Silver: 'silver-badge-sm.webp',
  Bronze: 'bronze-badge-sm.webp',
};

function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

const LabelIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height={18} viewBox="0 0 24 24" width={18}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      fill="#169aff"
      d="M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7v10c0 1.1.9 1.99 2 1.99L16 19c.67 0 1.27-.33 1.63-.84l3.96-5.58c.25-.35.25-.81 0-1.16l-3.96-5.58z"
    />
  </svg>
);

export const allColumns = {
  supportedAdPlatforms: {
    name: 'Supported Platforms',
    selector: ({ supportedPlatforms }) => supportedPlatforms.length,
    cell: ({ supportedPlatforms, email }) => supportedPlatforms
        && supportedPlatforms.map((platformName) => (
          <div key={email + platformName}>
            <AdPlatformImg
              src={`/assets/${platformName}-logo-sm.png`}
              alt={platformName}
            />
          </div>
        )),
    sortable: true,
  },
  name: {
    name: 'Name',
    selector: (row) => (row.driverName ? `${row.driverName.first} ${row.driverName.last}` : ''),
    sortable: true,
    wrap: true,
    maxWidth: '230px',
  },
  email: {
    name: 'Email',
    selector: (row) => row.email,
    sortable: true,
    minWidth: '270px',
  },
  phone: {
    name: 'Phone Number',
    selector: (row) => row.phone,
    format: (row) => formatPhoneNumber(row.phone),
    sortable: true,
    width: '150px',
  },
  rank: {
    name: 'Rank',
    selector: (row) => row.rank,
    sortable: true,
    width: '100px',
  },
  points: {
    name: 'Monthly Points',
    selector: (row) => row.points,
    sortable: true,
  },
  tier: {
    name: 'Tier',
    selector: (row) => row.tier,
    cell: ({ tier }) => (tier ? <BadgeIcon src={`/assets/${mapTierToImg[tier]}`} /> : null),
  },
  reason: {
    name: 'Return Reasons',
    cell: ({ returnInfo }) => {
      if (!returnInfo) return null;
      const { reasons, other, message } = returnInfo;
      const final = [];
      reasons.forEach((reason) => {
        if (reason === 'other') final.push(`${reason}: ${other}`);
        else final.push(reason);
      });
      final.push(`Message: ${message}`);
      return (
        <div>
          {final.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </div>
      );
    },
    wrap: true,
    width: '200px',
  },
  address: {
    name: 'Street',
    selector: (row) => row.addressOne + (row.addressTwo ? `, ${row.addressTwo}` : ''),
    sortable: true,
    width: '200px',
    wrap: true,
  },
  city: {
    name: 'City',
    selector: (row) => row.city,
    sortable: true,
    width: '100px',
  },
  province: {
    name: 'Province/ State',
    selector: (row) => row.province,
    sortable: true,
    width: '80px',
  },
  country: {
    name: 'Country',
    selector: (row) => row.country || 'CA',
    sortable: true,
    width: '80px',
  },
  trackingPin: {
    name: 'Tracking Pin (latest)',
    selector: (row) => row.shipments && row.shipments[0] && row.shipments[0].trackingPin,
    width: '170px',
  },
  postalCode: {
    name: 'Postal Code / Zip Code',
    selector: (row) => row.postalCode,
    sortable: true,
  },
  shipped: {
    name: 'Date Shipped',
    selector: (row) => new Date(row.shipDate).getTime(),
    format: (row) => (row.shipDate ? moment(row.shipDate).format('MMM. D, YYYY') : ''),
    sortable: true,
    width: '130px',
  },
  delivered: {
    name: 'Date Delivered',
    selector: (row) => new Date(row.deliveryDate).getTime(),
    format: (row) => (row.deliveryDate ? moment(row.deliveryDate).format('MMM. D, YYYY') : ''),
    sortable: true,
    width: '130px',
  },
  imei: {
    name: 'IMEI',
    selector: (row) => (row.tablets && row.tablets.length > 0
      ? row.tablets[row.tablets.length - 1].IMEI
      : ''),
    sortable: true,
    width: '150px',
  },
  tabletInstallDate: {
    name: 'Date Installed',
    selector: (row) => row.tabletInstallDate,
    sortable: true,
    format: (row) => (row.tabletInstallDate ? moment(row.tabletInstallDate).format('MMM.D, YYYY') : ''),
    width: '130px',
  },
  created: {
    name: 'Created',
    selector: (row) => row.created,
    sortable: true,
    format: (row) => (row.created ? moment(row.created).format('MMM. D, YYYY') : ''),
    width: '130px',
  },
  channel: {
    name: 'Channel',
    selector: (row) => row.referring_source,
    sortable: true,
    width: '130px',
  },
  referrer: {
    name: 'Referrer',
    selector: (row) => row.referrer,
    sortable: true,
    width: '130px',
  },
  referralCode: {
    name: 'Referral Code',
    selector: (row) => row.referralCode,
    sortable: true,
    width: '130px',
  },
  labelGenerated: {
    name: 'Label',
    sortable: true,
    selector: ({ trackingPin, returnTrackingPin }) => trackingPin || returnTrackingPin,
    cell: ({ leadStatus, trackingPin, returnTrackingPin }) => ((leadStatus === 'ShippingRequested' && trackingPin)
          || (leadStatus === 'ReturnRequested' && returnTrackingPin)) && <LabelIcon />,
    width: '50px',
  },
  depositStatus: {
    name: 'Deposit Status',
    selector: (row) => row.deposit_status,
    sortable: true,
  },
};

/* eslint-disable no-param-reassign */
// disable for now, will need to be redone when in use
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  Formik, Form, Field, FieldArray, ErrorMessage,
} from 'formik';

// Components
import Modal from '../../Components/Modal';
import Loading from '../../Components/Loading';
import Tooltip from '../../Components/Tooltip';
import AddClient from '../../Components/AddButton';
import MenuOptions from '../../Components/MenuOption';
import MediaAgency from '../../Components/Icon/ClientList';
import DirectBrand from '../../Components/Icon/DirectBrand';
import { ModalButton, ModalButtonCancel } from '../../Components/ModalButton';

// Compositions
import Table from '../../Compositions/Table';
import DashboardTableTopBar from '../../Compositions/DashboardTableTopBar';
import FormSingleInput from '../../Compositions/FormSingleInput';

// Modals
import { ChangeIconModal } from '../../Compositions/Modals';

// API
import {
  getInsightCompanies,
  updateInsightCompanies,
  deleteInsightCompanies,
  linkPlannerToCompany,
  createPlannerAndLinkCompanies,
  constructCompany,
} from '../../Utilities/api';

// UTILS
import {
  DIVIDER, DEACTIVATE, DELETE, ADD,
} from '../../Utilities/macros';

// ICON
import {
  AddIconStyled,
  ChangeIconStyled,
  DeactivateStyled,
  DeleteIconStyled,
} from '../../Components/Icon/CollapsibleMenuIconGroup';
import LabelIconStyled from '../../Components/Icon/Label';

// CONTEXT
import { ClientContext } from '../../context';

const ModalTitle = styled.p`
  color: #222222;
  font-size: 21px;
  font-weight: 500;
  text-align: center;
`;

const ModalSubtitle = styled.p`
  line-height: 1.56;
  padding: 30px;
  text-align: center;
  color: #757575;
`;
const ButtonGroup = styled.div`
  text-align: center;
`;

const AddNewContainer = styled.div`
  padding: 5px;
  width: 310px;
  border-radius: 10px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.25);

  background-color: #ffffff;
`;

const MenuOptionLightFont = styled(MenuOptions)`
  font-weight: 400;
  font-size: 22px;
`;

const Divider = styled.hr`
  height: 0px;
  margin: 0;
  border: none;
  border-bottom: 1px solid #d7d7d7;
  padding: 0px 15px;
`;

// TODO: Media agency manage brand, Direct brand manage campaigns
// This array is just to show the options of the item in the collapse icon
// To make it do something, you need to add the name and the actions to the 'parseModalContent'
const shortCutMenuOptions = [
  {
    name: ADD,
    icon: AddIconStyled,
  },
  {
    name: 'Change Icon',
    icon: ChangeIconStyled,
  },
  {
    name: DIVIDER,
  },
  {
    name: DEACTIVATE,
    icon: DeactivateStyled,
  },
  {
    name: DELETE,
    icon: DeleteIconStyled,
  },
];

const plannerShortcutMenuOptions = [
  {
    name: 'Remove Planner',
    icon: DeleteIconStyled,
  },
];

const clientListHeaders = {
  mainHeader: 'Client List',
  subHeaders: ['type', 'status', 'date added'],
};

const AddNewClientMenu = ({ setModalContent }) => {
  const MEDIA_AGENCY = 'Media Agency';
  const DIRECT_BRAND = 'Direct Brand';

  const handleClick = (brand) => {
    setModalContent(brand);
  };

  return (
    <AddNewContainer>
      <MenuOptionLightFont
        name={MEDIA_AGENCY}
        icon={MediaAgency}
        onClick={() => handleClick(MEDIA_AGENCY)}
      />
      <Divider />
      <MenuOptionLightFont
        name={DIRECT_BRAND}
        icon={DirectBrand}
        onClick={() => handleClick(DIRECT_BRAND)}
      />
    </AddNewContainer>
  );
};

const ClientOverViewTopbarContent = ({ setTableContent }) => {
  const [modalContent, setModalContent] = useState(undefined);
  const toggleModalState = () => {
    setModalContent(undefined);
  };

  const handleSubmit = async (data) => {
    const res = await constructCompany(data);
    if (res.error) {
      return console.warn('ERROR: ', res);
    }
    toggleModalState();
    return setTableContent((prevState) => [...prevState, res]);
  };

  return (
    <>
      <Modal isOpen={Boolean(modalContent)} onClose={toggleModalState}>
        <FormSingleInput
          createType={modalContent}
          toggle={toggleModalState}
          handleSubmit={handleSubmit}
          formTitle={`Add ${modalContent}`}
        />
      </Modal>
      <Tooltip
        placement="bottom-start"
        trigger="click"
        tooltip={<AddNewClientMenu setModalContent={setModalContent} />}
        additionalStyling={{
          top: '-90px!important',
          left: '-5px!important',
          zIndex: '10',
        }}
      >
        <AddClient>+</AddClient>
      </Tooltip>
    </>
  );
};

const LoadingStyled = styled(Loading)`
  padding-top: 0;
`;

const ModalTitleAdd = styled.div`
  font-size: 21px;
  font-weight: 500;
  text-align: left;
  color: #222222;
  padding-bottom: 18px;
`;

const InputWrapper = styled(Field)`
  font-size: 16px;
  color: #757575;
  border-radius: 5px;
  border: solid 1px #757575;
  padding: 10px 15px;
  width: 447px;
  margin-top: 15px;
`;

const CheckContainer = styled.div`
  margin-top: 15px;
  border: solid 1px #757575;
  border-radius: 5px;
  padding: 11px 15px;
  color: #757575;
`;

const CheckboxTitle = styled.div`
  display: flex;
  align-items: center;
`;

const CheckInput = styled.input.attrs({ type: 'checkbox' })`
  margin-top: 15px;
  margin-right: 15px;

  &: before {
    background: blue;
    border: 2px solid yellow;
    border-radius: 5px;
  }
`;

const ButtonWrapper = styled.div`
  text-align: end;
  margin-top: 30px;
`;

const ModalButtonStyled = styled(ModalButton)`
  margin: 0 0 0 10px;
`;

const ErrorMessageStyled = styled.p`
  padding-top: 4px;
  font-size: 11px;
  color: #d80f4d;
  margin: 0;
`;

const AddFormModal = ({ modalContent, closeModal }) => {
  const [brands, setBrands] = useState();
  const {
    _id: id, action, title, name,
  } = modalContent;

  const validationSchema = Yup.object({
    plannerName: Yup.string().required('Planner name is required'),
    plannerEmail: Yup.string()
      .trim()
      .email('Please enter a valid email.')
      .required('Please enter a valid email.'),
  });

  useEffect(() => {
    let isMounted = true;

    const getInfo = async () => {
      const response = await getInsightCompanies(modalContent.brands);

      if (isMounted) setBrands(response);
    };

    getInfo();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Formik
      initialValues={{
        plannerName: '',
        plannerEmail: '',
        companies: [id],
      }}
      validationSchema={validationSchema}
      onSubmit={action}
    >
      {() => (
        <Form>
          <ModalTitleAdd>
            {title}
            {' '}
            {name}
            .
          </ModalTitleAdd>
          <InputWrapper
            id="plannerName"
            name="plannerName"
            placeholder="Planner name"
          />
          <ErrorMessage
            id="plannerName"
            name="plannerName"
            component={ErrorMessageStyled}
          />
          <InputWrapper
            id="plannerEmail"
            name="plannerEmail"
            placeholder="Planner email"
          />
          <ErrorMessage
            id="plannerEmail"
            name="plannerEmail"
            component={ErrorMessageStyled}
          />
          {modalContent.brands && (
            <CheckContainer>
              <CheckboxTitle>
                <LabelIconStyled />
                Connect brands
              </CheckboxTitle>
              {!brands ? (
                <LoadingStyled />
              ) : (
                <FieldArray
                  name="companies"
                  render={(arrayHelpers) => brands.map(({ _id: brandId, name: brandName }) => (
                    <div key={`brandKey-${brandId}`}>
                      <CheckInput
                        id={brandId}
                        name={brandName}
                        value={brandId}
                        onChange={(e) => {
                          if (e.target.checked) {
                            arrayHelpers.push(brandId);
                          } else {
                            arrayHelpers = arrayHelpers.remove(brandId);
                          }
                        }}
                      />
                      <label htmlFor={brandId}>{brandName}</label>
                    </div>
                  ))}
                />
              )}
            </CheckContainer>
          )}
          <ButtonWrapper>
            <ModalButtonCancel type="button" onClick={closeModal}>
              Cancel
            </ModalButtonCancel>
            <ModalButtonStyled type="submit">Create</ModalButtonStyled>
          </ButtonWrapper>
        </Form>
      )}
    </Formik>
  );
};

const ClientOverview = () => {
  const [tableContent, setTableContent] = useState([]);
  const [activeModal, setActiveModal] = useState(undefined);
  const { clients } = useContext(ClientContext);

  const closeModal = () => setActiveModal(undefined);

  const deactivateItem = async (itemId) => {
    const results = await updateInsightCompanies({ _id: itemId, disabled: true });
    if (results.error) {
      return console.log('ERROR: ', results.error);
    }
    return window.location.reload();
  };

  const deleteItem = async (itemId) => {
    const results = await deleteInsightCompanies(itemId);
    if (results.error) {
      return console.log('ERROR: ', results.error);
    }
    return window.location.reload();
  };

  const updateBrandPlanners = async (plannerId, companyId) => {
    const payload = {
      plannersToAdd: [],
      plannersToDelete: [plannerId],
      companyId,
    };
    const results = await linkPlannerToCompany(payload);
    if (results.error) return console.log('Error : ', results.error);
    return window.location.reload();
  };

  const addPlanner = async (info) => {
    const response = await createPlannerAndLinkCompanies({
      name: info.plannerName,
      email: info.plannerEmail,
      companies: info.companies,
    });
    if (response.error) {
      return console.log('Error on adding planner', response); // TODO: Handle error for fail
    }
    return window.location.reload();
  };

  const changeCompanyIcon = async (info) => {
    const response = await updateInsightCompanies(info);

    if (response.error) {
      return console.log('Error changing company logo', response); // TODO: Handle error for fail
    }
    return window.location.reload();
  };

  useEffect(() => {
    let isMounted = true;
    let parsedData;

    if (clients) {
      parsedData = clients.map(({ _id: itemId, ...item }) => {
        const newItem = { ...item, _id: itemId };
        if (item.type === 'Media Agency') {
          newItem.route = `/client/${itemId}/brands`;
        } else if (item.type === 'Direct Brand') {
          newItem.route = `/brand/${itemId}/campaigns`;
        } else {
          console.warn('Error: ', item);
        }
        return newItem;
      });
      if (isMounted) setTableContent(parsedData);
    }

    return () => {
      isMounted = false;
    };
  }, [clients]);

  const overviewLocation = [{ sectionName: 'Client List' }];

  const handleModalContent = ({
    _id: id, companyId, optionType, name, brands,
  }) => {
    const parseModalContent = {
      [DEACTIVATE]: {
        optionType: DEACTIVATE,
        title: 'Do you want to deactivate ',
        subtitle:
          'Please note that this will also deactivate all buyers for this client',
        action: () => deactivateItem(id),
      },
      [DELETE]: {
        optionType: DELETE,
        title: 'Do you want to permanently delete ',
        subtitle:
          'Please note that this will delete all data associated with this client',
        action: () => deleteItem(id),
      },
      'Remove Planner': {
        optionType: DELETE,
        title: 'Do you want to remove ',
        subtitle:
          'Please note that this will delete all data associated with this client',
        action: () => updateBrandPlanners(id, companyId), // targetDocument._id === company._id
      },
      [ADD]: {
        optionType: ADD,
        title: 'Add planner for',
        action: addPlanner,
      },
      'Change Icon': {
        optionType: 'Change',
        title: 'Would you like to change the icon for the following company: ',
        action: changeCompanyIcon,
      },
    };
    const currentModal = parseModalContent[optionType];
    setActiveModal({
      ...currentModal,
      name,
      _id: id,
      brands,
    });
  };

  const iconChoose = () => {
    if (!activeModal) return null;
    switch (activeModal.optionType) {
      case DEACTIVATE:
        return DeactivateStyled;
      case DELETE:
        return DeleteIconStyled;
      default:
        return null;
    }
  };

  const switchModals = () => {
    if (!activeModal) return null;
    switch (activeModal.optionType) {
      case ADD:
        return <AddFormModal modalContent={activeModal} closeModal={closeModal} />;
      case 'Change':
        return <ChangeIconModal modalContent={activeModal} closeModal={closeModal} />;
      default:
        return (
          <>
            <ModalTitle>
              {activeModal.title}
              {activeModal.name}
            </ModalTitle>
            <ModalSubtitle>{activeModal.subtitle}</ModalSubtitle>
            <ButtonGroup>
              <ModalButtonCancel onClick={closeModal}>
                cancel
              </ModalButtonCancel>
              <ModalButton onClick={activeModal.action}>
                {activeModal.optionType}
              </ModalButton>
            </ButtonGroup>
          </>
        );
    }
  };

  return (
    <>
      {activeModal && (
        <Modal
          isOpen={Boolean(activeModal)}
          onClose={closeModal}
          icon={iconChoose()}
        >
          {switchModals()}
        </Modal>
      )}
      <DashboardTableTopBar location={overviewLocation}>
        <ClientOverViewTopbarContent setTableContent={setTableContent} />
      </DashboardTableTopBar>
      <Table
        list={tableContent}
        headers={clientListHeaders}
        menuContent={shortCutMenuOptions}
        setModalContent={handleModalContent}
        plannerShortcutMenuOptions={plannerShortcutMenuOptions}
      />
    </>
  );
};

export default ClientOverview;

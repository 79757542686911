import React, { useState } from 'react';
import styled from 'styled-components';

const CollapsibleWrap = styled.div`
    display: grid;
   
`;

const CollapsibleTitle = styled.div`
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
`;

export const CollapsibleTitleText = styled.p`
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: #043650;
    max-width: 90%;
`;

const Symbol = styled.img`
    width: 16px;
    height: 16px;
`;

const CollapsibleContent = styled.div`
    overflow: hidden;
    text-align: left;
    color: #043650;
    font-weight: 300;
    font-size: 13px;
    line-height: 1.67;
    //margin-top: 10px;
    max-width: 95%;
`;

const Divider = styled.div`
    border-top: solid 1px #848484;
    width: 100%;
    margin: calc(16px + 1vw) 0;
`;

const Subtitle = styled.span`
    display: inline-block;
    width: 80ch;
    font-style: italic;
    font-size: 12px;
    font-weight: 300;
    color: #222222;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const CollapsibleBlock = ({
  title, subtitle, index, children,
}) => {
  // const plusSymbol = './assets/plusSymbol.png';
  // const minusSymbol = './assets/minusSymbol.png';
  const down = '/assets/down.png';
  const up = '/assets/up.png';

  const [open, setOpen] = useState(false);

  const togglePanel = () => {
    setOpen(!open);
  };

  return (
    <>
      {index !== 0 && <Divider />}
      <CollapsibleWrap>
        <CollapsibleTitle onClick={() => { togglePanel(); }}>
          <CollapsibleTitleText>
            {title}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </CollapsibleTitleText>
          <Symbol src={open ? up : down} alt="symbol" />
        </CollapsibleTitle>
        {open
          ? (
            <CollapsibleContent>
              {children}
            </CollapsibleContent>
          )
          : null}
      </CollapsibleWrap>
    </>
  );
};

export default CollapsibleBlock;

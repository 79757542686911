/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';

const customStyles = {
  option: (provided, { isSelected, selectProps: { thick } }) => ({
    ...provided,
    backgroundColor: isSelected ? '#e8f0fe' : '#ffffff',
    color: '#222222',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '300',
    textAlign: 'left',
    padding: thick ? '10px 15px' : '4.5px 25px',
    margin: '7.5px 0',
  }),

  menuList: (provided) => ({
    ...provided,
    padding: '0',
  }),

  menu: (provided) => ({
    ...provided,
    padding: '0',
    borderRadius: '10px',
  }),

  container: (provided, { selectProps: { thick, width, marginLeft } }) => ({
    ...provided,
    height: thick ? '40px' : '32px',
    cursor: 'pointer',
    minWidth: width || '200px',
    marginLeft: marginLeft || '0px',
  }),

  control: (provided, { selectProps: { thick } }) => ({
    ...provided,
    minHeight: 'none',
    height: '100%',
    borderRadius: thick ? '5px' : '10px',
    border: thick ? 'solid 1px #757575' : 'solid 1px #e7e7e7',
    cursor: 'pointer',
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: '0 0 0 20px',
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),

  indicatorSeparator: () => ({ // to remove the line on the dropdown
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 10px 0 0',
  }),

};

const Divider = styled.div`
    width: 90%;
    height: 1px;
    margin: 0 auto;
    background-color: #d6d6d6;
`;

const Option = (props) => (
  <>
    <components.Option {...props} />
    <Divider />
  </>
);

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <img src="/assets/arrowDropdown.png" alt="arrow" style={{ width: '19px' }} />
  </components.DropdownIndicator>
);

const Dropdown = ({
  value,
  items = [],
  onSelect,
  placeholder,
  thick,
  id,
  commonProps,
  allowSearch = false,
  width,
  marginLeft,
}) => {
  let options = [];
  if (items[0] && items[0].value && items[0].label) {
    options = items;
  } else {
    options = items.map((item) => {
      if (typeof item === 'string') return { value: item, label: item };
      return { value: item, label: item.name };
    });
  }
  return (
    <Select
      key={`react-select_${value}`}
      value={commonProps ? commonProps.values[id] : value}
      options={options}
      onChange={onSelect || ((option) => commonProps.setFieldValue(id, option))}
      styles={customStyles}
      isSearchable={allowSearch}
      components={{ DropdownIndicator, Option }}
      placeholder={placeholder}
      thick={thick}
      width={width}
      marginLeft={marginLeft}
      id={id}
      onBlur={commonProps && commonProps.setFieldTouched}
      error={commonProps && commonProps.errors[id]}
      touched={commonProps && commonProps.touched[id]}
    />
  );
};

export default Dropdown;

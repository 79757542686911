import React from 'react';
import styled from 'styled-components';
import SyncLoader from 'react-spinners/SyncLoader';

const Container = styled.div.attrs((props) => ({
  className: props.className,
}))`
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : '45vh')};
  margin: 0 auto;
  text-align: center;
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? `${paddingBottom}px` : 0)};
`;
const LoadingText = styled.p`
  padding-top: 20px;
`;

const Loading = ({
  className, text, paddingTop, children, size = 18, paddingBottom,
}) => (
  <Container paddingTop={paddingTop} className={className} paddingBottom={paddingBottom}>
    <SyncLoader size={size} color="#1FB6FF" loading margin={2} />
    {text ? <LoadingText>{text}</LoadingText> : children}
  </Container>
);

export default Loading;

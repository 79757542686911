import styled from 'styled-components';
import { ReactComponent as DirectBrandIcon } from './directbrand.svg';
import { ICON_CSS_CONFIG } from '../../../Utilities/cssConfig';

const DirectBrandIconStyled = styled(DirectBrandIcon)`
    height: 32px;
    fill: ${({ selected }) => (selected ? ICON_CSS_CONFIG.selected : ICON_CSS_CONFIG.normal)};
`;

export default DirectBrandIconStyled;

import styled from 'styled-components';

const InputError = styled.div`
    color: #d80f4d;
    font-size: 12px;
    text-align: left;
    height: 9px;
    line-height: 1.25;
    margin-top: 6px;
`;

export default InputError;

/* eslint-disable react/jsx-props-no-spreading */
import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import { UserContext } from '../context';

const AuthRoute = ({ component: Component, render, ...rest }) => {
  const { user } = React.useContext(UserContext);
  if (user && !user.error) {
    return <Redirect to="/drivers" />;
  }

  return (
    (Component)
      ? <Route {...rest} render={(props) => <Component {...props} />} />
      : <Route {...rest} render={render} />
  );
};

AuthRoute.propTypes = {
  component: (props, _propName, componentName) => {
    if (props.component || props.render) return null;
    return new Error(`One of props 'component' or 'render' was not specified in '${componentName}'.`);
  },
  render: (props, _propName, componentName) => {
    if (props.component || props.render) return null;
    return new Error(`One of props 'component' or 'render' was not specified in '${componentName}'.`);
  },
};

export default AuthRoute;

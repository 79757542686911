import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoutIcon } from './logout.svg';

const LogoutStyled = styled(LogoutIcon)`
    height: 19px;
    stroke-width: 2;
    width: 19px;
    fill: ${(props) => props.fill || '#222222'};
    padding-right: 15px;
`;

const LogoutCustom = () => (
  <LogoutStyled />
);

export default LogoutCustom;

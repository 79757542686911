import styled from 'styled-components';

export const ModalButton = styled.button.attrs((props) => ({
  className: props.className,
}))`
    width: 130px;
    height: 48px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    background-color: #169aff;
    border: none;
    font-size: 21px;
    color: ${({ disabled }) => (disabled ? '#D7D7D7' : 'white')};;
    margin: 0 5px;
    text-transform: capitalize;
    cursor: pointer;

    &:focus {
        outline: none;
        border: 2px solid #1FB6FF;
        caret-color: #1FB6FF;
    }
`;

export const ModalButtonCancel = styled(ModalButton)`
    background-color: #d6d6d6;
`;

const BASE_CSS_CONFIG = {
  MENU_WIDTH: '280px',
  CONTENT_WIDTH: '79.5%',
  MAX_CONTENT_WIDTH: '1526px',
  MAX_APP_WIDTH: '1920px',
  HEADER_HEIGHT: '73px',
  PADDING_LEFT: 30, // px
};

export const ICON_CSS_CONFIG = {
  selected: '#169AFF',
  normal: '#757575',
};

export default BASE_CSS_CONFIG;

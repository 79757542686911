import styled from 'styled-components';
import { ReactComponent as TabletsIcon } from './tablets.svg';
import { ICON_CSS_CONFIG } from '../../../Utilities/cssConfig';

const TabletsIconStyled = styled(TabletsIcon)`
    height: 24px;
    stroke-width: 1;
    transform: rotate(90deg);
    stroke: ${({ selected }) => (selected ? ICON_CSS_CONFIG.selected : ICON_CSS_CONFIG.normal)};
`;

export default TabletsIconStyled;

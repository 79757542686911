/* eslint-disable */
export const calcFontSize = (minFont, maxFont) => { // change to default
    const vw = (maxFont - minFont) / 16;
    const px = (minFont - (3.20 * vw));

    return (`
    calc(${px}px + ${vw}vw);

    @media screen and (min-width: 1920px) {
        font-size: ${maxFont}px;
    }

    @media screen and (max-width: 1400px) {
        font-size: ${minFont}px;
    }
    `);
};

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// icon
import ArrowNavigate from '../../Components/Icon/ArrowNavigate';

const ListTopBar = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  position: relative;
`;

const Title = styled.span`
  display: flex;
  flex-wrap: nowrap;
  padding: 0 30px;
  text-align: left;
`;

const Location = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const CurrentLocation = styled.span`
  font-size: 21px;
  font-weight: 600;
  color: #222222;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ParentLocation = styled(CurrentLocation)`
  color: #757575;
  cursor: pointer;
  width: 180px;
`;

const TitleTree = ({ location }) => {
  const last = location.length - 1;
  return (
    <Title>
      {location.map(({ route, sectionName }, index) => (
        <Location key={`nav-${sectionName}`}>
          {index === last ? (
            <CurrentLocation>{sectionName}</CurrentLocation>
          ) : (
            <>
              <ParentLocation as={Link} to={route}>
                {sectionName}
              </ParentLocation>
              <ArrowNavigate />
            </>
          )}
        </Location>
      ))}
    </Title>
  );
};

const DashboardTableTopBar = ({ location, children }) => (
  <ListTopBar>
    {children}
    <TitleTree location={location} />
  </ListTopBar>
);

export default DashboardTableTopBar;

import React from 'react';
import { Redirect } from 'react-router-dom';
import FileSaver from 'file-saver';

export const renderRedirect = (redirectObject) => (
  (redirectObject.isRedirect)
  && (
    <Redirect to={{
      pathname: redirectObject.path,
      state: {
        errorObject: redirectObject.errorObject,
      },
      email: redirectObject.email,
    }}
    />
  )
);

export const deleteCookie = (key, domain) => {
  // overwrites and expires existing cookie
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
};

export const saveLogsToText = (log, fileName, stringify = false) => {
  const modifiedLog = stringify ? JSON.stringify(log, null, 2) : log;
  const logBlob = new Blob([modifiedLog], { type: 'text/plain' });
  FileSaver.saveAs(logBlob, `${new Date().toLocaleString().replace(',', '_')}-${fileName}`);
};

export const isAdmin = (user) => user && user.role === 'admin' && user.email.includes('portlmedia');

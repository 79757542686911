import React from 'react';
import styled from 'styled-components';
import { ModalButton, ModalButtonCancel } from '../../Components/ModalButton';

const ButtonGroup = styled.div`
    text-align: center;
`;

const ModalButtonGroup = ({
  action, closeModal, disabled, text, isForm,
}) => (
  <ButtonGroup>
    <ModalButtonCancel onClick={closeModal}>cancel</ModalButtonCancel>
    <ModalButton disabled={disabled} type={isForm && 'submit'} onClick={action}>{text}</ModalButton>
  </ButtonGroup>
);

export default ModalButtonGroup;

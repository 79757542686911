import React from 'react';
import styled from 'styled-components';

import ImpressionChart from '../ImpressionChart';

const Wrapper = styled.div`
    border-radius: 10px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    color: #222222;
    text-align: left;
    font-size: 13px;
    overflow: hidden;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: 600;
    padding: 14px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
`;

const InfoData = styled.table`
    padding: 13px 0 2px 15px;
`;

const Space = styled.tr`
    height: 6px;
`;

const TdStyled = styled.td`
    padding-left: 12px;
`;

const StatusStyled = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => {
    if (props.color === 'Active') return '#13ba71';
    if (props.color === 'Complete') return '#169AFF';
    return '#169AFF';
  }};
    background-color: ${(props) => {
    if (props.color === 'Active') return '#ebfbf7';
    if (props.color === 'Complete') return '#DCF0FF';
    return '#DCF0FF';
  }};
    padding: 3px 22px;
    width: fit-content;
    border-radius: 10px;
`;

const ImpressionOverview = ({
  startDate, endDate, totalImpression, impressionGoal, status,
}) => (
  <Wrapper>
    <Title>Campaign Overview</Title>
    <InfoData>
      <tbody>
        <tr>
          <td>Status:</td>
          <TdStyled>{status && <StatusStyled color={status}>{status}</StatusStyled>}</TdStyled>
        </tr>
        <Space />
        <tr>
          <td>Start Date:</td>
          <TdStyled>{startDate.format('MMMM DD, YYYY')}</TdStyled>
        </tr>
        <tr>
          <td>End Date:</td>
          <TdStyled>{endDate.format('MMMM DD, YYYY')}</TdStyled>
        </tr>
        <Space />
        <tr>
          <td>Goal:</td>
          <TdStyled>{impressionGoal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TdStyled>
        </tr>
      </tbody>
    </InfoData>
    <ImpressionChart percent={Math.round((totalImpression / impressionGoal) * 100)} />
  </Wrapper>
);

export default ImpressionOverview;

import styled from 'styled-components';
import { ReactComponent as RefreshIcon } from './refresh.svg';
import { ICON_CSS_CONFIG } from '../../../Utilities/cssConfig';

const RefreshIconStyled = styled(RefreshIcon)`
    height: 24px;
    fill: ${({ selected }) => (selected ? ICON_CSS_CONFIG.selected : ICON_CSS_CONFIG.normal)};
`;

export default RefreshIconStyled;

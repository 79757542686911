import styled from 'styled-components';
import React from 'react';

const PanelTopBar = styled.div`
  border-radius: 9px 9px 0 0;
  border-top: 9px solid
    ${(props) => props.topColor || (props.errorPanel ? '#d80f4d' : '#1FB6FF')};
`;

const PanelWrapperStyled = styled.div`
  background-color: #f9f9f9;
  width: 100%;
  max-width: ${(props) => props.maxWidth}; //|| "355px"
  min-width: ${(props) => props.minWidth};
  justify-self: center;
  margin: 30px auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

const PanelWrapper = ({
  maxWidth,
  minWidth,
  errorPanel,
  topColor,
  children,
}) => (
  <PanelWrapperStyled maxWidth={maxWidth} minWidth={minWidth}>
    <PanelTopBar errorPanel={errorPanel} topColor={topColor} />
    {children}
  </PanelWrapperStyled>
);

export default PanelWrapper;

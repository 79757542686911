import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

// Dashboard Context
import { DashboardContext } from '../../context';

// Component
import ImpressionBox from '../../Components/ImpressionBox';
import ImpressionOverview from '../../Components/ImpressionOverview';
import DashboardMap from '../../Components/DashboardMap';
import ImpressionGraph from '../../Components/ImpressionGraph';
import Loading from '../../Components/Loading';
import DataError from '../../Components/DataError';

// API
import { getImpressions, getCampaignImpressions } from '../../Utilities/api';

const Wrapper = styled.div`
  max-width: 1022px;
  margin: 0 auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 246px minmax(200px, 1fr) minmax(425px, 495px);
  grid-gap: 17.5px;
`;

const Space = styled.div`
  height: 18px;
`;

const IMPRESSION_MULTIPLIER = 2;

const Dashboard = () => {
  const { creative, campaign, brand } = React.useContext(DashboardContext);
  const [isLoading, setLoading] = useState(false);
  const [campaignImpressions, setCampaignImpressions] = React.useState();

  let selectedCreative = {};
  if (campaign && campaign.value && creative && creative.value) {
    const { startDate, endDate, goal } = campaign.value;
    selectedCreative = {
      startDate,
      endDate,
      goal,
      ...creative.value,
    };
  }

  const [impressionData, setImpressionData] = React.useState({
    totalImpression: 0,
    todayImpression: 0,
    weekImpression: 0,
    daysLeft: 0,
    fromYesterday: 0,
    fromLastWeek: 0,
  });

  React.useEffect(() => {
    let isMounted = true;
    const calculateTotalCampaignImpressions = async () => {
      if (!isMounted) return null;
      if (!campaign.value) return setCampaignImpressions(0);

      const { creatives } = campaign.value;

      switch (creatives.length) {
        case 0:
          return setCampaignImpressions(0);
        case 1:
          return setCampaignImpressions(
            impressionData.totalImpression * IMPRESSION_MULTIPLIER,
          );
        default: {
          const allIds = creatives.map(({ creativeId }) => creativeId);

          const total = await getCampaignImpressions(allIds);
          if (!isMounted) return null;
          return setCampaignImpressions(total);
        }
      }
    };
    calculateTotalCampaignImpressions();

    return () => {
      isMounted = false;
    };
  }, [campaign, impressionData]);

  React.useEffect(() => {
    let isMounted = true;

    const getData = async () => {
      if (!selectedCreative.creativeId) return null;

      setLoading(true);
      const response = await getImpressions(selectedCreative.creativeId);
      const { imps, total: totalImpression } = response;

      if (!isMounted) return null;
      setLoading(false);

      const now = moment();
      const daysDiff = selectedCreative.endDate.diff(now, 'days');
      const daysLeft = daysDiff > 0 ? daysDiff + 1 : 0;
      const today = now.clone().startOf('day').valueOf();
      const yesterday = today - 86400000; // today.clone().subtract(1, 'days');
      const lastWeek = today - 604800000; // today.clone().subtract(7, 'days');
      const twoWeeksAgo = lastWeek - 604800000; // lastWeek.clone().subtract(7, 'days');
      let todayImpression = 0;
      let yesterdayImpression = 0;
      let weekImpression = 0;
      let twoWeeksImpression = 0;

      if (response.error) return setImpressionData(response);

      const impressions = [];

      Object.entries(imps).forEach(([timestamp, { total: count, locations }]) => {
        const timeInMS = parseInt(timestamp, 10); // convert to number
        impressions.push({ time: timeInMS, count, locations });
        if (twoWeeksAgo <= timeInMS && timeInMS < lastWeek) {
          twoWeeksImpression += count;
        }

        if (lastWeek <= timeInMS) {
          weekImpression += count;

          if (yesterday <= timeInMS && timeInMS < today) {
            yesterdayImpression += count;
          }

          if (today <= timeInMS) {
            todayImpression += count;
          }
        }
      });

      const fromYesterday = yesterdayImpression > 0
        ? parseInt((todayImpression / yesterdayImpression) * 100, 10)
        : 0;
      const fromLastWeek = twoWeeksImpression > 0
        ? parseInt((weekImpression / twoWeeksImpression) * 100, 10)
        : 0;

      return setImpressionData({
        totalImpression,
        todayImpression,
        weekImpression,
        fromYesterday,
        fromLastWeek,
        daysLeft,
        impressions,
      });
    };

    getData();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [selectedCreative.creativeId]);

  if (brand === 'Error') return <DataError />;

  if (isLoading) return <Loading />;

  if (impressionData.error) return <DataError />;

  if (!selectedCreative.creativeId) {
    return <div>Select a creative to view metrics.</div>;
  }

  return (
    <Wrapper>
      <Grid>
        <ImpressionOverview
          startDate={selectedCreative.startDate}
          endDate={selectedCreative.endDate}
          totalImpression={campaignImpressions}
          impressionGoal={selectedCreative.goal}
          status={impressionData.daysLeft ? 'Active' : 'Complete'}
        />
        <div>
          <ImpressionBox
            title="Overall Impressions"
            number={impressionData.totalImpression * IMPRESSION_MULTIPLIER}
            detail={`${impressionData.daysLeft} days left in campaign`}
          />
          <Space />
          <ImpressionBox
            title="Today's Impressions"
            number={impressionData.todayImpression * IMPRESSION_MULTIPLIER}
            percent={impressionData.fromYesterday}
            detail="from yesterday"
          />
          <Space />
          <ImpressionBox
            title="This Week's Impressions"
            number={impressionData.weekImpression * IMPRESSION_MULTIPLIER}
            percent={impressionData.fromLastWeek}
            detail="from last week"
          />
        </div>
        <DashboardMap
          start={selectedCreative.startDate}
          end={selectedCreative.endDate}
          data={impressionData.impressions}
        />
      </Grid>
      <ImpressionGraph
        startDate={selectedCreative.startDate}
        endDate={selectedCreative.endDate}
        data={impressionData.impressions}
      />
    </Wrapper>
  );
};

export default React.memo(Dashboard);
